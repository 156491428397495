import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import { createLogger } from "redux-logger";
import sagas from "./rootSaga"
import auth from "./reducers/auth";
import conversations from "./reducers/conversations";
import user from "./reducers/user";
import messages from "./reducers/messages";
import business from './reducers/business'
import agentGroups from './reducers/agentGroups'
import customers from './reducers/customers'

import {
    persistStore,
    persistReducer,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = { // configuration object for redux-persist
    key: 'root',
    storage, // define which storage to use
    whitelist: ['auth', 'user', 'business'],
}

// const blackListConfig = {
    // key: "root",
    // storage,
    // blacklist: ['']
// }


const combinedReducer = combineReducers({
    auth,
    conversations, 
    user,
    messages,
    business,
    agentGroups,
    customers
})


const rootReducer = (state, action) => {
    return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
    const sagaMiddleware = createSagaMiddleware();
    const logger = createLogger({
        collapsed: (getState, action, logEntry) => !logEntry.error
      });
    const middleware = [sagaMiddleware, logger];
    // const middleware = [sagaMiddleware];

    const store = configureStore({
        reducer: persistedReducer,
        middleware: [...middleware],
    });

    const persistor = persistStore(store);

    sagaMiddleware.run(sagas);

    return { store, persistor};
};