import React, { useEffect, useState, useRef, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { apiGenerator, POST } from "../api/api-manager";
import { checkSession } from '../store/reducers/auth';
import ConversationBar from "../_component/ConversationBar";
import ConversationBar1 from "../_component/ConversationBar1";
import ConversationWindow from "../_component/CoversationWindow";
import { useConversation, ConversationProvider } from "../_component/context/ConversationContext";
import { getRoomList } from "../store/reducers/conversations";
import { SocketProvider } from "../_component/context/SocketProvider";
import { selectAuth } from "../store/reducers/auth";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next'
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';
import NotificationSound from './../new-message-notification-sound.mpeg'

const ReconnectMessage = ({ closeToast, toastProps }) => (
  <div>
    Reconnecting...
    <br />
    <small>Please check your internet connection.</small>
  </div>
);

const Chats = (props) => {
  const {
    updateRoomList
  } = useConversation();

  const { t } = props;
  const dispatch = useDispatch();
  const authState = useSelector(selectAuth);
  const appSettings = useContext(AppSettings);
  const [online, setOnline] = useState(true);
  const toastId = React.useRef(null);
  const reactNotificationComponentId = React.useRef(null);

  const audioPlayer = useRef(null);

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-0');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    //   appSettings.handleSetAppContentClass('');
    // }
  }, [appSettings])
  
  useEffect(() => {
    // add class to body tag
    document.body.className = 'overflow-hidden';
    
    const eventOnline = function(e) {
      console.log('Online');

      // toast.success('Back online!', {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });

      // toast.dismiss(toastId.current);

      Store.addNotification({
        message: 'Back online!',
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });

      Store.removeNotification(reactNotificationComponentId.current);

      setOnline(true);
    }

    const eventOffline = function(e) {
      console.log('Offline');

      // toastId.current = toast.error(<ReconnectMessage />, {
      //   position: "top-right",
      //   autoClose: false,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   hideProgressBar: true,
      //   theme: "light",
      // });

      reactNotificationComponentId.current = Store.addNotification({
        message: <ReconnectMessage />,
        type: 'danger',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 0, click: false, touch: false },
      });

      setOnline(false);
    }

    window.addEventListener('online', eventOnline);
    window.addEventListener('offline', eventOffline);
    
    // dispatch(POST(checkSession,"users/check_session",{}));
    // dispatch(POST(getRoomList, 'conversations' , {}))
    updateRoomList();
    // eslint-disable-next-line

    return () => {
      window.removeEventListener('online', eventOnline);
      window.removeEventListener('offline', eventOffline);
      document.body.className = '';
    }
  },[]);

  const playNotificationSound = () => {
    try {
      audioPlayer.current.play(); 
      console.log('Playing audio...')     
    } catch (error) {
      console.log('Failed to play audio.')
    }
    // var audio = new Audio(NotificationSound);
    // audio.muted = true;
    // audio.play();
  };

  return (
    <>
      <Container fluid style={{ position: "fixed" }}>
        <Row style={{ height: "calc(100svh - 90px)" }}>
          {/* <SocketProvider id={authState.userID} db={authState.database}> */}
              <ConversationBar1 />
              <ConversationWindow online={online} />
          {/* </SocketProvider> */}
        </Row>
        {/* <audio ref={audioPlayer} src={NotificationSound} /> */}
      </Container>
    </>
  );
};

export default withTranslation()(Chats)