// roomList => List of Room for customer in ConversationBar.js
// messageList => List of message for Customer Room  in ConversationWindow.js

import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getPagingObject } from "../../utils";
import { Store } from 'react-notifications-component';

export const agentGroupsSlice = createSlice({
  name: "agentGroups",
  initialState: {
    agentGroupList: [],
    parentAgentGroupList: [],
    agentGroupPaginationParams: {},
    loading: false,
  },
  reducers: {
    getAgentGroupList(state) {
      state.loading = true;
    },
    getAgentGroupListSuccess(state, payload) {
      state.loading = false;
      let responseData = payload.resData.data.data;
      state.agentGroupList = responseData.docs;
      state.agentGroupPaginationParams = getPagingObject(responseData);
    },
    getAgentGroupListFail(state) {
      state.loading = false;
    },
    getParentAgentGroupList(state) {
      state.loading = true;
    },
    getParentAgentGroupListSuccess(state, payload) {
      state.loading = false;
      let responseData = payload.resData.data.data;
      state.parentAgentGroupList = responseData;
    },
    getParentAgentGroupListFail(state) {
      state.loading = false;
    },
    addAgentGroup(state) {
      state.loading = true;
    },
    addAgentGroupSuccess(state, payload) {
      state.loading = false;
      // toast(payload.resData.data.message)
      Store.addNotification({
        message: payload.resData.data.message,
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
      let responseData = payload.resData.data.data;
      state.agentGroupList = responseData.docs;
      state.agentGroupPaginationParams = getPagingObject(responseData);
    },
    addAgentGroupFail(state) {
      state.loading = false;
    },
    updateAgentGroup(state) {
      state.loading = true;
    },
    updateAgentGroupSuccess(state, payload) {
      state.loading = false;
      // toast(payload.resData.data.message)
      Store.addNotification({
        message: payload.resData.data.message,
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
      let responseData = payload.resData.data.data;
      state.agentGroupList = responseData.docs;
      state.agentGroupPaginationParams = getPagingObject(responseData);
    },
    updateAgentGroupFail(state) {
      state.loading = false;
    },
    deleteAgentGroup(state) {
      state.loading = true;
    },
    deleteAgentGroupSuccess(state, payload) {
      state.loading = false;
      // toast(payload.resData.data.message)
      Store.addNotification({
        message: payload.resData.data.message,
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
      let responseData = payload.resData.data.data;
      state.agentGroupList = responseData.docs;
      state.agentGroupPaginationParams = getPagingObject(responseData);
    },
    deleteAgentGroupFail(state) {
      state.loading = false;
    },
  },
});

export const selectAgentGroups = (state) => state.agentGroups;
export const {
  getAgentGroupList,
  getAgentGroupListSuccess,
  getAgentGroupListFail,
  getParentAgentGroupList,
  getParentAgentGroupListSuccess,
  getParentAgentGroupListFail,
  addAgentGroup,
  addAgentGroupSuccess,
  addAgentGroupFail,
  updateAgentGroup,
  updateAgentGroupSuccess,
  updateAgentGroupFail,
  deleteAgentGroup,
  deleteAgentGroupSuccess,
  deleteAgentGroupFail,
} = agentGroupsSlice.actions;

export default agentGroupsSlice.reducer;
