import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Container } from "react-bootstrap";
import { getUserList, getRoleList, apiGenerator } from "../api/api-manager";
import { Link, useNavigate } from 'react-router-dom'
import PageTitle from "../_component/PageTitle";
import CustomPagination from '../_component/Pagination'

import dayjs from "dayjs";
import { withTranslation } from 'react-i18next'

const Users = (props) => {
  const { t } = props;
  // const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  const [page, setPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState([]);
  const limit = 2;

  const navigate = useNavigate();

  useEffect(() => {
    getRoleList().then(res => {
      let response = res.data;
      if(response.status){
        setRoleList(response.params)
      }
    })

    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    updateUserList()
  },[roleList, page])

  useEffect(() => {
    console.log(`userList`, userList)
  },[userList])


  const deleteUser = (user) => {
    if (window.confirm('Are you sure to delete this item?')) {
      apiGenerator({
        method: 'DELETE',
        path: `users/${user._id}`,
        body: {}
      }).then(res => {
        alert(res.data.message);
        if(res.data.status){
          updateUserList()
        }
      })
    }
  }

  const editUser = (user) => {
    console.log(`editing user...`, user);
    navigate('/users/add', {state : {user: user}});
  }

  const updateUserList = () => {
    getUserList({page: page, limit: limit}).then(res => {
      let response = res.data;
      console.log(response)
      if(response.status){
        let temp_user_list = [];
        response.params.docs.map((user) => {
          let temp_user = user;
          roleList.forEach((role) => {
            if(role._id == user.role_id){
              temp_user = {
                ...user,
                role_name: role.name
              }
              temp_user_list.push(temp_user)
              return false
            }
            
          })
        })
        setUserList(temp_user_list)
        setPaginationParams({
          totalDocs: response.params.totalDocs,
          limit: response.params.limit,
          page: response.params.page,
          totalPages: response.params.totalPages,
          hasPrevPage: response.params.hasPrevPage,
          hasNextPage: response.params.hasNextPage,
          prevPage: response.params.prevPage,
          nextPage: response.params.nextPage
        })
      }
    })
  }
  return (
    <>
      <Container>
        <PageTitle title={t("agent_management")} lastPage={false} />

        <Row className="my-2">
          <Col>
          <Link to={'/users/add'}><Button variant="success">{t('add_agent')}</Button></Link>
          </Col>
        </Row>
        <Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>{t('username')}</th>
                <th>{t('name')}</th>
                <th>{t('role')}</th>
                <th>{t('permission')}</th>
                <th>{t('updated_at')}</th>
                <th>{t('action')}</th>
              </tr>
            </thead>
            <tbody>
              {userList && userList.length > 0 && userList.map((user, index) => {
                return (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{user.username}</td>
                    <td>{user.name}</td>
                    <td>{user.role_name}</td>
                    <td>
                      {user.permission_info && user.permission_info.length > 0 && user.permission_info.map((per, index) => {
                        return (
                          <div key={index}>
                            {per.name}<br></br>
                          </div>
                        )
                      })}
                    </td>
                    <td>{dayjs(user.updated_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>
                      
                      {/* <DropdownButton
                        as={ButtonGroup}
                        title="Action"
                        id="bg-vertical-dropdown-1"
                        menuVariant="dark"
                      >
                        <Dropdown.Item eventKey="1" href={'/users/add'}>Edit</Dropdown.Item>
                        <Dropdown.Item eventKey="2" value={user._id} onClick= {(e) => deleteUser(e)}>Delete</Dropdown.Item>
                      </DropdownButton> */}
                        <Button
                          variant="info"
                          className="m-1"
                          onClick={() => editUser(user)}
                        >
                          {t('edit')}
                        </Button>

                        <Button
                          variant="danger"
                          onClick={() => deleteUser(user)}
                        >
                          {t('delete')}
                        </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Row>

        <Row>
          <CustomPagination paginationParams={paginationParams} setPage={setPage}/>
        </Row>
      </Container>
    </>
  );
};

export default withTranslation()(Users);
