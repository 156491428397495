import { takeEvery } from "redux-saga/effects";
import { sagaBuild } from "./common";
import * as CustomersReducers from "../reducers/customers";

function* getCustomerListCaller({ payload }) {
    yield sagaBuild({ payload, successType: CustomersReducers.getCustomerListSuccess.type, failType: CustomersReducers.getCustomerListFail.type });
}

function* addCustomerCaller({ payload }) {
    yield sagaBuild({ payload, successType: CustomersReducers.addCustomerSuccess.type, failType: CustomersReducers.addCustomerFail.type });
}

function* updateCustomerCaller({ payload }) {
    yield sagaBuild({ payload, successType: CustomersReducers.updateCustomerSuccess.type, failType: CustomersReducers.updateCustomerFail.type });
}

function* deleteCustomerCaller({ payload }) {
    yield sagaBuild({ payload, successType: CustomersReducers.deleteCustomerSuccess.type, failType: CustomersReducers.deleteCustomerFail.type });
}

export default function* customers() {
    yield takeEvery(CustomersReducers.getCustomerList.type, getCustomerListCaller);
    yield takeEvery(CustomersReducers.addCustomer.type, addCustomerCaller);
    yield takeEvery(CustomersReducers.updateCustomer.type, updateCustomerCaller);
    yield takeEvery(CustomersReducers.deleteCustomer.type, deleteCustomerCaller);
}