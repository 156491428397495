import React from 'react';
import TopMenuNav from './top-menu-nav.jsx';
import { useSocket } from "../../_component/context/SocketProvider";

const TopMenu = () => {
  const { totalUnreadCount } = useSocket();

  return (
    <div id="top-menu" className="app-top-menu" test={`${totalUnreadCount}`}>
      <TopMenuNav totalUnreadCount={totalUnreadCount} />
    </div>
  );
}

export default TopMenu;
