import { takeEvery } from "redux-saga/effects";
import { sagaBuild } from "./common";
import {
    check,
    checkSuccess,
    checkFail,
    login,
    loginFail,
    loginSuccess,
    logout,
    logoutFail,
    logoutSuccess,
    checkSession,
    checkSessionSuccess,
    checkSessionFail,
    sessionExpired,
    sessionExpiredSuccess,
    sessionExpiredFail,
    changeLanguage,
    changeLanguageSuccess,
    changeLanguageFail,
} from "../reducers/auth";

function* checkCaller({ payload }) {
    yield sagaBuild({ payload, successType: checkSuccess.type, failType: checkFail.type });
}

function* loginCaller({ payload }) {
    yield sagaBuild({ payload, successType: loginSuccess.type, failType: loginFail.type });
}

function* logoutCaller({ payload }) {
    yield sagaBuild({ payload, successType: logoutSuccess.type, failType: logoutFail.type });
}

function* checkSessionCaller({ payload }) {
    yield sagaBuild({ payload, successType: checkSessionSuccess.type, failType: checkSessionFail.type });
}

function* sessionExpiredCaller({ payload }) {
    yield sagaBuild({ payload, successType: sessionExpiredSuccess.type, failType: sessionExpiredFail.type });
}

function* changeLanguageCaller({ payload }) {
    yield sagaBuild({ payload, successType: changeLanguageSuccess.type, failType: changeLanguageFail.type });
}

export default function* auth() {
    yield takeEvery(check.type, checkCaller);
    yield takeEvery(login.type, loginCaller);
    yield takeEvery(logout.type, logoutCaller);
    yield takeEvery(checkSession.type, checkSessionCaller);
    yield takeEvery(sessionExpired.type, sessionExpiredCaller);
    yield takeEvery(changeLanguage.type, changeLanguageCaller);
}