// roomList => List of Room for customer in ConversationBar.js
// messageList => List of message for Customer Room  in ConversationWindow.js

import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { Store } from 'react-notifications-component';

export const conversationSlice = createSlice({
    name: "conversations",
    initialState: {
        roomList: [],
        messageList: [],
        conversationsRefreshed: false,
    },
    reducers: {
        getRoomList(state){
            state.loading = true
        },
        getRoomListSuccess(state, payload){
            state.loading = false;
            let responseData = payload.resData.data;
            state.roomList = responseData.data
        },
        getRoomListFail(state){
            state.loading = false;
        },
        setRoomList(state){
            state.loading = true
        },
        setRoomListSuccess(state, payload){
            state.loading = false;
            let newRoomList = payload.newRoomList;
            state.roomList = newRoomList;
        },
        setRoomListFail(state){
            state.loading = false;
        },
        getMessageList(state){
            state.loading = true
        },
        getMessageListSuccess(state, payload){
            state.loading = false;
            let responseData = payload.resData.data;
            state.messageList = responseData.data
        },
        getMessageListFail(state){
            state.loading = false;
        },
        appendMessageList(state){
            state.loading = true
        },
        appendMessageListSuccess(state, payload){
            state.loading = false;
            let responseData = payload.resData.data;
            state.messageList = state.messageList.concat(responseData.data);
        },
        appendMessageListFail(state){
            state.loading = false;
        },
        forwardMessage(state){
            state.loading = true;
            state.conversationsRefreshed = false;
        },
        forwardMessageSuccess(state, payload){
            state.loading = false;
            state.conversationsRefreshed = true;
        },
        forwardMessageFail(state, error){
            state.loading = false;
            Store.addNotification({
                message: error.error.response.data.message,
                type: 'danger',
                insert: "top",
                container: 'top-right',
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 5000 },
            });
        },
    }
})


export const selectConversation = (state) => state.conversations;
export const {
    getRoomList,
    getRoomListSuccess,
    getRoomListFail,
    setRoomList,
    setRoomListSuccess,
    setRoomListFail,
    getMessageList,
    getMessageListSuccess,
    getMessageListFail,
    appendMessageList,
    appendMessageListSuccess,
    appendMessageListFail,
    forwardMessage,
    forwardMessageSuccess,
    forwardMessageFail,
} = conversationSlice.actions;

export default conversationSlice.reducer;