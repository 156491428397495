import { takeEvery } from "redux-saga/effects";
import { sagaBuild } from "./common";
import * as MessageReducers from "../reducers/messages";

function* getDefaultMessageListCaller({ payload }) {
    yield sagaBuild({ payload, successType: MessageReducers.getDefaultMessageListSuccess.type, failType: MessageReducers.getDefaultMessageListFail.type });
}

function* setDefaultMessageCaller({ payload }) {
    yield sagaBuild({ payload, successType: MessageReducers.setDefaultMessageSuccess.type, failType: MessageReducers.setDefaultMessageFail.type });
}

function* addAutoReplyMessageCaller({ payload }) {
    yield sagaBuild({ payload, successType: MessageReducers.addAutoReplyMessageSuccess.type, failType: MessageReducers.addAutoReplyMessageFail.type });
}

function* updateAutoReplyMessageCaller({ payload }) {
    yield sagaBuild({ payload, successType: MessageReducers.updateAutoReplyMessageSuccess.type, failType: MessageReducers.updateAutoReplyMessageFail.type });
}

function* deleteAutoReplyMessageCaller({ payload }) {
    yield sagaBuild({ payload, successType: MessageReducers.deleteAutoReplyMessageSuccess.type, failType: MessageReducers.deleteAutoReplyMessageFail.type });
}

export default function* conversation() {
    yield takeEvery(MessageReducers.getDefaultMessageList.type, getDefaultMessageListCaller);
    yield takeEvery(MessageReducers.setDefaultMessage.type, setDefaultMessageCaller);
    yield takeEvery(MessageReducers.addAutoReplyMessage.type, addAutoReplyMessageCaller);
    yield takeEvery(MessageReducers.updateAutoReplyMessage.type, updateAutoReplyMessageCaller);
    yield takeEvery(MessageReducers.deleteAutoReplyMessage.type, deleteAutoReplyMessageCaller);
}