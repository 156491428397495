import React, { useEffect, useState, useMemo, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import { POST, GET, PATCH } from "../api/api-manager";
import {
  getAgentGroupList,
  getParentAgentGroupList,
} from "../store/reducers/agentGroups";
import {
  selectAgentGroups,
  addAgentGroup,
  updateAgentGroup,
  deleteAgentGroup,
} from "../store/reducers/agentGroups";
import Table from "react-bootstrap/Table";
import CustomModal from "../_component/CustomModal";
import CustomModal1 from "../_component/CustomModal1";
import { Button, Form, InputGroup } from "react-bootstrap";
import PageTitle from "../_component/PageTitle";
import CustomPagination from "../_component/Pagination";
import { withTranslation } from 'react-i18next'

const AgentGroups = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const {
    agentGroupList,
    agentGroupPaginationParams,
    loading,
    parentAgentGroupList,
  } = useSelector(selectAgentGroups);

  // Default Group list
  const [agentGroupListState, setAgentGroupListState] = useState([]);

  // Add/Edit modal
  const [addEditModalState, setAddEditModalState] = useState(false);
  const [addEditModalTypeState, setAddEditModalTypeState] = useState(null);

  const [currentGroupName, setCurrentGroupName] = useState("");
  const [currentParentGroupId, setCurrentParentGroupId] = useState("");
  const [currentGroupType, setCurrentGroupType] = useState("parent");

  const [searchGroupType, setSearchGroupType] = useState("all");

  const [editGroupId, setEditGroupId] = useState(null);

  const [parentAgentGroupListState, setParentAgentGroupListState] = useState(
    []
  );

  const [page, setPage] = useState(1);
  const [displayPage, setDisplayPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState({
    totalDocs: 0,
    limit: 2,
    page: 1,
    totalPages: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  });

  useEffect(() => {
    dispatch(GET(getParentAgentGroupList, "user_groups/parent", {}));
  }, [agentGroupList]);

  useEffect(() => {
    let reqPaginationParams = Object.assign({}, paginationParams);
    if (page) {
      reqPaginationParams.page = page;
      dispatch(
        POST(getAgentGroupList, "user_groups", {
          page: reqPaginationParams.page,
          limit: reqPaginationParams.limit,
          type: searchGroupType
        })
      );
    }
  }, [page]);

  useEffect(() => {
    let reqPaginationParams = Object.assign({}, paginationParams);
    if (page) {
      reqPaginationParams.page = page;
      dispatch(
        POST(getAgentGroupList, "user_groups", {
          page: 1,
          limit: reqPaginationParams.limit,
          type: searchGroupType
        })
      );
    }
  }, [searchGroupType]);

  useEffect(() => {
    if (!loading) setDisplayPage(page);
  }, [loading]);

  useEffect(() => {
    setAgentGroupListState(agentGroupList);
    setPaginationParams(agentGroupPaginationParams);
    setPage(agentGroupPaginationParams.page);
  }, [agentGroupList]);

  useEffect(() => {
    setParentAgentGroupListState(parentAgentGroupList);
    if (parentAgentGroupList[0]) {
      setCurrentParentGroupId(parentAgentGroupList[0]._id);
    }
  }, [parentAgentGroupList]);

  const openEditModal = (type, group = {}) => {
    if (type != "Add") {
      setCurrentGroupName(group.name);
      setCurrentGroupType(
        group.parent_group_id == null ? "parent" : "subGroup"
      );
      if(group.parent_group_id == "subGroup"){
        setCurrentParentGroupId(group.parent_group_id);
      }
      setEditGroupId(group._id);
    }
    setAddEditModalState(true);
    setAddEditModalTypeState(type);
  };

  const deleteGroup = (group) => {
    let reqPaginationParams = Object.assign({}, paginationParams);
    reqPaginationParams.page = page;
    dispatch(
      POST(deleteAgentGroup, `user_groups/delete`, {
        id: group._id,
        page: 1,
        limit: reqPaginationParams.limit,
        type: searchGroupType
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let reqPaginationParams = Object.assign({}, paginationParams);
    reqPaginationParams.page = page;
    if (addEditModalTypeState == "Add") {
      dispatch(
        POST(addAgentGroup, "user_groups/create", {
          page: 1,
          limit: reqPaginationParams.limit,
          name: currentGroupName,
          parent_group_id:
            currentGroupType === "subGroup" ? currentParentGroupId : null,
          type: searchGroupType

        })
      );
    } else {
      dispatch(
        PATCH(updateAgentGroup, `user_groups/${editGroupId}`, {
          page: 1,
          limit: reqPaginationParams.limit,
          name: currentGroupName,
          parent_group_id:
            currentGroupType === "subGroup" ? currentParentGroupId : null,
          type: searchGroupType
        })
      );
    }

    setAddEditModalState(false);
    setCurrentGroupName("");
    setCurrentGroupType("parent");
    setEditGroupId(null);

    if (parentAgentGroupList[0]) {
      setCurrentParentGroupId(parentAgentGroupList[0]._id);
    }
  };

  const clearModal = () => {
    setAddEditModalState(false);
    setCurrentGroupName("");
    setCurrentGroupType("parent");
    setEditGroupId(null);
  }

  const editModal = useMemo(() => {
    return (
      <CustomModal1
        showModal={addEditModalState}
        setShowModal={setAddEditModalState}
        modal_title={t("agent_group")}
        onSubmit={() => {
          //updateAgent();
        }}
        centered={true}
        onHideFunction={() => clearModal()}
        showSubmitButton={false}
      >
        <div className="d-flex flex-column">
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridUsername">
                  <Form.Label>{t("group_name")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("group_name")}
                    name="groupName"
                    value={currentGroupName}
                    onChange={(e) => {
                      setCurrentGroupName(e.target.value);
                    }}
                    // defaultValue={editMode ? editUser.username : ""}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridRole">
                  <Form.Label>{t("group_type")}</Form.Label>
                  <div>
                    <Form.Select
                      name="currentGroupType"
                      value={currentGroupType}
                      onChange={(e) => {
                        setCurrentGroupType(e.target.value);
                      }}
                      // value={editMode ? editUser.role_id : undefined}
                    >
                      <option value="parent">{t("parent")}</option>
                      <option value="subGroup">{t("sub_group")}</option>
                    </Form.Select>
                  </div>
                </Form.Group>
              </Row>
              {currentGroupType === "subGroup" && (
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridRole">
                    <Form.Label>{t("parent_group")}</Form.Label>
                    <div>
                      <Form.Select
                        name="parentGroupId"
                        value={currentParentGroupId}
                        onChange={(e) => {
                          setCurrentParentGroupId(e.target.value);
                        }}
                      >
                        {parentAgentGroupListState.map((parentGroup) => {
                          return (
                            <option
                              value={`${parentGroup._id}`}
                              key={parentGroup._id}
                            >
                              {parentGroup.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </Form.Group>
                </Row>
              )}
              <InputGroup className="mb-3">
                <Button type={"submit"}>{addEditModalTypeState}</Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
      </CustomModal1>
    );
  });

  return (
    <>
      <Container>
        <PageTitle title={t("agent_group_management")} lastPage={false} />

        <Row className="my-2">
          <Col>
            <Button variant="primary" onClick={() => openEditModal("Add")}>
              {t("add_agent_group")}
            </Button>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <Form.Select 
              aria-label="Search By Group Type"
              onChange={(e) => setSearchGroupType(e.target.value)}
            >
              <option value="all">{t("all")}</option>
              <option value="groups">{t("groups")}</option>
              <option value="sub_groups">{t("sub_groups")}</option>
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("group")}</th>
                  <th>{t("sub_groups")}</th>
                  <th>{t("agents")}</th>
                  <th>{t("action")}</th>
                </tr>
              </thead>
              <tbody>
                {agentGroupListState.map((agentgroup, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {displayPage && ((displayPage - 1) * paginationParams.limit + 1 + index)}
                      </td>
                      <td>{agentgroup.name}</td>
                      <td>
                        <ul>
                          {
                            agentgroup.sub_groups.map((sub_group, index) => {
                              return (
                                <li key={"sub_group_name_"+index}>{sub_group.name}</li>
                              )
                            })
                          
                          }
                        </ul>
                      </td>
                      <td>
                        <ul>
                          {
                            agentgroup.group_users.map((group_user, index) => {
                              return (
                                <li key={"group_user_name_"+index}>{group_user.name}</li>
                              )
                            })
                          
                          }
                        </ul>
                      </td>
                      <td>
                        <Button
                          variant="info"
                          className="m-1"
                          onClick={() => openEditModal("Edit", agentgroup)}
                        >
                          {t("edit")}
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => deleteGroup(agentgroup)}
                        >
                          {t("delete")}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <CustomPagination
            paginationParams={paginationParams}
            setPage={setPage}
          />
        </Row>
        {/* Modal for edit group */}
        {editModal}
      </Container>
    </>
  );
};

export default withTranslation()(AgentGroups);
