import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { POST, GET, PATCH } from '../api/api-manager';
import { getAgentGroupList, getParentAgentGroupList } from '../store/reducers/agentGroups';
import { selectAgentGroups, addAgentGroup, updateAgentGroup, deleteAgentGroup } from '../store/reducers/agentGroups';
import Table from 'react-bootstrap/Table';
import CustomModal from '../_component/CustomModal';
import CustomModal1 from '../_component/CustomModal1';
import { Button, Form, InputGroup } from 'react-bootstrap';
import PageTitle from '../_component/PageTitle';
import CustomPagination from '../_component/Pagination';
import CustomPagination1 from '../_component/Pagination1';
import { useTranslation, withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { apiGenerator } from '../api/api-manager';

// * Helper functions
// Get the client ChatGPT usage, quota, .etc.
const getChatGptInfo = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'GET',
      path: `client/chat-gpt`,
      body: {},
    }).then(res => {
      return resolve(res?.data ? res.data : undefined);
    });
  });
};

// Get the client's current subscription.
const getSubscription = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'POST',
      path: `subscription/null/checkLastSubscription`,
      body: {},
    }).then(res => {
      return resolve(res?.data ? res.data : undefined);
    });
  });
};

// * Sub-components
const CurrentPlan = ({ subscription, chatGptInfo, ...props }) => {
  const { t } = useTranslation();

  let displayPlan = '';

  switch (subscription.plan) {
    case 'starter':
      displayPlan = t('starter_plan');
      break;

    case 'business':
      displayPlan = t('business_plan');
      break;

    case 'enterprise':
      displayPlan = t('enterprise_plan');
      break;

    default:
      break;
  }

  return (
    <>
      <div className="card-header bg-none p-3 h5 m-0 d-flex align-items-center mt-3">Your Current Plan Details</div>
      <div className="card-body">
        <table className="table table-borderless table-sm fw-bold m-0">
          <tbody>
            <tr>
              <td className="w-200px">Subscription</td>
              <td>{displayPlan}</td>
            </tr>
            <tr>
              <td className="w-200px">Pricing</td>
              <td>
                {subscription.currency}${Math.floor(subscription.pricing * 100) / 100} / month
              </td>
            </tr>
            <tr>
              <td className="w-200px">Purchase Date (HKT)</td>
              <td>{new Date(subscription.purchaseDate).toLocaleString()}</td>
            </tr>
            <tr>
              <td className="w-200px" style={{ verticalAlign: 'top' }}>
                Subscription Period (HKT)
              </td>
              <td>
                <div style={{ whiteSpace: 'nowrap' }}>{new Date(subscription.purchaseDate).toLocaleString()}</div>
                <b>to</b>
                <div style={{ whiteSpace: 'nowrap' }}>{new Date(subscription.expirationDate).toLocaleString()}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="card-header bg-none p-3 h5 m-0 d-flex align-items-center mt-3">ChatGPT Quota</div>
      <div className="card-body">
        <table className="table table-borderless table-sm fw-bold m-0">
          <tbody>
            <tr>
              <td className="w-200px">Current Usage / Quota</td>
              <td>{chatGptInfo ? `${chatGptInfo.usage} / ${chatGptInfo.quota}` : 'N/A'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

// * Page component
const Subscription = props => {
  const { t } = props;

  const appSettings = useContext(AppSettings);
  const [subscription, setSubscription] = useState();
  const [chatGptInfo, setChatGptInfo] = useState();

  // In-component helper functions
  // * Init subscirption.
  const initSubscription = subscription => {
    if (subscription === undefined) {
      console.log('No existing subscription');
      return;
    }

    setSubscription({
      plan: subscription.plan_type,
      currency: subscription.currency,
      pricing: subscription.price,
      purchaseDate: subscription.purchase_date,
      expirationDate: subscription.expiration_date,
    });
  };

  const initChatGptInfo = chatGptData => {
    // * Init ChatGPT info.
    const newChatGptInfo = {
      usage: 0,
      quota: 0,
    };

    if (
      typeof chatGptData?.isChatGptEnabled !== 'boolean' ||
      chatGptData?.isChatGptEnabled === false ||
      typeof chatGptData?.chatGptQuota !== 'number' ||
      chatGptData?.chatGptQuota === 0
    ) {
      console.log('ChatGPT is not available.');
      return;
    }

    newChatGptInfo.quota = chatGptData.chatGptQuota;

    if (typeof chatGptData.chatGptUsage === 'number') {
      newChatGptInfo.usage = chatGptData.chatGptUsage;
    }

    setChatGptInfo(newChatGptInfo);
  };

  // General UI settings (Template)
  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');
  }, [appSettings]);

  useEffect(() => {
    (async () => {
      const getSubscriptionRes = await getSubscription();
      const getChatGptInfoRes = await getChatGptInfo();
      console.log({ getSubscriptionRes, getChatGptInfoRes });

      initSubscription(getSubscriptionRes?.params?.[0]?.last_subscription);
      initChatGptInfo(getChatGptInfoRes?.data);
    })();
  }, []);

  return (
    <>
      <Container>
        {/* Breadcrumb */}
        <div className="d-flex align-items-center mb-3">
          <div>
            <h1 className="page-header mb-0">{t('subscription')}</h1>
          </div>
        </div>

        {/* Main content */}
        <div className="row">
          {/* Starter plan */}
          <div className="col-sm-4">
            <div className="card border-0">
              {/* Subscription info */}
              <div className="card-header bg-none p-3 h2 m-0 d-flex align-items-center">
                {t('starter')}
                {subscription?.plan === 'starter' && (
                  <span
                    className="badge bg-primary mx-2"
                    style={{ fontSize: '16px', paddingTop: '4px', paddingBottom: '4px' }}
                  >
                    Current
                  </span>
                )}
                <div className="h5 ms-auto text-decoration-none text-gray-500">HKD$299 / month</div>
              </div>
              <div className="card-body">
                <table className="table table-borderless table-sm fw-bold m-0">
                  <tbody>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_number_of_agent_accounts')} 5
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_number_of_groups')} 2
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_number_of_monthly_active_contacts')} 500
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_usage_reports')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_automatic_responses')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_system_alerts')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_additional_agent_account_hkd_per_agent_per_month_1')}50{' '}
                        {t('subscription_additional_agent_account_hkd_per_agent_per_month_2')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_additional_group_hkd_per_group_per_month_1')}100{' '}
                        {t('subscription_additional_group_hkd_per_group_per_month_2')}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {subscription?.plan === 'starter' && (
                <CurrentPlan subscription={subscription} chatGptInfo={chatGptInfo} />
              )}
            </div>
          </div>

          {/* Business plan */}
          <div className="col-sm-4">
            <div className="card border-0">
              {/* Subscription info */}
              <div className="card-header bg-none p-3 h2 m-0 d-flex align-items-center">
                {t('business')}
                {subscription?.plan === 'business' && (
                  <span
                    className="badge bg-primary mx-2"
                    style={{ fontSize: '16px', paddingTop: '4px', paddingBottom: '4px' }}
                  >
                    Current
                  </span>
                )}
                <div className="h5 ms-auto text-decoration-none text-gray-500">HKD$899 / month</div>
              </div>
              <div className="card-body">
                <table className="table table-borderless table-sm fw-bold m-0">
                  <tbody>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_number_of_agent_accounts')} 20
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_number_of_groups')} 5
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_number_of_monthly_active_contacts')} 2,500
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_advanced_reports')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_automatic_responses')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_system_alerts')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_additional_agent_account_hkd_per_agent_per_month_1')}40{' '}
                        {t('subscription_additional_agent_account_hkd_per_agent_per_month_2')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_additional_group_hkd_per_group_per_month_1')}80{' '}
                        {t('subscription_additional_group_hkd_per_group_per_month_2')}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {subscription?.plan === 'business' && (
                <CurrentPlan subscription={subscription} chatGptInfo={chatGptInfo} />
              )}
            </div>
          </div>

          {/* Enterprise plan */}
          <div className="col-sm-4">
            <div className="card border-0">
              {/* Subscription info */}
              <div className="card-header bg-none p-3 h2 m-0 d-flex align-items-center">
                {t('enterprise')}
                {subscription?.plan === 'enterprise' && (
                  <span
                    className="badge bg-primary mx-2"
                    style={{ fontSize: '16px', paddingTop: '4px', paddingBottom: '4px' }}
                  >
                    Current
                  </span>
                )}
                <div className="h5 ms-auto text-decoration-none text-gray-500">HKD$2,499 / month</div>
              </div>
              <div className="card-body">
                <table className="table table-borderless table-sm fw-bold m-0">
                  <tbody>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_number_of_agent_accounts')} 50
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_number_of_groups')} 15
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_number_of_monthly_active_contacts')} 10,000
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_advanced_reports')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_automatic_responses')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_system_alerts')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_additional_agent_account_hkd_per_agent_per_month_1')}30{' '}
                        {t('subscription_additional_agent_account_hkd_per_agent_per_month_2')}
                      </td>
                    </tr>
                    <tr>
                      <td className="w-100" colSpan={2}>
                        <i className="fa fa-fw fa-circle-check me-1 text-green"></i>
                        {t('subscription_additional_group_hkd_per_group_per_month_1')}60{' '}
                        {t('subscription_additional_group_hkd_per_group_per_month_2')}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {subscription?.plan === 'enterprise' && (
                <CurrentPlan subscription={subscription} chatGptInfo={chatGptInfo} />
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default withTranslation()(Subscription);
