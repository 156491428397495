import { createContext, useContext, useReducer, useEffect } from 'react';
import { conversationSlice } from '../../store/reducers/conversations';

export const PermissionsContext = createContext(null);
export const PermissionsDispatchContext = createContext(null);

export function PermissionsProvider({ children }) {
  const [permissions, dispatch] = useReducer(permissionsReducer, initialPermissions, createInitialPermissions);

  useEffect(() => {
    console.log('Permissions are updated', permissions);
  }, [permissions]);

  return (
    <PermissionsContext.Provider value={permissions}>
      <PermissionsDispatchContext.Provider value={dispatch}>{children}</PermissionsDispatchContext.Provider>
    </PermissionsContext.Provider>
  );
}

export function usePermissions() {
  return useContext(PermissionsContext);
}

export function usePermissionsDispatch() {
  return useContext(PermissionsDispatchContext);
}

function permissionsReducer(permissions, action) {
  console.log('permissionsReducer', permissions)
  try {
    console.log({
      'new dispatch permissions': [
        JSON.stringify(permissions.permissionsAry) !== action.permissions,
        JSON.stringify(permissions.permissionsAry),
        action.permissions,
      ],
    });

    if (JSON.stringify(permissions.permissionsAry) !== action.permissions) {
      const permissionsAry = JSON.parse(action.permissions);
      const idxPermissions = {};

      for (const permission of permissionsAry) {
        idxPermissions[permission] = true;
      }

      const newPermissions = {
        permissionsAry,
        permissions: idxPermissions,
      };

      // Cache in local storage
      console.log('Cache permissions');
      localStorage.setItem('permissions', JSON.stringify(newPermissions));

      return newPermissions;
    }
  } catch (error) {
    console.log('Error when dispatch permissions', action, error);
  }

  return permissions;
}

const initialPermissions = {
  permissionsAry: [],
  permissions: {},
};

const createInitialPermissions = permissions => {
  let localStoragePermissions = localStorage.getItem('permissions');

  if (localStoragePermissions === null) {
    return permissions;
  }

  try {
    return JSON.parse(localStoragePermissions);
  } catch (error) {
    console.log(error);
    return permissions;
  }
};
