import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { useConversation } from '../_component/context/ConversationContext';
import SelectionList from '../_component/SelectionList';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { getRoleList, getPermissionList, apiGenerator } from '../api/api-manager';
import { withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';

const RoleForm = props => {
  const { t } = props;

  const appSettings = useContext(AppSettings);
  const [permissionList, setPermissionList] = useState([]);
  const [editRole, setEditRole] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const nameRef = useRef();

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    // };
  }, [appSettings]);

  useEffect(() => {
    getPermissionList().then(res => {
      let resposnse = res.data;
      if (resposnse.status) {
        setPermissionList(resposnse.params);
      }
    });
  }, []);

  useEffect(() => {
    if (location.state?.role) {
      setEditRole(location.state.role);
      setEditMode(true);
    }
  }, [location]);

  const onFormSubmit = event => {
    const formData = new FormData(event.currentTarget);

    console.log({ event, formData });

    const submitted_checkedbox = [];
    document.querySelectorAll('input[type="checkbox"]:checked').forEach(ele => {
      if (ele.value == 'on') {
        submitted_checkedbox.push(ele.id);
      }
    });
    event.preventDefault();

    var error = false;

    for (let [key, value] of formData.entries()) {
      // console.log(key, value);
      // check empty
      if (key == 'name') {
        if (value == '' || value == null || value == undefined) {
          error = true;
        }
      }
    }

    if (error) {
      // alert(`Please filled in required information`);
      Store.addNotification({
        message: `Please filled in required information`,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: { duration: 5000 },
      });
    } else {
      let request_role = {
        name: formData.get('name'),
        permission: submitted_checkedbox,
      };
      console.log(request_role);

      if (editMode) {
        apiGenerator({
          method: 'PATCH',
          path: `roles/${editRole._id}`,
          body: request_role,
        }).then(res => {
          // alert(res.data.message);
          Store.addNotification({
            message: res.data.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 5000 },
          });
          if (res.data.status) {
            navigate('/roles');
          }
        });
      } else {
        apiGenerator({
          method: 'POST',
          path: 'roles/create',
          body: request_role,
        }).then(res => {
          // alert(res.data.message);
          Store.addNotification({
            message: res.data.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 5000 },
          });
          if (res.data.status) {
            navigate('/roles');
          }
        });
      }
    }
  };

  return (
    <Container>
      <div className="d-flex align-items-center">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={'/roles'}>{t('role_management')}</Link>
            </li>
            <li className="breadcrumb-item active">{editMode ? t('edit_role') : t('add_role')}</li>
          </ol>
          <h1 className="page-header">{editMode ? t('edit_role') : t('add_role')}</h1>
        </div>
      </div>

      <Form onSubmit={onFormSubmit} id="roleForm">
        <div className="row gx-4">
          <div className="col-sm-6 offset-md-3" style={{ display: 'flex' }}>
            <div className="card border-0 mb-4">
              <div className="card-body p-3 text-dark fw-bold">
                <div className="row">
                  <div className="col-sm-12">
                    <fieldset>
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <label className="form-label" htmlFor="name">
                            {t('name')}
                          </label>
                          <input
                            ref={nameRef}
                            type="text"
                            className="form-control"
                            name="name"
                            defaultValue={editMode ? editRole.name : ''}
                          />
                        </div>
                      </div>

                      <hr class="my-4"></hr>

                      <h4 className="mb-3">{t('permission')}</h4>

                      <div className="row mb-3">
                        {permissionList &&
                          Object.values(permissionList).map(value => {
                            return (
                              <div className="col-sm-12 mb-4">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <label className="form-label mb-2">
                                      <b>{value.permissionGroup.name}</b>
                                    </label>
                                  </div>

                                  <div className="col-sm-8">
                                    {value.permissions.map((permission, index) => (
                                      <div key={index} className="form-check mb-2">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={permission.key}
                                          defaultChecked={
                                            Array.isArray(editRole?.permission) &&
                                            editRole.permission.includes(permission.key)
                                          }
                                        />
                                        <label className="form-check-label" htmlFor={permission.key}>
                                          {permission.name}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="card-footer bg-none d-flex p-3">
                <button type="submit" className="btn btn-primary w-100px ms-auto">
                  {t('submit')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default withTranslation()(RoleForm);
