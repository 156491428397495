import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyBmUkB2prB653xH7YGFcqLZuYqrb0h7Tbc",
  authDomain: "chatduck-ac40c.firebaseapp.com",
  projectId: "chatduck-ac40c",
  storageBucket: "chatduck-ac40c.appspot.com",
  messagingSenderId: "821878151393",
  appId: "1:821878151393:web:e0662c16b2b8ee665e7fa0",
  measurementId: "G-QRVFQSLXDL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);