import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { POST, GET, PATCH } from '../api/api-manager';
import { getCustomerList } from '../store/reducers/customers';
import { selectCustomers, addCustomer, updateCustomer, deleteCustomer } from '../store/reducers/customers';
import Table from 'react-bootstrap/Table';
import CustomModal from '../_component/CustomModal';
import CustomModal1 from '../_component/CustomModal1';
import { Button, Form, InputGroup } from 'react-bootstrap';
import PageTitle from '../_component/PageTitle';
import CustomPagination from '../_component/Pagination';
import CustomPagination1 from '../_component/Pagination1';
import { withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { usePermissions } from '../_component/context/AuthContext';
import { useDebounce } from '../_component/hooks/useDebounce';

const Customers1 = props => {
  const { t } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { permissions } = usePermissions();

  const appSettings = useContext(AppSettings);

  // Search fields
  const [searchKeyword, setSearchKeyword] = useState('');
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 350);

  const { customerList, customerPaginationParams, loading } = useSelector(selectCustomers);

  // Default Group list
  const [customerListState, setCustomerListState] = useState([]);

  // Add/Edit modal
  const [addEditModalState, setAddEditModalState] = useState(false);
  const [addEditModalTypeState, setAddEditModalTypeState] = useState(null);

  // Delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteCustomer, setSelectedDeleteCustomer] = useState(undefined);

  const [currentName, setCurrentName] = useState('');
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');

  const [editCustomerId, setEditCustomerId] = useState(null);

  // const [page, setPage] = useState(customerPaginationParams.page ? customerPaginationParams.page : 1);
  const [page, setPage] = useState(1);
  const [displayPage, setDisplayPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState({
    totalDocs: 0,
    limit: 5,
    page: 1,
    totalPages: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  });  

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    // };
  }, [appSettings]);

  useEffect(() => {
    let reqPaginationParams = Object.assign({}, paginationParams);
    if (page) {
      reqPaginationParams.page = page;
      dispatch(
        POST(getCustomerList, 'customers', {
          keyword: debouncedSearchKeyword,
          page: reqPaginationParams.page,
          limit: reqPaginationParams.limit,
        })
      );
    }
  }, [debouncedSearchKeyword, page]);

  useEffect(() => {
    if (!loading) setDisplayPage(page);
  }, [loading]);

  useEffect(() => {
    setCustomerListState(customerList);
    setPaginationParams(customerPaginationParams);
  }, [customerList]);

  const editCustomer = (customer = {}) => {
    navigate('/customers/add', { state: { customer: customer } });
  };

  const onDeleteModalSubmit = () => {
    let reqPaginationParams = Object.assign({}, paginationParams);

    // Reset to page 1
    setPage(1);
    reqPaginationParams.page = 1;

    dispatch(
      POST(deleteCustomer, `customers/delete`, {
        id: selectedDeleteCustomer._id,
        paging: {
          page: reqPaginationParams.page,
          limit: reqPaginationParams.limit,
        },
      })
    );
  };

  const handleDeleteCustomer = customer => {
    setSelectedDeleteCustomer(customer);
    setShowDeleteModal(true);
  };

  const handleSubmit = e => {
    e.preventDefault();
    let reqPaginationParams = Object.assign({}, paginationParams);
    reqPaginationParams.page = page;
    if (addEditModalTypeState == 'Add') {
      dispatch(
        POST(addCustomer, 'customers/create', {
          paging: {
            page: 1,
            limit: reqPaginationParams.limit,
          },
          profile_name: currentName,
          phone_number: currentPhoneNumber,
        })
      );
    } else {
      dispatch(
        PATCH(updateCustomer, `customers/${editCustomerId}`, {
          paging: {
            page: 1,
            limit: reqPaginationParams.limit,
          },
          profile_name: currentName,
          phone_number: currentPhoneNumber,
        })
      );
    }

    setAddEditModalState(false);
    setCurrentName('');
    setCurrentPhoneNumber('');
    setEditCustomerId(null);
  };

  const clearModal = () => {
    setAddEditModalState(false);
    setCurrentName('');
    setCurrentPhoneNumber('');
    setEditCustomerId(null);
  };

  const editModal = useMemo(() => {
    return (
      <CustomModal1
        showModal={addEditModalState}
        setShowModal={setAddEditModalState}
        modal_title={t('customers')}
        onSubmit={() => {
          //updateAgent();
        }}
        centered={true}
        onHideFunction={() => clearModal()}
        showSubmitButton={false}
      >
        <div className="d-flex flex-column">
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridUsername">
                  <Form.Label>{t('name')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t('name')}
                    name="Name"
                    value={currentName}
                    onChange={e => {
                      setCurrentName(e.target.value);
                    }}
                    // defaultValue={editMode ? editUser.username : ""}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridRole">
                  <Form.Label>{t('phone_number')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t('phone_number')}
                    name="phoneNumber"
                    value={currentPhoneNumber}
                    onChange={e => {
                      setCurrentPhoneNumber(e.target.value);
                    }}
                    // defaultValue={editMode ? editUser.username : ""}
                  />
                </Form.Group>
              </Row>
              <InputGroup className="mb-3">
                <button type={'submit'} className="btn btn-primary">
                  {addEditModalTypeState}
                </button>
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
      </CustomModal1>
    );
  });

  return (
    <>
      <Container>
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={'/'}>Home</Link>
              </li>
              <li className="breadcrumb-item active">{t('customer_management')}</li>
            </ul>
            <h1 className="page-header mb-0">{t('customer_management')}</h1>
          </div>
          <div className="ms-auto">
            {permissions?.add_customer && (
              <Link to={'/customers/add'}>
                <Button variant="primary" className="px-4 btn-rounded rounded-pill">
                  <i className="fa fa-plus fa-lg me-2 ms-n2 text-white"></i> {t('add_customer')}
                </Button>
              </Link>
            )}
          </div>
        </div>

        <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              {/* Search bar */}
              <div div className="input-group mb-3">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                  style={{ zIndex: 10 }}
                >
                  <i className="fa fa-search opacity-5"></i>
                </div>
                <input
                  type="text"
                  className="form-control px-35px bg-light"
                  style={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
                  placeholder={t('search_name_phone_number')}
                  value={searchKeyword}
                  onChange={e => {
                    setSearchKeyword(e.target.value);
                  }}
                />
              </div>

              {/* Table */}
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: '1%', whiteSpace: 'nowrap' }}>#</th>
                      {(permissions?.edit_customer || permissions?.delete_customer) && (
                        <th style={{ width: '1%', whiteSpace: 'nowrap' }}>{t('action')}</th>
                      )}
                      <th>{t('name')}</th>
                      <th>{t('phone_number')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerListState.map((customer, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ verticalAlign: 'super' }}>
                            {displayPage && (displayPage - 1) * paginationParams.limit + 1 + index}
                          </td>
                          {(permissions?.edit_customer || permissions?.delete_customer) && (
                            <td nowrap="true" className="with-btn" style={{ verticalAlign: 'super' }}>
                              {permissions?.edit_customer && (
                                <button
                                  className="btn btn-sm btn-primary w-60px me-1"
                                  onClick={() => editCustomer(customer)}
                                >
                                  {t('edit')}
                                </button>
                              )}
                              {permissions?.delete_customer && (
                                <button
                                  className="btn btn-sm btn-danger w-60px"
                                  onClick={() => handleDeleteCustomer(customer)}
                                >
                                  {t('delete')}
                                </button>
                              )}
                            </td>
                          )}
                          <td style={{ verticalAlign: 'super' }}>{customer.profile_name}</td>
                          <td style={{ verticalAlign: 'super' }}>{customer.phone_number}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="d-md-flex align-items-center">
                <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                  {t('pagination_info_1')} {(paginationParams.page - 1) * paginationParams.limit + 1}{' '}
                  {t('pagination_info_2')}{' '}
                  {paginationParams.page * paginationParams.limit < paginationParams.totalDocs
                    ? paginationParams.page * paginationParams.limit
                    : paginationParams.totalDocs}{' '}
                  {t('pagination_info_3')} {paginationParams.totalDocs} {t('pagination_info_4')}
                </div>

                <CustomPagination1
                  paginationParams={paginationParams}
                  setPage={value => {
                    console.log('on set page', value);
                    setPage(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <CustomModal1
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          modal_title={t('delete_customer')}
          onSubmit={onDeleteModalSubmit}
          submitButtonText={t('confirm')}
        >
          <div className="alert alert-danger">
            <h5>
              <i className="fa fa-info-circle"></i> {t('alert')}
            </h5>
            <p>{t('delete_confirmation_text')}</p>
          </div>
        </CustomModal1>
      </Container>
    </>
  );
};

export default withTranslation()(Customers1);
