import './App.css';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './_component/ProtectedRoute';
import PublicRoutes from './_component/PublicRoutes';
import LoginedLayout from './router/LoginedLayout';
import Login from './pages/Login';
import { selectAuth } from './store/reducers/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sessionExpired } from './store/reducers/auth';
import { LOCAL } from './api/api-manager';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from './firebase';
import { apiGenerator } from "./api/api-manager";

import { AppSettings } from './config/app-settings.js';
import { slideToggle } from './composables/slideToggle.js';
import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import SidebarRight from './components/sidebar-right/sidebar-right.jsx';
import TopMenu from './components/top-menu/top-menu.jsx';
import Content from './components/content/content.jsx';
import ThemePanel from './components/theme-panel/theme-panel.jsx';
import { ReactNotifications } from 'react-notifications-component';
import { SocketProvider } from "./_component/context/SocketProvider";
import { ConversationProvider } from "./_component/context/ConversationContext";
import NotificationSound from './new-message-notification-sound.mpeg';

const App = () => {
  const { isLogined } = useSelector( selectAuth );
  const authState = useSelector(selectAuth);
  const audioPlayer = useRef(null);

  const [appTheme, setAppTheme] = useState('');
  const [appDarkMode, setAppDarkMode] = useState(false);
  const [appGradientEnabled, setAppGradientEnabled] = useState(false);
  const [appHeaderNone, setAppHeaderNone] = useState(false);
  const [appHeaderFixed, setAppHeaderFixed] = useState(true);
  const [appHeaderInverse, setAppHeaderInverse] = useState(false);
  const [appHeaderMegaMenu, setAppHeaderMegaMenu] = useState(false);
  const [appHeaderLanguageBar, setAppHeaderLanguageBar] = useState(true);
  const [hasScroll, setHasScroll] = useState(false);
  const [appSidebarNone, setAppSidebarNone] = useState(true);
  const [appSidebarWide, setAppSidebarWide] = useState(false);
  const [appSidebarLight, setAppSidebarLight] = useState(false);
  const [appSidebarMinify, setAppSidebarMinify] = useState(false);
  const [appSidebarMobileToggled, setAppSidebarMobileToggled] = useState(false);
  const [appSidebarTransparent, setAppSidebarTransparent] = useState(false);
  const [appSidebarSearch, setAppSidebarSearch] = useState(false);
  const [appSidebarFixed, setAppSidebarFixed] = useState(true);
  const [appSidebarGrid, setAppSidebarGrid] = useState(false);
  const [appContentNone, setAppContentNone] = useState(false);
  const [appContentClass, setAppContentClass] = useState('');
  const [appContentFullHeight, setAppContentFullHeight] = useState(false);
  const [appTopMenu, setAppTopMenu] = useState(true);
  const [appTopMenuMobileToggled, setAppTopMenuMobileToggled] = useState(false);
  const [appSidebarTwo, setAppSidebarTwo] = useState(false);
  const [appSidebarEnd, setAppSidebarEnd] = useState(false);
  const [appSidebarEndToggled, setAppSidebarEndToggled] = useState(false);
  const [appSidebarEndMobileToggled, setAppSidebarEndMobileToggled] = useState(false);
  const [font, setFont] = useState({
    family: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
    size: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim(),
    weight: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
  });
  const [color, setColor] = useState({
    componentColor: window.getComputedStyle(document.body).getPropertyValue('--app-component-color').trim(),
    componentBg: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg').trim(),
    dark: window.getComputedStyle(document.body).getPropertyValue('--bs-dark').trim(),
    light: window.getComputedStyle(document.body).getPropertyValue('--bs-light').trim(),
    blue: window.getComputedStyle(document.body).getPropertyValue('--bs-blue').trim(),
    indigo: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim(),
    purple: window.getComputedStyle(document.body).getPropertyValue('--bs-purple').trim(),
    pink: window.getComputedStyle(document.body).getPropertyValue('--bs-pink').trim(),
    red: window.getComputedStyle(document.body).getPropertyValue('--bs-red').trim(),
    orange: window.getComputedStyle(document.body).getPropertyValue('--bs-orange').trim(),
    yellow: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow').trim(),
    green: window.getComputedStyle(document.body).getPropertyValue('--bs-green').trim(),
    success: window.getComputedStyle(document.body).getPropertyValue('--bs-success').trim(),
    teal: window.getComputedStyle(document.body).getPropertyValue('--bs-teal').trim(),
    cyan: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan').trim(),
    white: window.getComputedStyle(document.body).getPropertyValue('--bs-white').trim(),
    gray: window.getComputedStyle(document.body).getPropertyValue('--bs-gray').trim(),
    lime: window.getComputedStyle(document.body).getPropertyValue('--bs-lime').trim(),
    gray100: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100').trim(),
    gray200: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200').trim(),
    gray300: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300').trim(),
    gray400: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400').trim(),
    gray500: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim(),
    gray600: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim(),
    gray700: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700').trim(),
    gray800: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800').trim(),
    gray900: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim(),
    black: window.getComputedStyle(document.body).getPropertyValue('--bs-black').trim(),
    componentColorRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim(),
    componentBgRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb').trim(),
    darkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb').trim(),
    lightRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-light-rgb').trim(),
    blueRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb').trim(),
    indigoRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb').trim(),
    purpleRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb').trim(),
    pinkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb').trim(),
    redRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-red-rgb').trim(),
    orangeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb').trim(),
    yellowRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb').trim(),
    greenRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-green-rgb').trim(),
    successRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-success-rgb').trim(),
    tealRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb').trim(),
    cyanRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb').trim(),
    whiteRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-white-rgb').trim(),
    grayRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb').trim(),
    limeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb').trim(),
    gray100Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb').trim(),
    gray200Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb').trim(),
    gray300Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb').trim(),
    gray400Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb').trim(),
    gray500Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb').trim(),
    gray600Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb').trim(),
    gray700Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb').trim(),
    gray800Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim(),
    gray900Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb').trim(),
    blackRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-black-rgb').trim(),
  });

  const toggleAppSidebarMinify = e => {
    e.preventDefault();
    setAppSidebarMinify(!appSidebarMinify);
    if (localStorage) {
      localStorage.appSidebarMinify = !appSidebarMinify;
    }
  };

  const toggleAppSidebarMobile = e => {
    e.preventDefault();
    setAppSidebarMobileToggled(!appSidebarMobileToggled);
  };

  const handleSetAppSidebarNone = value => {
    setAppSidebarNone(value);
  };

  const handleSetAppSidebarMinified = value => {
    setAppSidebarMinify(value);
  };

  const handleSetAppSidebarWide = value => {
    setAppSidebarWide(value);
  };

  const handleSetAppSidebarLight = value => {
    setAppSidebarLight(value);
  };

  const handleSetAppSidebarTransparent = value => {
    setAppSidebarTransparent(value);
  };

  const handleSetAppSidebarSearch = value => {
    setAppSidebarSearch(value);
  };

  const handleSetAppSidebarFixed = value => {
    if (value === true && !appHeaderFixed) {
      alert('Default Header with Fixed Sidebar option is not supported. Proceed with Fixed Header with Fixed Sidebar.');

      setAppHeaderFixed(true);
      if (localStorage) {
        localStorage.appHeaderFixed = true;
      }
    }

    setAppSidebarFixed(value);
    if (localStorage) {
      localStorage.appSidebarFixed = value;
    }
  };

  const handleSetAppSidebarGrid = value => {
    setAppSidebarGrid(value);
    if (localStorage) {
      localStorage.appSidebarGrid = value;
    }
  };

  const toggleAppSidebarEnd = e => {
    e.preventDefault();

    setAppSidebarEndToggled(!appSidebarEndToggled);
  };

  const toggleAppSidebarEndMobile = e => {
    e.preventDefault();

    setAppSidebarEndMobileToggled(!appSidebarEndMobileToggled);
  };

  const handleSetAppSidebarEnd = value => {
    setAppSidebarEnd(value);
  };

  const handleSetAppContentNone = value => {
    setAppContentNone(value);
  };

  const handleSetAppContentClass = value => {
    setAppContentClass(value);
  };

  const handleSetAppContentFullHeight = value => {
    setAppContentFullHeight(value);
  };

  const handleSetAppHeaderNone = value => {
    setAppHeaderNone(value);
  };

  const handleSetAppHeaderFixed = value => {
    if (value === false && appSidebarFixed) {
      alert(
        'Default Header with Fixed Sidebar option is not supported. Proceed with Default Header with Default Sidebar.'
      );

      setAppSidebarFixed(false);
      if (localStorage) {
        localStorage.appSidebarFixed = false;
      }
    }

    setAppHeaderFixed(value);
    if (localStorage) {
      localStorage.appHeaderFixed = value;
    }
  };

  const handleSetAppHeaderInverse = value => {
    setAppHeaderInverse(value);
    if (localStorage) {
      localStorage.appHeaderInverse = value;
    }
  };

  const handleSetAppHeaderMegaMenu = value => {
    setAppHeaderMegaMenu(value);
  };

  const handleSetAppHeaderLanguageBar = value => {
    setAppHeaderLanguageBar(value);
  };

  const handleSetAppTopMenu = value => {
    setAppTopMenu(value);
  };

  const toggleAppTopMenuMobile = e => {
    e.preventDefault();

    slideToggle(document.querySelector('.app-top-menu'));
  };

  const handleSetAppSidebarTwo = value => {
    setAppSidebarTwo(value);

    setAppSidebarEndToggled(value);
  };

  const handleSetAppBoxedLayout = value => {
    if (value === true) {
      document.body.classList.add('boxed-layout');
    } else {
      document.body.classList.remove('boxed-layout');
    }
  };

  const handleSetAppDarkMode = value => {
    if (value === true) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }

    setAppDarkMode(value);
    this.handleSetColor();
    if (localStorage) {
      localStorage.appDarkMode = value;
    }
  };

  const handleSetAppGradientEnabled = value => {
    setAppGradientEnabled(value);
    if (localStorage) {
      localStorage.appGradientEnabled = value;
    }
  };

  const handleSetFont = () => {
    setFont({
      family: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
      size: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim(),
      weight: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
    });
  };

  const handleSetColor = () => {
    setColor({
      componentColor: window.getComputedStyle(document.body).getPropertyValue('--app-component-color').trim(),
      componentBg: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg').trim(),
      dark: window.getComputedStyle(document.body).getPropertyValue('--bs-dark').trim(),
      light: window.getComputedStyle(document.body).getPropertyValue('--bs-light').trim(),
      blue: window.getComputedStyle(document.body).getPropertyValue('--bs-blue').trim(),
      indigo: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim(),
      purple: window.getComputedStyle(document.body).getPropertyValue('--bs-purple').trim(),
      pink: window.getComputedStyle(document.body).getPropertyValue('--bs-pink').trim(),
      red: window.getComputedStyle(document.body).getPropertyValue('--bs-red').trim(),
      orange: window.getComputedStyle(document.body).getPropertyValue('--bs-orange').trim(),
      yellow: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow').trim(),
      green: window.getComputedStyle(document.body).getPropertyValue('--bs-green').trim(),
      success: window.getComputedStyle(document.body).getPropertyValue('--bs-success').trim(),
      teal: window.getComputedStyle(document.body).getPropertyValue('--bs-teal').trim(),
      cyan: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan').trim(),
      white: window.getComputedStyle(document.body).getPropertyValue('--bs-white').trim(),
      gray: window.getComputedStyle(document.body).getPropertyValue('--bs-gray').trim(),
      lime: window.getComputedStyle(document.body).getPropertyValue('--bs-lime').trim(),
      gray100: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100').trim(),
      gray200: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200').trim(),
      gray300: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300').trim(),
      gray400: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400').trim(),
      gray500: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim(),
      gray600: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim(),
      gray700: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700').trim(),
      gray800: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800').trim(),
      gray900: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim(),
      black: window.getComputedStyle(document.body).getPropertyValue('--bs-black').trim(),
      componentColorRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim(),
      componentBgRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb').trim(),
      darkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb').trim(),
      lightRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-light-rgb').trim(),
      blueRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb').trim(),
      indigoRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb').trim(),
      purpleRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb').trim(),
      pinkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb').trim(),
      redRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-red-rgb').trim(),
      orangeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb').trim(),
      yellowRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb').trim(),
      greenRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-green-rgb').trim(),
      successRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-success-rgb').trim(),
      tealRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb').trim(),
      cyanRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb').trim(),
      whiteRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-white-rgb').trim(),
      grayRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb').trim(),
      limeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb').trim(),
      gray100Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb').trim(),
      gray200Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb').trim(),
      gray300Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb').trim(),
      gray400Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb').trim(),
      gray500Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb').trim(),
      gray600Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb').trim(),
      gray700Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb').trim(),
      gray800Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim(),
      gray900Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb').trim(),
      blackRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-black-rgb').trim(),
    });
  };

  const handleSetAppTheme = value => {
    var newTheme = 'theme-' + value;
    for (var x = 0; x < document.body.classList.length; x++) {
      if (document.body.classList[x].indexOf('theme-') > -1 && document.body.classList[x] !== newTheme) {
        document.body.classList.remove(document.body.classList[x]);
      }
    }
    document.body.classList.add(newTheme);

    if (localStorage && value) {
      localStorage.appTheme = value;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
      var elm = document.getElementsByClassName('nvtooltip');
      for (var i = 0; i < elm.length; i++) {
        elm[i].classList.add('d-none');
      }
    };

    handleSetColor();
    handleSetFont();
    // handleSetAppTheme(appTheme);
    handleSetAppTheme('theme');
    if (appDarkMode) {
      handleSetAppDarkMode(true);
    }
    window.addEventListener('scroll', handleScroll);

    if (localStorage) {
      if (typeof localStorage.appTheme !== 'undefined') {
        document.body.classList.add('theme-' + localStorage.appTheme);
      }
      if (typeof localStorage.appDarkMode !== 'undefined') {
        handleSetAppDarkMode(localStorage.appDarkMode === 'true' ? true : false);
      }
      if (typeof localStorage.appHeaderInverse !== 'undefined') {
        handleSetAppHeaderInverse(localStorage.appHeaderInverse === 'true' ? true : false);
      }
      if (typeof localStorage.appGradientEnabled !== 'undefined') {
        handleSetAppGradientEnabled(localStorage.appDarkMode === 'true' ? true : false);
      }
      if (typeof localStorage.appSidebarGrid !== 'undefined') {
        handleSetAppSidebarGrid(localStorage.appSidebarGrid === 'true' ? true : false);
      }
      if (typeof localStorage.appSidebarMinify !== 'undefined') {
        handleSetAppSidebarMinified(localStorage.appSidebarMinify === 'true' ? true : false);
      }
      if (typeof localStorage.appSidebarFixed !== 'undefined') {
        handleSetAppSidebarFixed(localStorage.appSidebarFixed === 'true' ? true : false);
      }
      if (typeof localStorage.appHeaderFixed !== 'undefined') {
        handleSetAppHeaderFixed(localStorage.appHeaderFixed === 'true' ? true : false);
      }
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [appTheme, appDarkMode]);

  useEffect(() => {
    if (isLogined) {
      console.log('Initialize FCM');

      getToken(messaging, { vapidKey: 'BJevOdeDJteOat6sRZ5bVFx3UJFLL-nBaWVsMjoS-f_WJJoPCO6HjidQHNa2I4r3fw6ah2NmW4CDKpeRa_T6tV0' }).then((currentToken) => {
        console.log({ currentToken })

        if(currentToken) {
          // Send the token to your server and update the UI if necessary
          apiGenerator({
            method: 'PATCH',
            path: `users/web_push_token`,
            body: {
              token: currentToken,
            },
          }).then(res => {
              // alert(res.data.message);
          });
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });

      console.log('listen message');

      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        // ...
      });
    }
  }, [isLogined])

  const playNotificationSound = () => {
    try {
      audioPlayer.current.play(); 
      console.log('Playing audio...')     
    } catch (error) {
      console.log('Failed to play audio.')
    }
  };

  return (
    <AppSettings.Provider
      value={{
        appTheme,
        appDarkMode,
        appGradientEnabled,
        appHeaderNone,
        appHeaderFixed,
        appHeaderInverse,
        appHeaderMegaMenu,
        appHeaderLanguageBar,
        hasScroll,
        appSidebarNone,
        appSidebarWide,
        appSidebarLight,
        appSidebarMinify,
        appSidebarMobileToggled,
        appSidebarTransparent,
        appSidebarSearch,
        appSidebarFixed,
        appSidebarGrid,
        appContentNone,
        appContentClass,
        appContentFullHeight,
        appTopMenu,
        appTopMenuMobileToggled,
        appSidebarTwo,
        appSidebarEnd,
        appSidebarEndToggled,
        appSidebarEndMobileToggled,
        font,
        color,
        handleSetAppHeaderNone,
        handleSetAppHeaderInverse,
        handleSetAppHeaderLanguageBar,
        handleSetAppHeaderMegaMenu,
        handleSetAppHeaderFixed,
        handleSetAppSidebarNone,
        handleSetAppSidebarWide,
        handleSetAppSidebarLight,
        handleSetAppSidebarMinified,
        handleSetAppSidebarTransparent,
        handleSetAppSidebarSearch,
        handleSetAppSidebarFixed,
        handleSetAppSidebarGrid,
        handleSetAppContentNone,
        handleSetAppContentClass,
        handleSetAppContentFullHeight,
        handleSetAppTopMenu,
        handleSetAppSidebarTwo,
        handleSetAppSidebarEnd,
        handleSetAppBoxedLayout,
        handleSetAppDarkMode,
        handleSetAppGradientEnabled,
        handleSetAppTheme,
        handleSetColor,
        toggleAppSidebarMinify,
        toggleAppSidebarMobile,
        toggleAppTopMenuMobile,
        toggleAppSidebarEnd,
        toggleAppSidebarEndMobile,
      }}
    >
      <SocketProvider id={authState.userID} db={authState.database}>
        <ConversationProvider playNotificationSound={playNotificationSound}>
          <div
            className={
              'test-123 app ' +
              (appGradientEnabled ? 'app-gradient-enabled ' : '') +
              (appHeaderNone ? 'app-without-header ' : '') +
              (appHeaderFixed && !appHeaderNone ? 'app-header-fixed ' : '') +
              (appSidebarFixed ? 'app-sidebar-fixed ' : '') +
              (appSidebarNone ? 'app-without-sidebar ' : '') +
              (appSidebarEnd ? 'app-with-end-sidebar ' : '') +
              (appSidebarWide ? 'app-with-wide-sidebar ' : '') +
              (appSidebarLight ? 'app-with-light-sidebar ' : '') +
              (appSidebarMinify ? 'app-sidebar-minified ' : '') +
              (appSidebarMobileToggled ? 'app-sidebar-mobile-toggled ' : '') +
              (isLogined && appTopMenu ? 'app-with-top-menu ' : '') +
              (appContentFullHeight ? 'app-content-full-height ' : '') +
              (appSidebarTwo ? 'app-with-two-sidebar ' : '') +
              (appSidebarEndToggled ? 'app-sidebar-end-toggled ' : '') +
              (appSidebarEndMobileToggled ? 'app-sidebar-end-mobile-toggled ' : '') +
              (hasScroll ? 'has-scroll ' : '')
            }
          >
            {!appHeaderNone && <Header />}
            {!appSidebarNone && <Sidebar />}
            {appSidebarTwo && <SidebarRight />}
            {isLogined && appTopMenu && <TopMenu />}
            {!appContentNone && <Content />}
            {/* <ThemePanel /> */}

            <ReactNotifications />
            <audio ref={audioPlayer} src={NotificationSound} />
          </div>
        </ConversationProvider>
      </SocketProvider>
    </AppSettings.Provider>
  );
};

export default App;
