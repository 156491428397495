// ! Deprecated.
import React, { useEffect, useState } from 'react';
import Slider from 'react-slider-modal';
import 'animate.css/animate.min.css'
import { ThreeDots } from 'react-loader-spinner'
import { useConversation } from "./context/ConversationContext";
import { Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next'
import MsgRow from "../_component/MsgRow";
import dayjs from 'dayjs';

const MarkedMessageSlide = ({showMarkedMessage, setShowMarkedMessage, t, onClickMarkedMessage, setMessageFocus}) => {
      const [loading, setLoading] = useState(true);
      const [markedMessage, setMarkedMessage] = useState([]);
      const { updateCustomerMarkedMessageList } = useConversation();

      useEffect(() => {
            console.log(`showMarkedMessage : ${showMarkedMessage}`)
            // update markedList
            if(showMarkedMessage){
                  updateMarkedMessage()
                  setMessageFocus(undefined)
            }
      },[showMarkedMessage])

      const updateMarkedMessage = async () => {
            setLoading(true)
            updateCustomerMarkedMessageList().then((res) => {
                  console.log(res)
                  if (res?.data?.data) setMarkedMessage(res.data.data)
                  setLoading(false)
            }).catch((error) => {
                  setMarkedMessage([])
                  setLoading(false)
            })
      }

      return (
            <Slider
                  size = "large"
                  direction="right"
                  toggle = {showMarkedMessage}
                  closeModal = {() => setShowMarkedMessage(false)}
                  closeIcon={()=> {setShowMarkedMessage(false)}}
                  className= "slider-container-marked-message"
                  // outSideMouseEvent = {true}
            >
                  <div className="slider-container">
                        <div className="sliderHeader-marked-message"></div>
                        
                        <div className="sliderBody sliderBody-marked-message">

                              {/* <div className="sliderLeftBox">Hello World</div>
                              <div className="sliderRightBox">Right Box</div> */}

                              {/* Loading Spinner */}
                              {loading && 
                                    <div className="loading-marked-message">
                                          <ThreeDots 
                                                height="80" 
                                                width="80" 
                                                radius="9"
                                                color="#4fa94d" 
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                          />
                                    </div>
                              }

                              {/* Main List of Marked Message */}
                              {
                                    !loading && markedMessage && markedMessage.length > 0 && markedMessage.map((value, index) => {
                                          let send_type = 'receive';

                                          // media details
                                          let isMedia = false;
                                          let media_info = {
                                                media_id: value._id,
                                                media_type: '',
                                                mime_type: '',
                                                extension: ''
                                          }
                                          if(value.company_phone_number && (value.company_phone_number).includes(value.from)){ send_type = 'send'; }

                                          try {
                                                if(value.type && value.type != 'text') {
                                                  isMedia = true;
                        
                                                  switch (value.type) {
                                                    case 'sticker':
                                                      media_info.media_type = 'sticker';
                                                      media_info.mime_type = value.sticker.mime_type;
                                                      media_info.extension = value.sticker.extension;
                        
                                                      break;
                        
                                                    case 'audio':
                                                      media_info.media_type = 'audio';
                                                      media_info.mime_type = value.audio.mime_type;
                                                      media_info.extension = value.audio.extension;
                        
                                                      break;
                        
                                                    case 'document':
                                                      media_info.media_type = 'document';
                                                      media_info.filename = value.document?.filename;
                                                      media_info.caption = value.document?.caption;
                                                      media_info.mime_type = value.document.mime_type;
                                                      media_info.extension = value.document.extension;
                        
                                                      break;
                        
                                                    case 'image':
                                                      media_info.media_type = 'image';
                                                      media_info.caption = value.image?.caption;
                                                      media_info.mime_type = value.image.mime_type;
                                                      media_info.extension = value.image.extension;
                        
                                                      break;
                        
                                                    case 'video':
                                                      media_info.media_type = 'video';
                                                      media_info.caption = value.video?.caption;
                                                      media_info.mime_type = value.video.mime_type;
                                                      media_info.extension = value.video.extension;
                        
                                                      break;
                                                    case 'template':
                                                      isMedia = false
                                                      break
                                                    default:
                                                      break;
                                                  }

                                                }

                                                if(value.type){
                                                      return (
                                                            <div key={index} className={`${index == 0 ? 'body-marked-message-default' : ''} body-marked-message`} onClick={() => onClickMarkedMessage(value._id)}>
                                                                  {/* <div>{`${markedMessage}`}</div> */}
                                                                  <div
                                                                        style={{
                                                                              display: 'flex',
                                                                              flexDirection: 'row',
                                                                              justifyContent: 'space-between',
                                                                              paddingBottom: 10
                                                                        }}
                                                                  >
                                                                        <div>
                                                                              {send_type == 'receive' ? `${t('marked_msg_you')} ► ${value.customer_name}` : `${value.customer_name} ► You`}
                                                                        </div>
                                                                        {/* {dayjs(value.timestamp).format(`YYYY ${t('marked_msg_year')} MM ${t('marked_msg_month')} DD ${t('marked_msg_day')}`)} */}
                                                                        <div>
                                                                              {dayjs(value.timestamp).format(`YYYY-MM-DD hh:mm:ss`)}
                                                                        </div>
                                                                  </div>

                                                                  <MsgRow
                                                                        id = {value._id}
                                                                        message_id = {value.id}
                                                                        key = {'marked_'+value._id}
                                                                        rowKey = {value._id+'_'+index}
                                                                        type= {send_type}
                                                                        msg= {value.text?.body}
                                                                        marked = {value.marked}
                                                                        time= {value.timestamp}
                                                                        
                                                                        isMedia = {isMedia}
                                                                        media_info = {media_info}
                                                                        reaction_emoji_business = {value.reaction_business}
                                                                        reaction_emoji_client = {value.reaction_client}

                                                                        isMarkedMessage = {true}
                                                                  />
                                                            </div>

                                                            
                                                      )
                                                  }
                                          } catch (error) {
                                          console.log('Error occured while handling media info');
                                          console.log(error)
                                          }
                                    })
                                    
                              }
                        </div>

                        {/* <div className="sliderFooter">
                              <button
                                    className="btn btn-primary"
                                    onClick={() => setShowMarkedMessage(false)}
                              >
                                    Cancel
                              </button>
                        </div> */}
                  </div>
            </Slider>
      )
}

export default withTranslation()(MarkedMessageSlide)