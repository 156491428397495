import { createSlice } from "@reduxjs/toolkit";

export const businessSlice = createSlice({
    name: "business",
    initialState: {
        businessPhoneList: [],
        loading: false
    },
    reducers: {
        getBusinessPhoneList(state){
            state.loading = true
        },
        getBusinessPhoneListSuccess(state, payload){
            state.loading = false;
            let responseData = payload.resData.data;
            state.businessPhoneList = [];
            responseData.params.map((value) => {
                state.businessPhoneList.push(value.phone_number)
            })
        },
        getBusinessPhoneListFail(state){
            state.loading = false;
        },
    }
})

export const selectbusiness = (state) => state.business;
export const {
    getBusinessPhoneList,
    getBusinessPhoneListSuccess,
    getBusinessPhoneListFail,
} = businessSlice.actions;

export default businessSlice.reducer;