import { ListGroup } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

/**
 *
 * @param {[{ id: (string), title: (string)}]} optionList
 * @param {(e, option, index) => {}} onClickOption
 * @param {{[id]: any}} selectedOptions
 * @param {(option, index) => {} : string} href
 */
const SelectionList = ({ t, optionList, handleClickOption = (e, option, index) => {}, selectedOptions = {}, href, ...props }) => {
  return (
    <>
      <ListGroup {...props}>
        {optionList &&
          optionList.map((option, index) => {
            return (
              <ListGroup.Item
                key={option.id}
                action
                active={selectedOptions?.[option.id] !== undefined}
                href={href}
                onClick={e => handleClickOption(e, option, index)}
              >
                {option.title}
              </ListGroup.Item>
            );
          })}
      </ListGroup>
    </>
  );
};

export default withTranslation()(SelectionList);
