export const isPermitted = (permissions = {}, permissionName = "") => {
  if (!permissions) return false;
  let permission_splited = permissionName.split(".");
  return (
    permissions[permission_splited[0]][permission_splited[1]] !== undefined
  );
};

export const isOnePermitted = (permissions = {}, permissionNames = []) => {
  if (!permissions) return false;
  let result = false;
  permissionNames.map((item) => {
    if (!result) {
      let permission_splited = item.split(".");
      if (permissions[permission_splited[0]][permission_splited[1]]) {
        result = true;
      }
    }
  });
  return result;
};

export const isAllPermitted = (permissions = {}, permissionNames = []) => {
  if (!permissions) return false;
  let result = true;
  permissionNames.map((item) => {
    if (result) {
      let permission_splited = item.split(".");
      if (!permissions[permission_splited[0]][permission_splited[1]]) {
        result = false;
      }
    }
  });
  return result;
};

export const getPagingObject = (responseData = {}) => {
  let pagingObject = {};
  pagingObject.page = responseData.page;
  pagingObject.totalDocs = responseData.totalDocs;
  pagingObject.limit = responseData.limit;
  pagingObject.totalPages = responseData.totalPages;
  pagingObject.hasPrevPage = responseData.hasPrevPage;
  pagingObject.hasNextPage = responseData.hasNextPage;
  pagingObject.prevPage = responseData.prevPage;
  pagingObject.nextPage = responseData.nextPage;
  return pagingObject;
}
