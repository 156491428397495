import axios from "axios";

export function apiGenerator({ method, path, body, query, options }) {
    console.log('apiGenerator()', { method, path, body, query, options });
    let API_SERVER = process.env.REACT_APP_API_URL_DEV+'/api/';

    axios.interceptors.request.use((request) => {
        // let token = JSON.parse(localStorage.getItem("token"));

        // // console.log("token: ",token)
        // // const isLoggedIn = token?.length > 0;
        // const isLoggedIn = token?.lastActiveTime ? moment(token.lastActiveTime).add(tokenExpireTime,'seconds').format(DateTimeFormat) >= moment().format(DateTimeFormat) : false;
        // if (isLoggedIn) {
        //     token.lastActiveTime = moment();
        //     localStorage.setItem("token", JSON.stringify(token));
        //     request.headers.common.Authorization = `Bearer ${token.token}`;
        // }
        // console.log(request.sessions);
        return request;
    });
    try {
        const axiosCallback = async response => {
            console.log('axiosCallback', { options, response });

            if (typeof options?.callback !== 'undefined') {
                const alternativeReturn = await options.callback(response);
                console.log({ alternativeReturn })

                if (typeof alternativeReturn !== 'undefined') return alternativeReturn;
            }

            return response;
        }

        switch (method) {
            case "GET":
                return axios.get(API_SERVER + path, query).then(axiosCallback);
            case "PUT":
                return axios.put(API_SERVER + path, body).then(axiosCallback);
            case "POST":
                return axios.post(API_SERVER + path, body).then(axiosCallback);
            case "DELETE":
                return axios.delete(API_SERVER + path, body).then(axiosCallback);
            case "PATCH":
                return axios.patch(API_SERVER + path, body).then(axiosCallback);
            default: 
                return new Error(`Not supported axios method`)
        }
    }catch (error){
        console.log(error)
    }
    
}

export function POST(action, path, body, options) {
    return action({ method: "POST", path, body, options });
}

export function PATCH(action, path, body, options) {
    return action({ method: "PATCH", path, body, options });
}

export function PUT(action, path, body, options) {
    return action({ method: "PUT", path, body, options });
}

export function GET(action, path, query, options) {
    return action({ method: "GET", path, query, options });
}

export function DELETE(action, path, body, options) {
    return action({ method: "DELETE", path, body, options });
}

export function LOCAL(action, body) {
    return action({body});
}

export function getUserList(paginate) {
    return apiGenerator({
        method: 'POST',
        path: 'users',
        body: paginate
    })
}

export function getRoleList(paginate) {
    return apiGenerator({
        method: 'GET',
        path: 'roles',
        query: {
            params: paginate
        }
    })
}

export function getMessageTemplateList(paginate) {
    return apiGenerator({
        method: 'GET',
        path: 'message_templates',
        query: {
            params: paginate
        }
    })
}

export function getPermissionList() {
    return apiGenerator({
        method: 'GET',
        path: 'permissions',
        body: {}
    })
}

export function getLog(paginate) {
    return apiGenerator({
        method: 'GET',
        path: 'logs',
        query: {
            params: paginate
        }
    })
}