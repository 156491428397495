import { takeEvery } from "redux-saga/effects";
import { sagaBuild } from "./common";
import {
    setAutoReply,
    setAutoReplySuccess,
    setAutoReplyFail,
    getAutoReply,
    getAutoReplySuccess,
    getAutoReplyFail,
    changePassword,
    changePasswordSuccess,
    changePasswordFail,
    updateProfilePic,
    updateProfilePicSuccess,
    updateProfilePicFail,
    getProfilePic,
    getProfilePicSuccess,
    getProfilePicFail,
    removeProfilePic,
    removeProfilePicSuccess,
    removeProfilePicFail,
} from "../reducers/user";

function* setAutoReplyCaller({ payload }) {
    yield sagaBuild({ payload, successType: setAutoReplySuccess.type, failType: setAutoReplyFail.type });
}

function* getAutoReplyCaller({ payload }) {
    yield sagaBuild({ payload, successType: getAutoReplySuccess.type, failType: getAutoReplyFail.type });
}

function* changePasswordCaller({ payload }) {
    yield sagaBuild({ payload, successType: changePasswordSuccess.type, failType: changePasswordFail.type });
}

function* updateProfilePicCaller({ payload }) {
    yield sagaBuild({ payload, successType: updateProfilePicSuccess.type, failType: updateProfilePicFail.type });
}

function* getProfilePicCaller({ payload }) {
    yield sagaBuild({ payload, successType: getProfilePicSuccess.type, failType: getProfilePicFail.type });
}

function* removeProfilePicCaller({ payload }) {
    yield sagaBuild({ payload, successType: removeProfilePicSuccess.type, failType: removeProfilePicFail.type });
}

export default function* user() {
    yield takeEvery(setAutoReply.type, setAutoReplyCaller);
    yield takeEvery(getAutoReply.type, getAutoReplyCaller);
    yield takeEvery(changePassword.type, changePasswordCaller);
    yield takeEvery(updateProfilePic.type, updateProfilePicCaller);
    yield takeEvery(getProfilePic.type, getProfilePicCaller);
    yield takeEvery(removeProfilePic.type, removeProfilePicCaller);
}