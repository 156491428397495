import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slider-modal';
import 'animate.css/animate.min.css'
import { ThreeDots } from 'react-loader-spinner'
import { useConversation } from "./context/ConversationContext";
import { Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next'
import dayjs from 'dayjs';

const SearchMessageSlide = ({showSearchMessage, setShowSearchMessage, t, onClickSearchMessage}) => {
      const [loading, setLoading] = useState(true);
      const [searchStr, setSearchStr] = useState('');
      const [searchMessage, setSearchMessage] = useState([]);
      const [filteredMessage, setFilteredMessage] = useState([]);
      const { getSearchMessageList } = useConversation();

      useEffect(() => {
            console.log(`showSearchMessage : ${showSearchMessage}`)
            // update markedList
            if(showSearchMessage){
                  setLoading(false)
                  setSearchStr('')
                  setFilteredMessage([])
            }
      },[showSearchMessage])

      useEffect(() => {
            console.log(`searching ... ${searchStr}`)
            setLoading(true)
            // delay call list
            const timeOutId = setTimeout(async () => {
                  try {
                        const res = await getSearchMessageList(searchStr, '1970-01-01');

                        console.log(`update Search message...`)
                        console.log(res)
                        if (res?.data?.data) setSearchMessage(res.data.data)
                        setLoading(false)     
                  } catch (error) {
                        setSearchMessage([])
                        setLoading(false)
                  }
                  
            }, 500);
            return () => clearTimeout(timeOutId);

      }, [searchStr])

      useEffect(() => {
            var filter = (searchMessage).filter((value) => value.text?.body.includes(searchStr))
            if(searchStr == ''){
                  filter = []
            }
            setFilteredMessage(filter)
      },[searchMessage])

      const renderMessage = (message) => {
            var msg = '';
            if(message.type == 'text'){
                  msg = message.text?.body
            }else{
                  msg = message.text?.body.replace('<br>', ' ')
            }
            return msg;
      }

      return (
            <Slider
                  size = "large"
                  direction="right"
                  toggle = {showSearchMessage}
                  closeModal = {() => setShowSearchMessage(false)}
                  closeIcon={()=> {setShowSearchMessage(false)}}
                  className= "slider-container-marked-message"
                  // outSideMouseEvent = {true}
            >
                  <div className="slider-container">
                        <div className="sliderHeader-marked-message"></div>
                        
                        <div className="sliderBody sliderBody-marked-message">

                              <input 
                                    type="search" 
                                    className="searchMessageInput form-control" 
                                    onChange={(e) => setSearchStr(e.target.value)}
                                    value= {searchStr}
                              /> 
                              <hr></hr>
                              <div className="search-slide-container">

                                    {/* Main List of Marked Message */}
                                    {
                                          // Loading Spinner
                                          loading && 
                                                <div className="loading-marked-message">
                                                      <ThreeDots 
                                                            height="80" 
                                                            width="80" 
                                                            radius="9"
                                                            color="#4fa94d" 
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClassName=""
                                                            visible={true}
                                                      />
                                                </div>
                                    }

                                    {
                                          // have at least one record
                                          !loading && 
                                          (filteredMessage.length > 0) && (filteredMessage)
                                                .map((filteredMessage, index) => {
                                                      return (
                                                            <div key={`msg-search-${filteredMessage._id}`}>
                                                                  <div className="search-slide-row" onClick={() => onClickSearchMessage(filteredMessage._id)}>
                                                                  
                                                                        <div>
                                                                              {dayjs(filteredMessage.timestamp).format(`YYYY-MM-DD hh:mm:ss`)}
                                                                        </div>
                                                                        <div className="search-slide-text" key={`msg-search-${filteredMessage._id}`}>
                                                                              {renderMessage(filteredMessage)}
                                                                        </div>
                                                                  </div>
                                                                  <hr></hr>
                                                            </div>
                                                      )
                                                })
                                          
                                    }

                                    {
                                          // no record
                                          !loading && (filteredMessage.length == 0) &&
                                          <div>
                                                {t('no_result')}
                                          </div>
                                    }
                              </div>
                        </div>

                        {/* <div className="sliderFooter">
                              <button
                                    className="btn btn-primary"
                                    onClick={() => setShowSearchMessage(false)}
                              >
                                    Cancel
                              </button>
                        </div> */}
                  </div>
            </Slider>
      )
}

export default withTranslation()(SearchMessageSlide)