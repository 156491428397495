import React, { useEffect, useState, useMemo, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import { POST, GET, PATCH } from "../api/api-manager";
import {
  getCustomerList,
} from "../store/reducers/customers";
import {
  selectCustomers,
  addCustomer,
  updateCustomer,
  deleteCustomer,
} from "../store/reducers/customers";
import Table from "react-bootstrap/Table";
import CustomModal1 from "../_component/CustomModal1";
import { Button, Form, InputGroup } from "react-bootstrap";
import PageTitle from "../_component/PageTitle";
import CustomPagination from "../_component/Pagination";
import { withTranslation } from 'react-i18next'

const Customers = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const {
    customerList,
    customerPaginationParams,
    loading,
  } = useSelector(selectCustomers);

  // Default Group list
  const [customerListState, setCustomerListState] = useState([]);

  // Add/Edit modal
  const [addEditModalState, setAddEditModalState] = useState(false);
  const [addEditModalTypeState, setAddEditModalTypeState] = useState(null);

  const [currentName, setCurrentName] = useState("");
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState("");

  const [editCustomerId, setEditCustomerId] = useState(null);

  const [page, setPage] = useState(1);
  const [displayPage, setDisplayPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState({
    totalDocs: 0,
    limit: 5,
    page: 1,
    totalPages: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  });

  useEffect(() => {
    let reqPaginationParams = Object.assign({}, paginationParams);
    if (page) {
      reqPaginationParams.page = page;
      dispatch(
        POST(getCustomerList, "customers", {
          page: reqPaginationParams.page,
          limit: reqPaginationParams.limit,
        })
      );
    }
  }, [page]);

  useEffect(() => {
    if (!loading) setDisplayPage(page);
  }, [loading]);

  useEffect(() => {
    setCustomerListState(customerList);
    setPaginationParams(customerPaginationParams);
    setPage(customerPaginationParams.page);
  }, [customerList]);

  const openEditModal = (type, customer = {}) => {
    if (type != "Add") {
      setCurrentName(customer.profile_name);
      setCurrentPhoneNumber(customer.phone_number);
      setEditCustomerId(customer._id);
    }
    setAddEditModalState(true);
    setAddEditModalTypeState(type);
  };

  const deleteCustomerFunction = (customer) => {
    let reqPaginationParams = Object.assign({}, paginationParams);
    reqPaginationParams.page = page;
    dispatch(
      POST(deleteCustomer, `customers/delete`, {
        id: customer._id,
        paging: {
          page: 1,
          limit: reqPaginationParams.limit,
        },
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let reqPaginationParams = Object.assign({}, paginationParams);
    reqPaginationParams.page = page;
    if (addEditModalTypeState == "Add") {
      dispatch(
        POST(addCustomer, "customers/create", {
          paging: {
            page: 1,
            limit: reqPaginationParams.limit,
          },
          profile_name: currentName,
          phone_number: currentPhoneNumber
        })
      );
    } else {
      dispatch(
        PATCH(updateCustomer, `customers/${editCustomerId}`, {
          paging: {
            page: 1,
            limit: reqPaginationParams.limit,
          },
          profile_name: currentName,
          phone_number: currentPhoneNumber
        })
      );
    }

    setAddEditModalState(false);
    setCurrentName("");
    setCurrentPhoneNumber("");
    setEditCustomerId(null);

  };

  const clearModal = () => {
    setAddEditModalState(false);
    setCurrentName("");
    setCurrentPhoneNumber("");
    setEditCustomerId(null);
  }

  const editModal = useMemo(() => {
    return (
      <CustomModal1
        showModal={addEditModalState}
        setShowModal={setAddEditModalState}
        modal_title={t("customers")}
        onSubmit={() => {
          //updateAgent();
        }}
        centered={true}
        onHideFunction={() => clearModal()}
        showSubmitButton={false}
      >
        <div className="d-flex flex-column">
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridUsername">
                  <Form.Label>{t("name")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("name")}
                    name="Name"
                    value={currentName}
                    onChange={(e) => {
                      setCurrentName(e.target.value);
                    }}
                    // defaultValue={editMode ? editUser.username : ""}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridRole">
                  <Form.Label>{t("phone_number")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("phone_number")}
                    name="phoneNumber"
                    value={currentPhoneNumber}
                    onChange={(e) => {
                      setCurrentPhoneNumber(e.target.value);
                    }}
                    // defaultValue={editMode ? editUser.username : ""}
                  />
                </Form.Group>
              </Row>
              <InputGroup className="mb-3">
                <Button type={"submit"}>{addEditModalTypeState}</Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
      </CustomModal1>
    );
  });

  return (
    <>
      <Container>
        <PageTitle title={t("customer_management")} lastPage={false} />

        <Row className="my-2">
          <Col>
            <Button variant="success" onClick={() => openEditModal("Add")}>
              {t("add_customer")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t("name")}</th>
                  <th>{t("phone_number")}</th>
                  <th>{t("action")}</th>
                </tr>
              </thead>
              <tbody>
                {customerListState.map((customer, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {displayPage && ((displayPage - 1) * paginationParams.limit + 1 + index)}
                      </td>
                      <td>{customer.profile_name}</td>
                      <td>
                        {customer.phone_number}
                      </td>
                      <td>
                        <Button
                          variant="info"
                          className="m-1"
                          onClick={() => openEditModal("Edit", customer)}
                        >
                          {t("edit")}
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => deleteCustomerFunction(customer)}
                        >
                          {t("delete")}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <CustomPagination
            paginationParams={paginationParams}
            setPage={setPage}
          />
        </Row>
        {/* Modal for edit group */}
        {editModal}
      </Container>
    </>
  );
};

export default withTranslation()(Customers);
