import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slider-modal';
import 'animate.css/animate.min.css';
import { ThreeDots } from 'react-loader-spinner';
import { useConversation } from './context/ConversationContext';
import { Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import MsgRow from './MsgRow';
import dayjs from 'dayjs';
import { BsSearch } from 'react-icons/bs';

const MarkedMessageSlide = ({ t, showMarkedMessage, setShowMarkedMessage, onClickMarkedMessage, setMessageFocus }) => {
  const [loading, setLoading] = useState(true);
  const [markedMessage, setMarkedMessage] = useState([]);
  const { updateCustomerMarkedMessageList } = useConversation();

  useEffect(() => {
    console.log(`showMarkedMessage : ${showMarkedMessage}`);
    // update markedList
    if (showMarkedMessage) {
      updateMarkedMessage();
      setMessageFocus(undefined);
    }
  }, [showMarkedMessage]);

  const updateMarkedMessage = async () => {
    setLoading(true);
    updateCustomerMarkedMessageList()
      .then(res => {
        console.log(res);
        if (res?.data?.data) setMarkedMessage(res.data.data);
        setLoading(false);
      })
      .catch(error => {
        setMarkedMessage([]);
        setLoading(false);
      });
  };

  const renderMessage = message => {
    var msg = '';
    if (message.type == 'text') {
      msg = message.text?.body;
    } else {
      msg = message.text?.body.replace('<br>', ' ');
    }
    return msg;
  };

  return (
    <div
      className="conversation-utility-sidebar"
      style={{
        display: showMarkedMessage ? 'flex' : 'none',
        flexDirection: 'column',
        flex: '0 0 480px',
        overflow: 'hidden',
        background: '#ffffff',
      }}
    >
      {/* Topbar */}
      <div style={{ backgroundColor: '#f0f2f5', display: 'flex', alignItems: 'center', padding: '16px' }}>
        <svg
          stroke="#54656f"
          fill="#54656f"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1.4em"
          width="1.4em"
          xmlns="http://www.w3.org/2000/svg"
          onClick={e => setShowMarkedMessage(false)}
        >
          <path fill="none" stroke="#54656f" strokeWidth="2" d="M3,3 L21,21 M3,21 L21,3"></path>
        </svg>
        <div style={{ paddingLeft: '12px', fontSize: '16px', color: '#54656f' }}>{t('starred_message')}</div>
      </div>
      <div className="search-slide-container" style={{ overflowY: 'auto' }}>
        {/* Main List of Marked Message */}
        {
          // Loading Spinner
          loading && (
            <div className="loading-marked-message">
              <ThreeDots
                height="50"
                width="50"
                radius="4"
                color="#54656f"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )
        }
        {
          // have at least one record
          !loading &&
            markedMessage.length > 0 &&
            markedMessage.map((value, index) => {
              let send_type = 'receive';

              // media details
              let isMedia = false;
              let media_info = {
                media_id: value._id,
                media_type: '',
                mime_type: '',
                extension: '',
              };
              if (value.company_phone_number && value.company_phone_number.includes(value.from)) {
                send_type = 'send';
              }

              try {
                if (value.type && value.type != 'text') {
                  isMedia = true;

                  switch (value.type) {
                    case 'sticker':
                      media_info.media_type = 'sticker';
                      media_info.mime_type = value.sticker.mime_type;
                      media_info.extension = value.sticker.extension;

                      break;

                    case 'audio':
                      media_info.media_type = 'audio';
                      media_info.mime_type = value.audio.mime_type;
                      media_info.extension = value.audio.extension;

                      break;

                    case 'document':
                      media_info.media_type = 'document';
                      media_info.filename = value.document?.filename;
                      media_info.caption = value.document?.caption;
                      media_info.mime_type = value.document.mime_type;
                      media_info.extension = value.document.extension;

                      break;

                    case 'image':
                      media_info.media_type = 'image';
                      media_info.caption = value.image?.caption;
                      media_info.mime_type = value.image.mime_type;
                      media_info.extension = value.image.extension;

                      break;

                    case 'video':
                      media_info.media_type = 'video';
                      media_info.caption = value.video?.caption;
                      media_info.mime_type = value.video.mime_type;
                      media_info.extension = value.video.extension;

                      break;
                    case 'template':
                      isMedia = false;
                      break;
                    default:
                      break;
                  }
                }

                if (value.type) {
                  return (
                    <div
                      key={index}
                      className={`${index == 0 ? 'body-marked-message-default' : ''} body-marked-message`}
                      onClick={() => onClickMarkedMessage(value._id)}
                    >
                      {/* <div>{`${markedMessage}`}</div> */}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          paddingBottom: 10,
                        }}
                      >
                        <div>
                          {send_type == 'receive'
                            ? `${t('marked_msg_you')} ► ${value.customer_name}`
                            : `${value.customer_name} ► You`}
                        </div>
                        {/* {dayjs(value.timestamp).format(`YYYY ${t('marked_msg_year')} MM ${t('marked_msg_month')} DD ${t('marked_msg_day')}`)} */}
                        <div>{dayjs(value.timestamp).format(`YYYY-MM-DD hh:mm:ss`)}</div>
                      </div>

                      <MsgRow
                        id={value._id}
                        message_id={value.id}
                        key={'marked_' + value._id}
                        rowKey={value._id + '_' + index}
                        type={send_type}
                        msg={value.text?.body}
                        template={value?.template}
                        marked={value.marked}
                        time={value.timestamp}
                        messageType={value.type}
                        isMedia={isMedia}
                        media_info={media_info}
                        reaction_emoji_business={value.reaction_business}
                        reaction_emoji_client={value.reaction_client}
                        previewMode={true}
                      />
                    </div>
                  );
                }
              } catch (error) {
                console.log('Error occured while handling media info');
                console.log(error);
              }
            })
        }
        {
          // no record
          !loading && markedMessage.length == 0 && (
            <div style={{ padding: '16px', textAlign: 'center' }}>{t('no_result')}</div>
          )
        }
      </div>
    </div>
  );
};

export default withTranslation()(MarkedMessageSlide);
