import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import { ArrowLeft } from 'react-bootstrap-icons';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { getRoleList, getPermissionList, apiGenerator } from "../api/api-manager";
import { withTranslation } from 'react-i18next'

const AddUsers = (props) => {
    const { t } = props;
    const [roleList, setRoleList] = useState([]);
    const [permissionList, setPermissionList] = useState([]);
    const [editUser, setEditUser] = useState([]);
    const [editMode, setEditMode] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const nameRef = useRef();
    const usernameRef = useRef();
    const passwordRef = useRef();
    const roleRef = useRef();

    useEffect(() => {
        getRoleList().then(res => {
            let resposnse = res.data;
            if(resposnse.status){
              setRoleList(resposnse.params)
            }
        })

        getPermissionList().then(res => {
            let resposnse = res.data;
            if(resposnse.status){
              setPermissionList(resposnse.params)
            }
        })

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(location.state?.user){
            setEditUser(location.state.user);
            setEditMode(true)
        }
    }, [location])

    const onFormSubmit = (event) => {
        
        // console.log(usernameRef.current.value)
        // console.log(roleRef.current.value)
        const formData = new FormData(event.currentTarget);
        // console.log(event.target.elements.username.value)

        const submitted_checkedbox = [];
        document.querySelectorAll(":checked").forEach((ele) => {
            if(ele.value == 'on'){
                submitted_checkedbox.push(ele.id)
            }
        })
        event.preventDefault();


        var error = false;

        for (let [key, value] of formData.entries()) {
            // console.log(key, value);
            // check empty 
            if(key == 'username' || key == 'password' || key == 'name'){
                if(value == '' || value == null || value == undefined){
                    error = true;
                }
            }

        }

        if(error){
            alert(`Please filled in required information`)
        }else{
            
            let request_user = {    
                name: formData.get('name'),
                username: formData.get('username'),
                password: formData.get('password'),
                role_id: formData.get('role_id'),
                permission: submitted_checkedbox,
                auto_reply_messages: [],
                enable_auto_reply: false,
            }
            console.log(request_user)

            if(editMode){
                apiGenerator({
                    method: 'PATCH',
                    path: `users/${editUser._id}`,
                    body: request_user
                }).then(res => {
                    alert(res.data.message);
                    if(res.data.status){
                        navigate('/users')
                    }
                })
            }else{
                apiGenerator({
                    method: 'POST',
                    path: 'users/create',
                    body: request_user
                }).then(res => {
                    alert(res.data.message);
                    if(res.data.status){
                        navigate('/users')
                    }
                })
            }
        }
    }

    return (
        <Container>
            <Form style={{padding:10}} onSubmit={onFormSubmit} id='userForm'>
            <Row className="mb-3 px-3">
                <div style={{display: 'flex', textAlign:'center'}}>
                    <Link to="/users">
                        <ArrowLeft 
                            color="royalblue" 
                            size={20} 
                            style={{
                                marginTop:'auto', 
                                marginBottom: 'auto', 
                                cursor: 'pointer'
                            }} />
                        </Link>
                    <h3 style={{paddingLeft: 30, marginTop:'auto', marginBottom: 'auto'}}>
                        {editMode? t('edit_users') : t('add_users')}
                    </h3>
                </div>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridUsername">
                    <Form.Label>{ t('username') }</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder={ t('enter_username') } 
                        ref={usernameRef} 
                        name="username" 
                        defaultValue = {editMode? editUser.username : ''}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>{ t('password') }</Form.Label>
                    <Form.Control 
                        type="password" 
                        placeholder={ t('password') } 
                        ref={passwordRef} 
                        name="password"
                        defaultValue = {editMode? editUser.password : ''}
                    />
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridName">
                    <Form.Label>{ t('name') }</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder={ t('name') }
                        ref={nameRef} 
                        name="name"
                        defaultValue = {editMode? editUser.name : ''}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridRole">
                <Form.Label>{ t('role') }</Form.Label>
                    <div>
                        <Form.Select
                            ref={roleRef} 
                            name="role_id"
                            value = {editMode ? editUser.role_id : undefined}
                        >
                            {
                                roleList.map((role) => {
                                    
                                    return (
                                        <option value={`${role._id}`} key={role._id}>{role.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </div>
                </Form.Group>
            </Row>

            <Row className="mb-3">

                <Form.Group as={Col} controlId="formGridPermission">
                <Form.Label>{ t('permission') }</Form.Label>
                    <div>
                    {
                        permissionList && permissionList.map((permission, index) => {
                            if(editMode && editUser.permission.includes(permission.key)){
                                return (
                                    <Form.Check
                                        key = {index}
                                        inline
                                        label= {permission.name}
                                        type={'checkbox'}
                                        id={permission.key}
                                        defaultChecked = {true}
                                    />
                                )
                            }else{

                                return (
                                    <Form.Check
                                        key = {index}
                                        inline
                                        label= {permission.name}
                                        type={'checkbox'}
                                        id={permission.key}
                                    />
                                )
                            }
                        })
                    }
                    
                    </div>
                </Form.Group>

            </Row>


        <Row className="mb-3">
            <Button variant="primary" type="submit">
                { t('submit') }
            </Button>
        </Row>
        </Form>
        </Container>
    );
};

export default withTranslation()(AddUsers);
