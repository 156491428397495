const Menu = permissions => {
  const menuAry = [];

  menuAry.push({ path: '/dashboard', icon: 'fa fa-chart-pie', i18n: 'dashboard' });

  if (permissions?.view_all_conversations || permissions?.view_own_conversations)
    menuAry.push({ path: '/chats', icon: 'fa fa-message', i18n: 'chats' });

  if (permissions?.view_customer) menuAry.push({ path: '/customers', icon: 'fa fa-users', i18n: 'customers' });

  if (permissions?.view_agent) menuAry.push({ path: '/users', icon: 'fa fa-user-tie', i18n: 'agents' });

  if (permissions?.view_role) menuAry.push({ path: '/roles', icon: 'fa fa-id-badge', i18n: 'roles' });

  if (permissions?.view_agent_group)
    menuAry.push({ path: '/agent_groups', icon: 'fa fa-user-group', i18n: 'agent_groups' });

  if (true) menuAry.push({ path: '/message_templates', icon: 'fa fa-id-badge', i18n: 'message_template' });

  if (permissions?.view_report)
    menuAry.push({ path: '/conversation_report', icon: 'fa fa-chart-line', i18n: 'conversation_report' });

  if (permissions?.view_system_log)
    menuAry.push({ path: '/system_log', icon: 'fa fa-file-signature', i18n: 'system_log' });

  // Non-protected
  if (true) menuAry.push({ path: '/alert_setting', icon: 'fa fa-bell', i18n: 'alert_setting' });

  return menuAry;
};

export default Menu;
