import { takeEvery } from "redux-saga/effects";
import { sagaBuild } from "./common";
import * as AgentGroupsReducers from "../reducers/agentGroups";

function* getAgentGroupListCaller({ payload }) {
    yield sagaBuild({ payload, successType: AgentGroupsReducers.getAgentGroupListSuccess.type, failType: AgentGroupsReducers.getAgentGroupListFail.type });
}

function* getParentAgentGroupListCaller({ payload }) {
    yield sagaBuild({ payload, successType: AgentGroupsReducers.getParentAgentGroupListSuccess.type, failType: AgentGroupsReducers.getParentAgentGroupListFail.type });
}

function* addAgentGroupCaller({ payload }) {
    yield sagaBuild({ payload, successType: AgentGroupsReducers.addAgentGroupSuccess.type, failType: AgentGroupsReducers.addAgentGroupFail.type });
}

function* updateAgentGroupCaller({ payload }) {
    yield sagaBuild({ payload, successType: AgentGroupsReducers.updateAgentGroupSuccess.type, failType: AgentGroupsReducers.updateAgentGroupFail.type });
}

function* deleteAgentGroupCaller({ payload }) {
    yield sagaBuild({ payload, successType: AgentGroupsReducers.deleteAgentGroupSuccess.type, failType: AgentGroupsReducers.deleteAgentGroupFail.type });
}

export default function* agentGroups() {
    yield takeEvery(AgentGroupsReducers.getAgentGroupList.type, getAgentGroupListCaller);
    yield takeEvery(AgentGroupsReducers.getParentAgentGroupList.type, getParentAgentGroupListCaller);
    yield takeEvery(AgentGroupsReducers.addAgentGroup.type, addAgentGroupCaller);
    yield takeEvery(AgentGroupsReducers.updateAgentGroup.type, updateAgentGroupCaller);
    yield takeEvery(AgentGroupsReducers.deleteAgentGroup.type, deleteAgentGroupCaller);
}