import React, { useEffect, useState, useRef } from 'react';
import { HiEmojiHappy } from 'react-icons/hi';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Tooltip, OverlayTrigger, Image } from 'react-bootstrap';
import EmojiPicker from 'emoji-picker-react';
import { useConversation } from './context/ConversationContext';
import { withTranslation } from 'react-i18next';
import { AiFillStar } from 'react-icons/ai';
dayjs.extend(relativeTime);

const API_BASE_URL = process.env.REACT_APP_API_URL_DEV;

const renderCaption = props => {
  if (props.media_info?.caption) {
    return props.media_info.caption.split('<br>').map((value, index) => {
      return (
        <div key={index}>
          {value}
          <br></br>
        </div>
      );
    });
  }

  return null;
};

const formatBodyPreview = (bodyText, { variables = [] } = {}) => {
  // console.log('formatBodyPreview()', { bodyText, variables });
  // Replace bold formatting
  let formattedText = bodyText.replace(/(^|\W)\*(?!\s)(.*?)(?<!\s)\*(?!\w)/g, '$1<strong>$2</strong>');

  // Replace italic formatting
  formattedText = formattedText.replace(/(^|\W)_(?!\s)(.*?)(?<!\s)_(?!\w)/g, '$1<em>$2</em>');

  // Replace strikethrough formatting
  formattedText = formattedText.replace(/(^|\W)~(?!\s)(.*?)(?<!\s)~(?!\w)/g, '$1<del>$2</del>');

  // Replace monospace formatting
  formattedText = formattedText.replace(/(^|\W)```(?!\s)(.*?)(?<!\s)```(?!\w)/g, '$1<code>$2</code>');

  if (Array.isArray(variables)) {
    for (const key in variables) {
      const variableNumber = Number(key) + 1;
      const value = variables[key];

      console.log('test joseph', { variableNumber, value });

      if (value === '') {
        // Skip if value is not provided.
        continue;
      }

      formattedText = formattedText.replace(`{{${variableNumber}}}`, value);
    }
  }

  // Update the preview with the formatted text
  return formattedText;
};

const renderMessage = props => {
  let s3PresignedUrl;

  // if (!props.isMedia && props.msg) {
  if (props.messageType === 'text' && props.msg) {
    return props.msg
      .replace(/\n/g, '<br>')
      .split('<br>')
      .map((value, index) => {
        return (
          <div key={index}>
            {value}
            <br></br>
          </div>
        );
      });
  }

  if (props.messageType === 'template') {
    // if (typeof props?.template?.message_template?.components !== 'undefined') console.log({ components: props?.template?.message_template?.components });
    // * Get template message entered variable values.
    const messageBodyVariables = [];

    if (Array.isArray(props?.template?.components)) {
      for (const component of props.template.components) {
        switch (component?.type) {
          case 'body':
            if (!Array.isArray(component?.parameters)) break; // No parameters

            for (const parameter of component.parameters) {
              messageBodyVariables.push(parameter.text);
            }

            break;
        
          default:
            break;
        }
      }
    }

    console.log({ messageBodyVariables });

    return props?.template?.message_template?.components?.map((component, index) => {
      switch (component?.type) {
        case 'HEADER':
          let mediaStorage = props.template?.message_template.mediaStorage?.[0];
          let originalFilename = mediaStorage?.original_name;
          let mimeType = mediaStorage?.mime_type;
          let s3PresignedUrl = mediaStorage?.s3PresignedUrl;

          switch (component?.format) {
            case 'TEXT':
              return (
                <div key={index} className="msg-header" style={{ color: 'black' }}>
                  {component.text}
                </div>
              );

            case 'IMAGE':
              return (
                <Image
                  src={s3PresignedUrl}
                  className="msg-image"
                  onLoad={() => {
                    props.onLoad();
                  }}
                />
              );

            case 'VIDEO':
              return (
                <video
                  width="320"
                  height="240"
                  controls
                  onLoadedData={() => {
                    props.onLoad();
                  }}
                >
                  <source
                    src={s3PresignedUrl}
                    type={mimeType}
                  />
                </video>
              );

            case 'DOCUMENT':
              return (
                <a
                  href={s3PresignedUrl}
                  target="_blank"
                >
                  Download {originalFilename ? `${originalFilename}` : 'File'}
                </a>
              );

            default:
              break;
          }
          break;

        case 'BODY':
          const formattedBodyText = formatBodyPreview(component.text, { variables: messageBodyVariables.length > 0 ? messageBodyVariables : component?.example?.body_text?.[0] }).replace(/\n/g, '<br>');
          return (
            <div
              key={index}
              className="msg-body"
              style={{ color: 'black' }}
              
            >
							<div className='msg-text' dangerouslySetInnerHTML={{
                __html: formattedBodyText,
              }}></div>
						</div>
          );

        case 'FOOTER':
          return (
            <div key={index} className="msg-footer fw-light" style={{ color: 'grey' }}>
              {component.text}
            </div>
          );

        default:
          break;
      }
    });
  }

  s3PresignedUrl = props.media_info?.s3PresignedUrl;

  // Sticker
  if (props.media_info.media_type == 'sticker') {
    return (
      <Image
        src={
          s3PresignedUrl
            ? s3PresignedUrl
            : `${API_BASE_URL}/images/${props.media_info.media_id}.${props.media_info.extension}`
        }
        className="msg-sticker"
        onLoad={() => {
          props.onLoad();
        }}
      />
    );
  }

  // Audio
  if (props.media_info.media_type == 'audio') {
    return (
      <>
        <audio controlsList="nodownload" controls>
          <source
            src={
              s3PresignedUrl
                ? s3PresignedUrl
                : `${API_BASE_URL}/audio/${props.media_info.media_id}.${props.media_info.extension}`
            }
            type={`${props.media_info.mime_type}`}
          />
        </audio>
      </>
    );
  }

  // Document
  if (props.media_info.media_type == 'document') {
    return (
      <>
        <a
          href={
            s3PresignedUrl
              ? s3PresignedUrl
              : `${API_BASE_URL}/document/${props.media_info.media_id}.${props.media_info.extension}`
          }
          target="_blank"
        >
          Download {props.media_info.filename ? `${props.media_info.filename}` : 'File'}
        </a>
        {renderCaption(props)}
      </>
    );
  }

  // Image
  if (props.media_info.media_type == 'image') {
    return (
      <>
        <Image
          src={
            s3PresignedUrl
              ? s3PresignedUrl
              : `${API_BASE_URL}/images/${props.media_info.media_id}.${props.media_info.extension}`
          }
          className="msg-image"
          onLoad={() => {
            props.onLoad();
          }}
        />
        {renderCaption(props)}
      </>
    );
  }

  // Video
  if (props.media_info.media_type == 'video') {
    return (
      <>
        <video
          width="320"
          height="240"
          controls
          onLoadedData={() => {
            props.onLoad();
          }}
        >
          <source
            src={
              s3PresignedUrl
                ? s3PresignedUrl
                : `${API_BASE_URL}/video/${props.media_info.media_id}.${props.media_info.extension}`
            }
            type={`${props.media_info.mime_type}`}
          />
        </video>
        {renderCaption(props)}
      </>
    );
  }
};

function useOutsideAlerter(ref, setShowEmojiHover, setEmojiHoverStyle) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowEmojiHover(false);
        setEmojiHoverStyle({ display: 'none' });
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

const MsgRow = props => {
  const { t, previewMode } = props;
  const [showEmojiOption, setShowEmojiOption] = useState(false);
  const [showEmojiHover, setShowEmojiHover] = useState(false);
  const [emojiHoverStyle, setEmojiHoverStyle] = useState({ display: 'none' });
  const [hoverStyle, setHoverStyle] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const { sendReaction, customerID, markMessage } = useConversation();

  const pickerRef = useRef(null);
  const pickerContainerRef = useRef(null);
  useOutsideAlerter(pickerRef, setShowEmojiHover, setEmojiHoverStyle);

  function checkDivPosition(div) {
    const rect = div.getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();

    const isOutside = rect.top + 450 > bodyRect.bottom - 60;

    return isOutside;
  }

  const getEmojiPickerPosition = () => {
    if (!pickerContainerRef.current) {
      return {};
    }

    const isOutside = checkDivPosition(pickerContainerRef.current);

    if (isOutside) {
      return { bottom: 0 };
    }

    return {};
  };

  useEffect(() => {
    if (showEmojiHover) setEmojiHoverStyle(getEmojiPickerPosition);
  }, [showEmojiHover]);

  let time = dayjs(props.time).format('YYYY-MM-DD HH:mm:ss');

  return (
    <div
      className={`conversation-message-row d-flex${props.type === 'send' ? ' flex-row-reverse' : ''}`}
      // onMouseDown = {() => {console.log('holding...')}}
      // onMouseUp = {() => {console.log('end.')}}
      id={`msg-${props.id}`}
      onMouseEnter={() => setShowEmojiOption(true)}
      onMouseLeave={() => setShowEmojiOption(false)}
    >
      <div
        className={`conversation-message-bubble ${
          props.type === 'send' ? 'to-msg align-self-end' : 'from-msg align-self-start'
        }`}
        style={{ ...props.conversationMessageBubbleCss }}
      >
        <OverlayTrigger
          placement={props.type === 'send' ? 'left' : 'right'}
          overlay={
            <Tooltip id={`tooltip-${props.time}`}>
              <strong>{`${time}`}</strong>.
            </Tooltip>
          }
        >
          <div>
            <div className="msg-body">
              <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                {props?.agentName && <div style={{fontWeight: 'bold', color: 'var(--app-theme)'}}>{props.agentName}</div>}
                <div>{renderMessage(props)}</div>
                {!previewMode && (
                  <>
                    <div className="dropdown">
                      <button
                        className={`btn btn-default dropdown-toggle p-0 ${showDropdown ? ' show' : ''}`}
                        type="button"
                        aria-expanded="false"
                        style={{
                          border: 'none',
                          borderBottomLeftRadius: '50%',
                          boxShadow: `-3px 3px 2px 2px ${props.type === 'send' ? '#D9FDD3' : '#ffffff'}`,
                          backgroundColor: props.type === 'send' ? '#D9FDD3' : '#ffffff',
                          width: '24px',
                          height: '24px',
                        }}
                        onClick={() => {
                          props.setForwardMessageId(showDropdown ? null : props.id);
                          document.querySelectorAll('.message-dropdown').forEach(function (item) {
                            item.classList.remove('show');
                          });
                          setShowDropdown(!showDropdown);
                        }}
                      >
                        <b className="caret m-auto"></b>
                      </button>
                      <ul
                        className={`dropdown-menu${showDropdown ? ' show' : ''} ${
                          props.type === 'send' ? 'forward_dropdown_left' : 'forward_dropdown_right'
                        } message-dropdown`}
                      >
                        <li key={`forward-li-${props.id}`} id={`forward-li-${props.id}`}>
                          <a
                            className="dropdown-item"
                            href="#1"
                            onClick={() => {
                              props.setShowForwardModal(true);
                              setShowDropdown(false);
                            }}
                          >
                            {t('forward_message')}
                          </a>
                        </li>
                        <li key={`marked-li-${props.id}`} id={`marked-li-${props.id}`}>
                          <a
                            className="dropdown-item"
                            href="#1"
                            onClick={() => {
                              markMessage(props.id);
                              setShowDropdown(false);
                            }}
                          >
                            {t('star_message')}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'self-end' }}>
                {
                  // marked star
                  props.marked && (
                    <div className="msg-marked">
                      <AiFillStar size={15} color={'#8696A0'} />
                    </div>
                  )
                }
                <div className="msg-time fw-light">
                  {dayjs().to(dayjs(props.time))}
                  {props.type === 'send' && (
                    <svg
                      viewBox="0 0 16 11"
                      style={{ marginLeft: '2px' }}
                      height="11"
                      width="16"
                      preserveAspectRatio="xMidYMid meet"
                      fill="none"
                    >
                      <path
                        d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z"
                        fill={props.read ? '#53bdeb' : '#8696a0'}
                      ></path>
                    </svg>
                  )}
                </div>
              </div>
            </div>

            {props.reaction_emoji_client?.emoji || props.reaction_emoji_business?.emoji ? (
              <div className="msg-reaction">
                {/* Reaction from client */}
                {props.reaction_emoji_business?.emoji ? (
                  <div className="msg-reaction-from">{props.reaction_emoji_business.emoji}</div>
                ) : (
                  <></>
                )}

                {/* Reaction from business */}
                {props.reaction_emoji_client?.emoji != null ? (
                  <div className="msg-reaction-to">{props.reaction_emoji_client.emoji}</div>
                ) : (
                  <></>
                )}

                {/* {Reaction number} */}
                {props.reaction_emoji_business?.emoji != null && props.reaction_emoji_client?.emoji != null ? (
                  <div className="msg-reaction-text">2</div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </OverlayTrigger>
      </div>
      {/* Emoji picker trigger */}
      {(!previewMode && showEmojiOption) || showEmojiHover ? (
        <div className="d-flex flex-column justify-content-center align-items-center" id={`${props.id}-emoji-btn`}>
          <div
            className="btn-emoji"
            style={{ marginLeft: 5, marginRight: 5 }}
            onClick={() => setShowEmojiHover(!showEmojiHover)}
          >
            <HiEmojiHappy color="white" />
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Emoji picker */}
      {showEmojiHover ? (
        <div style={{ position: 'relative' }} ref={pickerContainerRef}>
          <div
            className={props.type == 'send' ? `emoji-hover-send` : `emoji-hover-receive`}
            style={{ zIndex: 1, ...emojiHoverStyle }}
            ref={pickerRef}
          >
            <EmojiPicker
              width={'100%'}
              searchPlaceholder={t('search_emoji_here')}
              lazyLoadEmojis={true}
              emojiStyle="apple"
              autoFocusSearch={false}
              previewConfig={{ showPreview: false }}
              onEmojiClick={e => {
                // console.log(customerID, props.id, e.emoji)
                sendReaction(customerID, props.message_id, e.emoji);
              }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default withTranslation()(MsgRow);
