import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from 'react-bootstrap/Image'
import Container from "react-bootstrap/Container";
import { logout, changeLanguage } from "../store/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import { POST , PATCH } from "../api/api-manager";
import { selectAuth } from "../store/reducers/auth";
import { withTranslation } from 'react-i18next'
import i18n from 'i18next';
import { Displayport } from "react-bootstrap-icons";
import {
  ImEarth
} from "react-icons/im";

const NavBar = (props) => {
  const {t} = props;
  const dispatch = useDispatch();
  const { userName } = useSelector(selectAuth);
  const reactAppFolder = process.env.REACT_APP_FOLDER_NAME;

  const leftRoutes = [
    // {
    //   name: "Home",
    //   link: "/dashboard",
    // },
    {
      name: t("chats"),
      link: "/chats",
    },
    {
      name: t("customers"),
      link: "/customers",
    },
    {
      name: t("agent"),
      link: "/users",
    },
    {
      name: t("agent_groups"),
      link: "/agent_groups",
    },
    // {
    //   name: "WA Business",
    //   link: "/wa_business",
    //   subMenu: [
    //     {
    //       name: "Phone Numbers",
    //       link: "/phone_numbers",
    //     },
    //     {
    //       name: "Business Profiles",
    //       link: "/business_profiles",
    //     },
    //   ],
    // },
    {
      name: t("settings"),
      link: "/settings",
      subMenu: [
        {
          name: t("roles"),
          link: "/roles_settings",
        },
        // {
        //   name: "Permissions",
        //   link: "/permissions_settings",
        // },
        // {
        //   name: "Auto Reply Messages",
        //   link: "/auto_reply_messages",
        // },
      ],
    },
    // {
    //   name: "Reports",
    //   link: "/reports",
    //   subMenu: [
    //     {
    //       name: "Users",
    //       link: "/users_reports",
    //     },
    //     {
    //       name: "Customers",
    //       link: "/customers_reports",
    //     },
    //     {
    //       name: "Chats",
    //       link: "/chats_reports",
    //     },
    //   ],
    // },
  ];

  const rightRoutes = [
    {
      name: userName,
      // link: "/username",
      right: true,
    },
    {
      name: t("update_profile"),
      link: "/update_profile",
      right: true,
    },
    {
      name: t("subscription"),
      link: "/subscription",
      right: true,
    },
    // {
    //   name: "Web Push Test",
    //   link: "/web_push_test",
    //   right: true,
    // },
    {
      name: t("language"),
      icon: <ImEarth />,
      subMenu: [
        {
          name: "English",
          right: true,
          onClick: () => {
            dispatch(PATCH(changeLanguage, "users/set_default_language", { language_key: "en_US" }));
            i18n.changeLanguage("en_US");
          },
        },
        {
          name: "简体中文",
          right: true,
          onClick: () => {
            dispatch(PATCH(changeLanguage, "users/set_default_language", { language_key: "zh_CN" }));
            i18n.changeLanguage("zh_CN");
          },
        },
        {
          name: "繁體中文",
          right: true,
          onClick: () => {
            dispatch(PATCH(changeLanguage, "users/set_default_language", { language_key: "zh_HK" }));
            i18n.changeLanguage("zh_HK");
          },
        },
      ],
    },
    {
      name: t("logout"),
      link: "/logout",
      right: true,
      onClick: () => {
        dispatch(POST(logout, "users/logout", {}));
      },
    },
  ];

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        {/* <Navbar.Brand href={reactAppFolder + "/dashboard"}>
          
        </Navbar.Brand> */}
        <a href={reactAppFolder + "/chats"}>
          <Image src="logo2.png" fluid style={{ marginRight: '10px', height: '40px' }}/>
        </a>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {leftRoutes.map((item, key) => {
              if (item.subMenu) {
                return (
                  <NavDropdown
                    title={item.name}
                    key={"mainMenu_" + key}
                    id="collasible-nav-dropdown"
                  >
                    {item.subMenu.map((mapSubMenu, subMenuKey) => {
                      return (
                        <NavDropdown.Item
                          href={reactAppFolder+mapSubMenu.link}
                          key={item.name + "_" + subMenuKey}
                        >
                          {mapSubMenu.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                );
              } else if (item.onClick) {
                return (
                  <Nav.Link onClick={item.onClick} key={key}>
                    {item.name}
                  </Nav.Link>
                );
              } else {
                return (
                  <Nav.Link href={reactAppFolder+item.link} key={key}>
                    {item.name}
                  </Nav.Link>
                );
              }
            })}
          </Nav>
          <Nav>
            {rightRoutes.map((item, key) => {
              if (item.subMenu) {
                return (
                  <NavDropdown
                    title={item.icon ? item.icon : item.name}
                    key={"mainMenu_" + key}
                    id="collasible-nav-dropdown"
                  >
                    {item.subMenu.map((mapSubMenu, subMenuKey) => {
                      if (mapSubMenu.link) {
                        return (
                          <NavDropdown.Item
                            href={reactAppFolder+mapSubMenu.link}
                            key={item.name + "_" + subMenuKey}
                            onClick={mapSubMenu.onClick}
                          >
                            {mapSubMenu.name}
                          </NavDropdown.Item>
                        )
                      }
                      else {
                        return (
                          <NavDropdown.Item
                            key={item.name + "_" + subMenuKey}
                            onClick={mapSubMenu.onClick}
                          >
                            {mapSubMenu.name}
                          </NavDropdown.Item>
                        ) 
                      }
                    })}
                  </NavDropdown>
                );
              } else if (item.onClick) {
                return (
                  <Nav.Link onClick={item.onClick} key={key}>
                    {item.name}
                  </Nav.Link>
                );
              } else if (item.link) {
                return (
                  <Nav.Link href={reactAppFolder+item.link} key={key}>
                    {item.name}
                  </Nav.Link>
                );
              } else {
                return (
                  <Nav.Link key={key}>
                    {item.name}
                  </Nav.Link>
                );
              }
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default withTranslation()(NavBar);
