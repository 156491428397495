import auth from "./sagas/auth";
import conversations from "./sagas/conversations";
import user from "./sagas/user";
import messages from "./sagas/messages";
import business from './sagas/business'
import agentGroups from './sagas/agentGroups'
import customers from './sagas/customers'

import { fork } from "@redux-saga/core/effects";
export default function* rootSaga() {
    yield fork(auth);
    yield fork(conversations);
    yield fork(user);
    yield fork(messages);
    yield fork(business);
    yield fork(agentGroups);
    yield fork(customers);
}