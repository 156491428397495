import { selectAuth } from "../store/reducers/auth";
import { selectUser } from "../store/reducers/user";
import { selectbusiness } from "../store/reducers/business";
import { useSelector, useDispatch} from "react-redux";
import ConversationRow from "../_component/ConversationRow";
import {
    BsThreeDotsVertical,
    BsFilter,
  } from "react-icons/bs";
import { useEffect, useState, useRef, useMemo } from "react";
import { selectConversation } from "../store/reducers/conversations";
import { useConversation } from "./context/ConversationContext";
import CustomModal from './CustomModal';
import CustomModal1 from './CustomModal1';
import { Form, Col, Row } from "react-bootstrap";
import { PATCH , GET } from "../api/api-manager"
import { setAutoReply, getAutoReply } from '../store/reducers/user';
import { getBusinessPhoneList } from '../store/reducers/business'
import { withTranslation } from 'react-i18next'
import { getProfilePic } from "../store/reducers/user";

import ListGroup from 'react-bootstrap/ListGroup';

const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
      () => {
        const handler = setTimeout(() => {
            // Debug message
            // console.log('debounced value', value);
          setDebouncedValue(value);
        }, delay);

        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay]
    );

    return debouncedValue;
}

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

const renderStringWithComma = (strAry) => {
    if (strAry.length === 0) {
        return null;
    }

    const html = [];
    html.push(strAry);

    return strAry.join(', ');
}

const ConversationBar = (props) => {
    const { t } = props;
    const dispatch = useDispatch();
    const {
      profile_pic
    } = useSelector(selectUser);
    const conversation = useSelector( selectConversation );
    const previousConversation = usePrevious( conversation );
    // const [unreadConversations, setUnreadConversations] = useState({});
    const { agentList, agentGroupList, addCustomer, updateRoomList, updateAgentList, updateAgentGroupList, updateCustomerAgent, markMessageAsRead } = useConversation();
    const { userDisplayName, userRole } = useSelector( selectAuth );
    const { autoReply } = useSelector( selectUser );
    const { businessPhoneList } = useSelector( selectbusiness );

    const [searchConversation, setSearchConversation] = useState('');
    const {debouncedValue:debouncedSearchConversation} = useDebounce(searchConversation, 350);

    // new customer
    const [showModal, setShowModal] = useState(false);
    const newPhoneRef = useRef();
    const newNameRef = useRef();;
    const newBusinessPhone = useRef();

    // agent assign
    const [showAgentModal, setShowAgentModal] = useState(false);
    // const [assignedAgent, setAssignedAgent] = useState();
    // const [assignedAgentGroups, setAssignedAgentGroups] = useState();
    const [selectedAgents, setSelectedAgents] = useState({});   // Indexed by agent ID
    const [selectedAgentGroups, setSelectedAgentGroups] = useState({});   // Indexed by agent ID
    const [selectedCustomer, setSelectedCustomer] = useState();

    // toggle auto reply
    const [dropdown, setDropdown] = useState(false);
    const [toggleButtonText, setToggleButtonText] = useState("");

    const [agentProfilePic, setAgentProfilePic] = useState(null);

    useEffect(() => {
        dispatch(GET(getProfilePic, "users/profile_pic", {}));
    }, []);
  
    useEffect(() => {
        setAgentProfilePic(profile_pic);
    }, [profile_pic])
      
    const toggleMenu = () =>
    {
        setDropdown(!dropdown);
    }

    const handleToggleAutoReply = () => {
        let body = { enable_auto_reply : !autoReply };
        dispatch( PATCH(setAutoReply,"users/toggle_auto_reply",body) );
    }

    const agentModal = useMemo(() => {
        return (
            <CustomModal1
                size='lg'
                showModal = {showAgentModal}
                setShowModal = {setShowAgentModal}
                modal_title={ t("agent") }
                onSubmit={() => {updateAgent()}}
            >
                <Row>
                    {/* Agent */}
                    <Col>
                        <h5 className='mb-4'>
                            {t("assigned_agent")}{renderStringWithComma(Object.keys(selectedAgents).length > 0 ? Object.values(selectedAgents).map((value) => value.name): [])}
                        </h5>

                        <ListGroup className='mb-4'>
                            {
                            agentList && agentList.map((agent, index) => {
                                return (
                                <ListGroup.Item
                                    key = {agent._id}
                                    action
                                    active={selectedAgents?.[agent._id] !== undefined}
                                    href={`#agent${index}`} 
                                    onClick={() => {
                                        const newSelectedAgents = {...selectedAgents};

                                        if (newSelectedAgents?.[agent._id] === undefined) {
                                            // Add agent
                                            newSelectedAgents[agent._id] = agent;
                                        } else {
                                            // Remove agent
                                            delete newSelectedAgents[agent._id];
                                        }

                                        setSelectedAgents(newSelectedAgents)
                                    }
                                }>
                                    {agent.name}
                                </ListGroup.Item>
                                )
                            })
                            }
                        </ListGroup>
                    </Col>

                    <Col>
                        {/* Agent group */}
                        <h5 className='mb-4'>
                            { t("assigned_agent_group") }{renderStringWithComma(Object.keys(selectedAgentGroups).length > 0 ? Object.values(selectedAgentGroups).map((value) => value.name): [])}
                        </h5>

                        <ListGroup>
                            {
                            agentGroupList && agentGroupList.map((agentGroup, index) => {
                                return (
                                <ListGroup.Item
                                    key = {agentGroup._id}
                                    action
                                    active={selectedAgentGroups?.[agentGroup._id] !== undefined}
                                    href={`#agent${index}`} 
                                    onClick={() => {
                                        const newSelectedAgentGroups = {...selectedAgentGroups};

                                        if (newSelectedAgentGroups?.[agentGroup._id] === undefined) {
                                            // Add agent
                                            newSelectedAgentGroups[agentGroup._id] = agentGroup;
                                        } else {
                                            // Remove agent
                                            delete newSelectedAgentGroups[agentGroup._id];
                                        }

                                        setSelectedAgentGroups(newSelectedAgentGroups)
                                    }
                                }>
                                    {agentGroup.name}
                                </ListGroup.Item>
                                )
                            })
                            }
                        </ListGroup>
                    </Col>
                </Row>
            </CustomModal1>)
    })

    // const markMessageAsRead = (id) => {
    //     console.log('on mark message as read', id);
    //     const newUnreadConversations = {...unreadConversations};
    //     delete newUnreadConversations[id];

    //     console.log({newUnreadConversations})
    //     setUnreadConversations(newUnreadConversations);
    // }

    useEffect(() => {
        console.log(`=============== initing ConversationBar ===============`);

        dispatch( GET(getAutoReply,"users/get_auto_reply_on_off",{}) );    
        dispatch( GET(getBusinessPhoneList,"business_profiles", {}) );
        //eslint-disable-next-line
    },[])

    useEffect(() => {  
        setToggleButtonText( autoReply ? "Disable auto reply" : "Enable auto reply");
    }, [autoReply])

    useEffect(() => {
        updateAgentList();
        updateAgentGroupList();
    }, [showAgentModal])

    // Listen changes of conversation list
    useEffect(() => {
        console.log('On change conversation');

        // console.log({
        //     previousConversation,
        //     conversation
        // })

        if (!previousConversation || !Array.isArray(previousConversation?.roomList) || Object.keys(previousConversation.roomList).length === 0) {
            console.log('Previous conversation is not initialized', previousConversation);
            return;
        }

        // const newUnreadConversations = {...unreadConversations};
        const newRoomLastMessageTime = {};  // Indexed by _id;
        const previousRoomLastMessageTime = {};  // Indexed by _id;

        // Index new rooms
        if (Array.isArray(conversation?.roomList)) {
            for (const key in conversation.roomList) {
                const room = conversation.roomList[key];

                newRoomLastMessageTime[room._id] = new Date(room.last_msg_time);
            }
        }

        // Index previous rooms
        if (Array.isArray(previousConversation?.roomList)) {
            for (const key in previousConversation.roomList) {
                const room = previousConversation.roomList[key];
                
                previousRoomLastMessageTime[room._id] = new Date(room.last_msg_time);
            }
        }

        console.log({newRoomLastMessageTime, previousRoomLastMessageTime, previousConversation});

        // Compare and update unread conversations
        // for (const id of Object.keys(newRoomLastMessageTime)) {
        //     if (!previousRoomLastMessageTime?.[id]) {
        //         // Previously not having this conversation
        //         console.log('Previously not having this conversation')
        //         newUnreadConversations[id] = true;
        //         continue;
        //     }

        //     if (newRoomLastMessageTime[id].getTime() !== previousRoomLastMessageTime[id].getTime()) {
        //         // Message time is different comparing to the previous message time(of the same conversation)
        //         console.log('Message time is different comparing to previous message time(of the same conversation)')
        //         newUnreadConversations[id] = true;
        //         continue;
        //     }
        // }

        // Update unread conversations
        // console.log({newUnreadConversations});
        // setUnreadConversations(newUnreadConversations);
    }, [conversation]);

    // Listen changes of select agent
    useEffect(() => {
      console.log('On change selected agent', selectedAgents);
    }, [selectedAgents])
    

    const onModalSubmit = () => {
        if(newPhoneRef.current.value == '' || newNameRef.current.value == ''){
            alert('please fill in the required info')
            throw new Error('not filled')
        }else{
            addCustomer(newPhoneRef.current.value, newNameRef.current.value, newBusinessPhone.current.value)
        }
    }

    const renderModalContent = () => {
        return (
            <>
            <Form.Group as={Row} controlId="customerName" className="mb-3">
                <Form.Label column sm={2}>{ t("customer_name") }</Form.Label>
                <Col>
                    <Form.Control 
                        type = "input"
                        ref = {newNameRef}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="phoneNumber" className="mb-3">
                <Form.Label column sm={2}>{ t("phone_number") }</Form.Label>
                <Col>
                    <Form.Control 
                        type = "input"
                        onKeyDown = {(e) => {
                            if ((!/[0-9]/.test(e.key)) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowRight" && e.key !== "ArrowLeft") {
                                e.preventDefault();
                              }
                        }}
                        ref = {newPhoneRef}
                    />
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="businessPhoneNumbeer" className="mb-3">
                <Form.Label column sm={2}>{ t("business_phone") }</Form.Label>
                <Col>
                        <Form.Select
                            ref={newBusinessPhone} 
                        >
                            {
                                businessPhoneList.map((bphone, index) => {
                                    return (
                                        <option value={bphone} key={index}>{bphone}</option>
                                    )
                                })
                            }
                        </Form.Select>
                </Col>
            </Form.Group>
            </>
        )
    }

    const updateAgentModalContent = (customer_id, customer_agents, customer_agent_groups) => {
        console.log(`UPDATING agent modal`, customer_id, customer_agents, customer_agent_groups);

        const newSelectedAgents = {};
        const newSelectedAgentGroups = {};

        // agentList && agentList.forEach(agent => {
        //     if(agent_id == null){
        //         setAssignedAgent(null)
        //     }

        //     if(agent._id == agent_id){
        //         setAssignedAgent(agent)
        //         return
        //     }
        // });
        // setSelectedCustomer(customer_id)
        // updateRoomList()
        // console.log('current agent', assignedAgent);

        if (Array.isArray(agentList) && Array.isArray(customer_agents) && customer_agents.length > 0) {
            for (const agent of agentList) {
                if (!customer_agents.includes(agent._id)) {
                    // Database agent groups does not include this agent group
                    continue;
                }

                newSelectedAgents[agent._id] = agent;
            }
        }

        if (Array.isArray(agentGroupList) && Array.isArray(customer_agent_groups) && customer_agent_groups.length > 0) {
            for (const agentGroup of agentGroupList) {
                if (!customer_agent_groups.includes(agentGroup._id)) {
                    // Database agent groups does not include this agent group
                    continue;
                }

                newSelectedAgentGroups[agentGroup._id] = agentGroup;
            }
        }
        
        setSelectedAgents(newSelectedAgents);
        setSelectedAgentGroups(newSelectedAgentGroups);
        setSelectedCustomer(customer_id)
        updateRoomList();
      }
    
      const updateAgent = () => {
        console.log(`updating ${selectedCustomer} agent & agent group`);
        console.log({ selectedAgents, selectedAgentGroups })

        if(selectedCustomer && (Object.keys(selectedAgents).length > 0 || Object.keys(selectedAgentGroups).length > 0)){
            updateCustomerAgent(selectedCustomer, Object.keys(selectedAgents), Object.keys(selectedAgentGroups));
        }
      }
    return (<>
        <div className="col-md-3 p-0" id="chat-list-div" style={{ backgroundColor: '#ffffff' }}>
            <div className="row d-flex flex-column flex-nowrap vh-100">

                {/* Top BAR start */}
                <div id="top-bar-div" className="d-flex flex-nowrap">
                    <div className="col-2 d-flex justify-content-center align-items-center">
                    <img
                        className="profile-pic"
                        src={agentProfilePic ? agentProfilePic : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"}
                        alt= "profile_pic"
                    />
                    </div>
                    <div className="col-9 px-2 d-flex flex-column justify-content-center">
                    <div className="d-flex justify-content-between">
                        <h5>{userDisplayName}</h5>
                        <h5 className="fw-normal">{userRole}</h5>
                    </div>
                    <div>
                        <span></span>
                    </div>
                    </div>
                    <div className="col-1 d-flex flex-column justify-content-center align-items-center">
                        <div className="btn btn-default" style={{ border: 'none', backgroundColor: 'transparent' }}>
                            <BsThreeDotsVertical 
                                onClick={() => toggleMenu()}
                            />
                            <ul className={`dropdown-menu${dropdown ? ' show' : ''}`} style={{transform: 'translate3d(-140px, 5px, 0px)'}}>
                                <li key={`li-auto-reply`} id={`li-auto-reply`}>
                                    <a 
                                        className="dropdown-item" 
                                        href="#1" 
                                        onClick={() => {
                                            handleToggleAutoReply()
                                        }}
                                    >
                                        {toggleButtonText}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Top BAR end */}

                {/* Search Bar */}
                <div
                    id="search-bar-div"
                    className="d-flex justify-content-center align-items-center"
                >
                    <div className="flex-fill">
                    <div className="input-group position-relative d-inline-flex align-items-center">
                        <input
                            className="form-control"
                            type="text"
                            name="search-conversation"
                            value={searchConversation}
                            onChange={(e) => setSearchConversation(e.target.value)}
                        />
                        {/* <i className="bi bi-x-lg position-absolute" 
                        style="
                        right: 10px;
                        cursor: pointer;
                        z-index: 100;">
                        </i> */}
                        <svg
                            style={{position: 'absolute', right: '10px', cursor: 'pointer', zIndex: 100}}
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={(e) => setSearchConversation('')}
                        >
                            <path fill="none" stroke="#000" strokeWidth="2" d="M3,3 L21,21 M3,21 L21,3"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="">
                    <button className="btn btn-default" style={{ border: 'none', backgroundColor: 'transparent' }}>
                        <BsFilter />
                    </button>
                    </div>
                </div>
                {/* Search Bar end */}

                <div id="list-div" className="flex-fill overflow-scroll">
                    <div id="list-container" className="d-flex flex-column" style={{paddingBottom: 100}}>
                        {/* Add new Contact */}
                        <div className="conversation-row d-flex" onClick={() => {setShowModal(true)}} >
                            <div style={{margin: 'auto'}}><h5>{ t("create_new_customer") }</h5></div>
                        </div>

                        {/* {console.log(conversation)} */}
                        {(conversation.roomList) && (conversation.roomList)
                            .filter((value) => debouncedSearchConversation === '' || value.profile_name.toLowerCase().includes(debouncedSearchConversation.toLowerCase()))
                            .map(
                            (value, index) => {
                                return (
                                    <ConversationRow
                                        key ={index}
                                        profileIcon="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"
                                        name = {value.profile_name}
                                        lastMsg = {value.last_msg}
                                        lastMsgTime = {value.last_msg_time}
                                        // unread={unreadConversations[value._id] ? true : false}
                                        unread={!value.last_msg_read}
                                        messageSide = {value.messageSide}
                                        customer_id = {value._id}
                                        phone = {value.phone_number}
                                        setShowAgentModal = {setShowAgentModal}
                                        updateAgentModalContent = {updateAgentModalContent}
                                        // agent_id = {value.agent_id}
                                        agents = {value.agents}
                                        agentGroups = {value.agent_groups}
                                    />
                                )
                            }
                        )}
                    
                    <span className="fw-light text-center">{ t("end_of_list") }</span>
                    </div>
                </div>
            </div>

            {/* Add new customer */}
            <CustomModal1 
                showModal = {showModal}
                setShowModal = {setShowModal}
                modal_title={ t("enter_new_customer_phone") } 
                onSubmit={onModalSubmit}
            >
                {renderModalContent()}
            </CustomModal1>

            {/* Modal for agent assign */}
            {agentModal}
          </div>
    </>
    );
}

export default withTranslation()(ConversationBar);