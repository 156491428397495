// roomList => List of Room for customer in ConversationBar.js
// messageList => List of message for Customer Room  in ConversationWindow.js

import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { Store } from 'react-notifications-component';

export const messageSlice = createSlice({
  name: "messages",
  initialState: {
    autoReplyMessageList: [],
    autoReplyPaginationParams: {},
    loading: false,
  },
  reducers: {
    getDefaultMessageList(state) {
      state.loading = true;
    },
    getDefaultMessageListSuccess(state, payload) {
      state.loading = false;
      let responseData = payload.resData.data;
      state.autoReplyMessageList = responseData.data.auto_reply_message;
      state.autoReplyPaginationParams = responseData.data.paginationParams;
    },
    getDefaultMessageListFail(state) {
      state.loading = false;
    },
    setDefaultMessage(state) {
      state.loading = true;
    },
    setDefaultMessageSuccess(state, payload) {
      state.loading = false;
      let responseData = payload.resData.data;
      // toast(responseData.message)
      Store.addNotification({
        message: responseData.message,
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
      state.autoReplyMessageList = responseData.data.auto_reply_message;
      state.autoReplyPaginationParams = responseData.data.paginationParams;
    },
    setDefaultMessageFail(state) {
      state.loading = false;
    },
    addAutoReplyMessage(state) {
      state.loading = true;
    },
    addAutoReplyMessageSuccess(state, payload) {
      state.loading = false;
      let responseData = payload.resData.data;
      // toast(responseData.message)
      Store.addNotification({
        message: responseData.message,
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
      state.autoReplyMessageList = responseData.data.auto_reply_message;
      state.autoReplyPaginationParams = responseData.data.paginationParams;
    },
    addAutoReplyMessageFail(state) {
      state.loading = false;
    },
    updateAutoReplyMessage(state) {
      state.loading = true;
    },
    updateAutoReplyMessageSuccess(state, payload) {
      state.loading = false;
      let responseData = payload.resData.data;
      // toast(responseData.message)
      Store.addNotification({
        message: responseData.message,
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
      state.autoReplyMessageList = responseData.data.auto_reply_message;
      state.autoReplyPaginationParams = responseData.data.paginationParams;
    },
    updateAutoReplyMessageFail(state) {
      state.loading = false;
    },
    deleteAutoReplyMessage(state) {
      state.loading = true;
    },
    deleteAutoReplyMessageSuccess(state, payload) {
      state.loading = false;
      let responseData = payload.resData.data;
      // toast(responseData.message)
      Store.addNotification({
        message: responseData.message,
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
      state.autoReplyMessageList = responseData.data.auto_reply_message;
      state.autoReplyPaginationParams = responseData.data.paginationParams;
    },
    deleteAutoReplyMessageFail(state) {
      state.loading = false;
    },
  },
});

export const selectMessage = (state) => state.messages;
export const {
  getDefaultMessageList,
  getDefaultMessageListSuccess,
  getDefaultMessageListFail,
  setDefaultMessage,
  setDefaultMessageSuccess,
  setDefaultMessageFail,
  addAutoReplyMessage,
  addAutoReplyMessageSuccess,
  addAutoReplyMessageFail,
  updateAutoReplyMessage,
  updateAutoReplyMessageSuccess,
  updateAutoReplyMessageFail,
  deleteAutoReplyMessage,
  deleteAutoReplyMessageSuccess,
  deleteAutoReplyMessageFail,
} = messageSlice.actions;

export default messageSlice.reducer;
