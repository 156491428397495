import React, { useEffect, useState, useContext } from 'react';
import { Button, Col, Row, Table, Container } from 'react-bootstrap';
import { getRoleList, apiGenerator } from '../api/api-manager';
import { Link, useNavigate } from 'react-router-dom';
import PageTitle from '../_component/PageTitle';
import CustomPagination from '../_component/Pagination';
import CustomPagination1 from '../_component/Pagination1';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';

import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import CustomModal1 from '../_component/CustomModal1';

import { usePermissions } from '../_component/context/AuthContext';
import { useDebounce } from '../_component/hooks/useDebounce';

const Roles = props => {
  const { t } = props;
  // const dispatch = useDispatch();
  const appSettings = useContext(AppSettings);

  // Search fields
  const [searchKeyword, setSearchKeyword] = useState('');
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 350);

  const { permissions } = usePermissions();
  const [roleList, setRoleList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteRole, setSelectedDeleteRole] = useState(undefined);

  const [page, setPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState([]);
  const limit = 2;

  const navigate = useNavigate();

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    // };
  }, [appSettings]);

  useEffect(() => {
    updateRoleList();
  }, [debouncedSearchKeyword, page]);

  useEffect(() => {
    console.log(`roleList`, roleList);
  }, [roleList]);

  const onDeleteModalSubmit = () => {
    apiGenerator({
      method: 'DELETE',
      path: `roles/${selectedDeleteRole._id}`,
      body: {},
    }).then(res => {
      // alert(res.data.message);
      Store.addNotification({
        message: res.data.message,
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: { duration: 5000 },
      });

      if (res.data.status) {
        updateRoleList();
      }
    });
  };

  const editRole = role => {
    console.log(`editing role...`, role);
    navigate('/roles/add', { state: { role: role } });
  };

  const handleDeleteRole = role => {
    setSelectedDeleteRole(role);
    setShowDeleteModal(true);
  };

  const updateRoleList = () => {
    getRoleList({
      keyword: debouncedSearchKeyword,
      page: page,
      limit: limit
    }).then(res => {
      let response = res.data;
      console.log({ response });
      if (response.status) {
        let newList = [];

        for (const role of response.params.docs) {
          newList.push(role);
        }

        setRoleList(newList);
        setPaginationParams({
          totalDocs: response.params.totalDocs,
          limit: response.params.limit,
          page: response.params.page,
          totalPages: response.params.totalPages,
          hasPrevPage: response.params.hasPrevPage,
          hasNextPage: response.params.hasNextPage,
          prevPage: response.params.prevPage,
          nextPage: response.params.nextPage,
        });
      }
    });
  };

  return (
    <>
      <Container>
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={'/'}>Home</Link>
              </li>
              <li className="breadcrumb-item active">{t('role_management')}</li>
            </ul>
            <h1 className="page-header mb-0">{t('role_management')}</h1>
          </div>
          <div className="ms-auto">
            {permissions?.add_role && (
              <Link to={'/roles/add'}>
                <Button variant="primary" className="px-4 btn-rounded rounded-pill">
                  <i className="fa fa-plus fa-lg me-2 ms-n2 text-white"></i> {t('add_role')}
                </Button>
              </Link>
            )}
          </div>
        </div>

        <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              {/* Search bar */}
              <div div className="input-group mb-3">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                  style={{ zIndex: 10 }}
                >
                  <i className="fa fa-search opacity-5"></i>
                </div>
                <input
                  type="text"
                  className="form-control px-35px bg-light"
                  style={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
                  placeholder={t('search_name')}
                  value={searchKeyword}
                  onChange={e => {
                    setSearchKeyword(e.target.value);
                  }}
                />
              </div>

              {/* Table */}
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: '1%', whiteSpace: 'nowrap' }}>#</th>
                      {(permissions?.edit_role || permissions?.delete_role) && (
                        <th style={{ width: '1%', whiteSpace: 'nowrap' }}>{t('action')}</th>
                      )}
                      <th>{t('name')}</th>
                      <th>{t('permission')}</th>
                      <th>{t('updated_at')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roleList &&
                      roleList.length > 0 &&
                      roleList.map((role, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ verticalAlign: 'super' }}>{index + 1}</td>
                            {(permissions?.edit_role || permissions?.delete_role) && (
                              <td nowrap="true" className="with-btn" style={{ verticalAlign: 'super' }}>
                                {permissions?.edit_role && (
                                  <button className="btn btn-sm btn-primary w-60px me-1" onClick={() => editRole(role)}>
                                    {t('edit')}
                                  </button>
                                )}
                                {permissions?.delete_role && (
                                  <button className="btn btn-sm btn-danger w-60px" onClick={() => handleDeleteRole(role)}>
                                    {t('delete')}
                                  </button>
                                )}
                              </td>
                            )}
                            <td style={{ verticalAlign: 'super' }}>{role.name}</td>
                            <td style={{ verticalAlign: 'super' }}>

                              {Object.keys(role.permission_info).length > 0 ?
                                Object.values(role.permission_info).map((value, index) => {
                                  return <>
                                    <b>{value.permissionGroup.name}{t('symbol_colon')}</b>
                                    <ul>
                                    {value.permissions.map((permission, index) => {
                                        return (
                                          <li>
                                            <div key={permission.key}>
                                              {permission.name}
                                            </div>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                    <br/>
                                  </>;
                              }) : '(None)'}
                              
                              {/* <ul>
                              {role.permission_info &&
                                Object.values(role.permission_info).map((value, index) => {
                                  return (
                                    <li>
                                      <div key={index}>
                                        {value.permissionGroup.name}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul> */}
                            </td>
                            <td style={{ verticalAlign: 'super' }}>
                              {dayjs(role.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="d-md-flex align-items-center">
                <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                  {t('pagination_info_1')} {(paginationParams.page - 1) * paginationParams.limit + 1}{' '}
                  {t('pagination_info_2')}{' '}
                  {paginationParams.page * paginationParams.limit < paginationParams.totalDocs
                    ? paginationParams.page * paginationParams.limit
                    : paginationParams.totalDocs}{' '}
                  {t('pagination_info_3')} {paginationParams.totalDocs} {t('pagination_info_4')}
                </div>

                <CustomPagination1 paginationParams={paginationParams} setPage={setPage} />
              </div>
            </div>
          </div>
        </div>

        <CustomModal1
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          modal_title={t('delete_role')}
          onSubmit={onDeleteModalSubmit}
          submitButtonText={t('confirm')}
        >
          <div className="alert alert-danger">
            <h5>
              <i className="fa fa-info-circle"></i> {t('alert')}
            </h5>
            <p>{t('delete_confirmation_text')}</p>
          </div>
        </CustomModal1>
      </Container>
    </>
  );
};

export default withTranslation()(Roles);
