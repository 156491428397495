import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { POST, GET, PATCH, DELETE } from '../api/api-manager';
import { changePassword, updateProfilePic, getProfilePic, removeProfilePic } from '../store/reducers/user';
import PageTitle from '../_component/PageTitle';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import Avatar from 'react-avatar-edit';
import { Link } from 'react-router-dom';
import { selectUser } from '../store/reducers/user';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';

const UpdateProfile1 = props => {
  const { t } = props;
  const dispatch = useDispatch();
  const { profile_pic, update_profile_pic } = useSelector(selectUser);

  const oldPwRef = useRef();
  const newPwRef = useRef();
  const confirmPwRef = useRef();

  const appSettings = useContext(AppSettings);

  const [profileSrc, setProfileSrc] = useState('');
  const [preview, setPreview] = useState(null);
  const [backUpCurrentPic, setBackUpCurrentPic] = useState(null);
  const [editingProfilePic, setEditingProfilePic] = useState(false);

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //     appSettings.handleSetAppSidebarNone(false);
    //     appSettings.handleSetAppHeaderNone(false);
    //     appSettings.handleSetAppContentClass('');
    // }
  }, [appSettings]);

  useEffect(() => {
    setProfileSrc('');
    setEditingProfilePic(false);
    dispatch(GET(getProfilePic, 'users/profile_pic', {}));
  }, [update_profile_pic]);

  useEffect(() => {
    setPreview(profile_pic);
    setBackUpCurrentPic(profile_pic);
    if (profile_pic == null) {
      setEditingProfilePic(true);
    }
  }, [profile_pic]);

  const onCrop = function (onCropPreview) {
    setPreview(onCropPreview);
    console.log('onCrop');
  };

  const onClose = function () {
    if (backUpCurrentPic != null) {
      setPreview(backUpCurrentPic);
    } else {
      setPreview(null);
    }
    console.log('onCrop');
  };

  const onBeforeFileLoad = function () {
    console.log('onBeforeFileLoad');
  };

  const updateIcon = function () {
    dispatch(PATCH(updateProfilePic, 'users/update_profile_pic', { profile_pic: preview }));
    console.log(preview);
    console.log('updateIcon');
  };

  const removeProfilePicFunction = () => {
    dispatch(DELETE(removeProfilePic, 'users/remove_profile_pic', {}));
  };

  const onFormSubmit = event => {
    // console.log(usernameRef.current.value)
    // console.log(roleRef.current.value)
    const formData = new FormData(event.currentTarget);
    // console.log(event.target.elements.username.value)

    event.preventDefault();

    var error = false;

    for (let [key, value] of formData.entries()) {
      // console.log(key, value);
      // check empty
      if (key == 'oldPw' || key == 'newPw' || key == 'confirmPw') {
        if (value == '' || value == null || value == undefined) {
          // toast('Please fill in all fields');
          Store.addNotification({
            message: 'Please fill in all fields',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 5000 },
          });
          return;
        }
      }
    }

    if (formData.get('newPw') != formData.get('confirmPw')) {
      // toast('Please confirm password');
      Store.addNotification({
        message: 'Please confirm password',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: { duration: 5000 },
      });
      return;
    }

    let request_user = {
      oldPw: formData.get('oldPw'),
      newPw: formData.get('newPw'),
      confirmPw: formData.get('confirmPw'),
    };

    dispatch(PATCH(changePassword, 'users/change_password', request_user));
  };

  return (
    <Container>
      {/* Breadcrumb */}
      <div className="d-flex align-items-center">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">{t('update_profile')}</li>
          </ol>
          <h1 className="page-header">{t('update_profile')}</h1>
        </div>
      </div>

      <div className="row gx-4">
        <div className="col-sm-4">
          <div className="card border-0 mb-4">
            <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">{t('profile_pic')}</div>

            <div className="card-body p-3 text-dark fw-bold">
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-center py-3">
                  {/* Current profile picture display */}
                  {backUpCurrentPic && !editingProfilePic ? (
                    <img width={300} height={300} src={backUpCurrentPic} alt="Preview" />
                  ) : (
                    <Avatar
                      exportAsSquare
                      imageWidth={300}
                      imageHeight={300}
                      onCrop={onCrop}
                      onClose={onClose}
                      onBeforeFileLoad={onBeforeFileLoad}
                      src={null}
                      label={t('select_a_file')}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="card-footer bg-none d-flex justify-content-end p-3">
              {!editingProfilePic && (
                <button type="button" className="btn btn-primary m-1" onClick={() => setEditingProfilePic(true)}>
                  {t('update_profile_pic')}
                </button>
              )}
              {editingProfilePic && backUpCurrentPic != null && (
                <button type="button" className="btn btn-secondary m-1" onClick={() => setEditingProfilePic(false)}>
                  {t('cancel')}
                </button>
              )}
              {!editingProfilePic && backUpCurrentPic && (
                <button type="button" className="btn btn-danger m-1" onClick={removeProfilePicFunction}>
                  {t('remove_profile_pic')}
                </button>
              )}
              {editingProfilePic && (
                <Button variant="primary" className="m-1" onClick={updateIcon}>
                  {t('upload')}
                </Button>
              )}
            </div>
          </div>
        </div>

        {/* Change password function */}
        <div className="col-sm-8">
          <Form onSubmit={onFormSubmit} id="userForm">
            <div className="card border-0 mb-4">
              <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">{t('change_password')}</div>

              <div className="card-body p-3 text-dark fw-bold">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <label className="form-label" for="oldPw">
                          {t('old_password')}
                        </label>
                        <input type="password" ref={oldPwRef} name="oldPw" className="form-control" />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <label className="form-label" for="newPw">
                          {t('new_password')}
                        </label>
                        <input type="password" ref={newPwRef} name="newPw" className="form-control" />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <label className="form-label" for="confirmPw">
                          {t('confirm_password')}
                        </label>
                        <input type="password" ref={confirmPwRef} name="confirmPw" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer bg-none d-flex p-3">
                <button type="submit" className="btn btn-primary m-1 ms-auto">
                  {t('submit')}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default withTranslation()(UpdateProfile1);
