import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { useConversation } from '../_component/context/ConversationContext';
import SelectionList from '../_component/SelectionList';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { getPermissionList, apiGenerator } from '../api/api-manager';
import { useTranslation, withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';
import { showGeneralDangerToast } from '../utils/toast';
import TextareaAutosize from 'react-textarea-autosize';
import MsgRow from '../_component/MsgRow';
import EmojiPicker from 'emoji-picker-react';

// * Constants
const CATEGORIES = [
  { label: 'Marketing', value: 'MARKETING' },
  { label: 'Utility', value: 'UTILITY' },
  // ! Not supported in our app yet.
  { label: 'Authentication', value: 'AUTHENTICATION' },
];
// ! To be deleted.
// const COMPONENT_TYPES = [
//   { label: 'Header', value: 'HEADER' },
//   { label: 'Body', value: 'BODY' },
//   { label: 'Footer', value: 'FOOTER' },
//   { label: 'Buttons', value: 'BUTTONS' },
// ];
// * For header.
const COMPONENT_HEADER_FORMATS = [
  { label: 'None', value: '' },
  { label: 'Text', value: 'TEXT' },
  { label: 'Image', value: 'IMAGE' },
  { label: 'Video', value: 'VIDEO' },
  { label: 'Document', value: 'DOCUMENT' },
  // ! Not supported in our app yet.
  // { label: 'Location', value: 'LOCATION' },
];
const MESSAGE_TEMPLATE_SUPPORTED_LANGUAGES = [
  // { language: 'Afrikaans', code: 'af' },
  // { language: 'Albanian', code: 'sq' },
  // { language: 'Arabic', code: 'ar' },
  // { language: 'Azerbaijani', code: 'az' },
  // { language: 'Bengali', code: 'bn' },
  // { language: 'Bulgarian', code: 'bg' },
  // { language: 'Catalan', code: 'ca' },
  // { language: 'Chinese (CHN)', code: 'zh_CN' },
  { language: 'Chinese (HKG)', code: 'zh_HK' },
  // { language: 'Chinese (TAI)', code: 'zh_TW' },
  // { language: 'Croatian', code: 'hr' },
  // { language: 'Czech', code: 'cs' },
  // { language: 'Danish', code: 'da' },
  // { language: 'Dutch', code: 'nl' },
  { language: 'English', code: 'en' },
  // { language: 'English (UK)', code: 'en_GB' },
  // { language: 'English (US)', code: 'en_US' },
  // { language: 'Estonian', code: 'et' },
  // { language: 'Filipino', code: 'fil' },
  // { language: 'Finnish', code: 'fi' },
  // { language: 'French', code: 'fr' },
  // { language: 'Georgian', code: 'ka' },
  // { language: 'German', code: 'de' },
  // { language: 'Greek', code: 'el' },
  // { language: 'Gujarati', code: 'gu' },
  // { language: 'Hausa', code: 'ha' },
  // { language: 'Hebrew', code: 'he' },
  // { language: 'Hindi', code: 'hi' },
  // { language: 'Hungarian', code: 'hu' },
  // { language: 'Indonesian', code: 'id' },
  // { language: 'Irish', code: 'ga' },
  // { language: 'Italian', code: 'it' },
  // { language: 'Japanese', code: 'ja' },
  // { language: 'Kannada', code: 'kn' },
  // { language: 'Kazakh', code: 'kk' },
  // { language: 'Kinyarwanda', code: 'rw_RW' },
  // { language: 'Korean', code: 'ko' },
  // { language: 'Kyrgyz (Kyrgyzstan)', code: 'ky_KG' },
  // { language: 'Lao', code: 'lo' },
  // { language: 'Latvian', code: 'lv' },
  // { language: 'Lithuanian', code: 'lt' },
  // { language: 'Macedonian', code: 'mk' },
  // { language: 'Malay', code: 'ms' },
  // { language: 'Malayalam', code: 'ml' },
  // { language: 'Marathi', code: 'mr' },
  // { language: 'Norwegian', code: 'nb' },
  // { language: 'Persian', code: 'fa' },
  // { language: 'Polish', code: 'pl' },
  // { language: 'Portuguese (BR)', code: 'pt_BR' },
  // { language: 'Portuguese (POR)', code: 'pt_PT' },
  // { language: 'Punjabi', code: 'pa' },
  // { language: 'Romanian', code: 'ro' },
  // { language: 'Russian', code: 'ru' },
  // { language: 'Serbian', code: 'sr' },
  // { language: 'Slovak', code: 'sk' },
  // { language: 'Slovenian', code: 'sl' },
  // { language: 'Spanish', code: 'es' },
  // { language: 'Spanish (ARG)', code: 'es_AR' },
  // { language: 'Spanish (SPA)', code: 'es_ES' },
  // { language: 'Spanish (MEX)', code: 'es_MX' },
  // { language: 'Swahili', code: 'sw' },
  // { language: 'Swedish', code: 'sv' },
  // { language: 'Tamil', code: 'ta' },
  // { language: 'Telugu', code: 'te' },
  // { language: 'Thai', code: 'th' },
  // { language: 'Turkish', code: 'tr' },
  // { language: 'Ukrainian', code: 'uk' },
  // { language: 'Urdu', code: 'ur' },
  // { language: 'Uzbek', code: 'uz' },
  // { language: 'Vietnamese', code: 'vi' },
  // { language: 'Zulu', code: 'zu' },
];

const MAX_NAME_LENGTH = 512;
const MAX_HEADER_TEXT_LENGTH = 60;
const MAX_BODY_TEXT_LENGTH = 1024;
const MAX_FOOTER_TEXT_LENGTH = 60;

const MAX_FILE_SIZE = {
  audio: {
    byte: 16777216, // 16MB
    displayString: '16MB',
  },
  document: {
    byte: 104857600, // 100MB
    displayString: '100MB',
  },
  image: {
    byte: 5242880, // 5MB
    displayString: '55MB',
  },
  video: {
    byte: 16777216, // 16MB
    displayString: '16MB',
  },
};

// * Helper functions.
// > Validate name.
const validateName = (name, { required = false } = {}) => {
  console.log('Invoke validateName', { name, options: { required } });

  if (required === true && name === '') {
    return 'REQUIRED';
  }

  // Skip the continue validations.
  if (required === false && name === '') {
    return;
  }

  const regex = /^[a-z0-9_]+$/;
  if (!regex.test(name)) {
    return 'INVALID_CHARACTER';
  }

  if (name.length > MAX_NAME_LENGTH) {
    return 'EXCEEDED_LENGTH';
  }
};

// > Validate header text.
const validateHeaderText = (headerText, { required = false } = {}) => {
  console.log('Invoke validateHeaderText', { headerText, options: { required } });

  if (required === true && headerText === '') {
    return 'REQUIRED';
  }

  // Skip the continue validations.
  if (required === false && headerText === '') {
    return;
  }

  if (headerText.length > MAX_HEADER_TEXT_LENGTH) {
    return 'EXCEEDED_LENGTH';
  }
};

// > Validate body text.
const validateBodyText = (bodyText, { required = false, variables } = {}) => {
  console.log('Invoke validateBodyText', { bodyText, options: { required, variables } });

  if (required === true && bodyText === '') {
    return 'REQUIRED';
  }

  // Skip the continue validations.
  if (required === false && bodyText === '') {
    return;
  }

  console.log({ bodyLength: bodyText.length });
  if (bodyText.length > MAX_BODY_TEXT_LENGTH) {
    return 'EXCEEDED_LENGTH';
  }

  // * Validate body variables.
  if (required === true && Array.isArray(variables)) {
    for (const bodyVariable of variables) {
      if (bodyVariable?.value === '') {
        return 'VARIABLE_SAMPLE_TEXT_REQUIRED';
      }
    }
  }
};

// > Validate footer text.
const validateFooterText = (footerText, { required = false } = {}) => {
  console.log('Invoke validateFooterText', { footerText, options: { required } });

  if (footerText.length > MAX_FOOTER_TEXT_LENGTH) {
    return 'EXCEEDED_LENGTH';
  }
};

const extractVariableNumbers = bodyText => {
  // Extract variable blocks using regular expression
  const variableBlocks = bodyText.match(/{{(.*?)}}/g);

  console.log({ variableBlocks });

  // Remove the double curly braces from variable blocks
  const varaibleNumbers = Array.isArray(variableBlocks)
    ? variableBlocks.map(block => parseInt(block.replace(/{{|}}/g, '')))
    : [];

  const sortedVariableNumbers = varaibleNumbers.toSorted((a, b) => a - b);
  const uniqueVariableNumbers = sortedVariableNumbers.filter(
    (item, pos) => sortedVariableNumbers.indexOf(item) === pos
  );

  return {
    original: varaibleNumbers,
    sorted: sortedVariableNumbers,
    unique: uniqueVariableNumbers,
  };
};

// * Components.
const FileUploader = ({ onFileSelectSuccess, onFileSelectError, selectedFile, disabled = false, headerFormat }) => {
  const { t } = useTranslation();

  const fileInput = useRef(null);
  const [label, setLabel] = useState('');
  const [acceptType, setAcceptType] = useState('');
  let type;

  switch (headerFormat) {
    case 'IMAGE':
      type = 'image';
      break;

    case 'VIDEO':
      type = 'video';
      break;

    case 'DOCUMENT':
      type = 'file';
      break;

    default:
  }

  useEffect(() => {
    console.log('[FileUploader] on change selected', selectedFile);

    if (selectedFile === null) {
      fileInput.current.value = null;
    }
  }, [selectedFile]);

  useEffect(() => {
    console.log('On change file type');
    switch (type) {
      case 'image':
        setLabel('Image');
        setAcceptType('image/png,image/jpeg,image/jpg');
        break;

      case 'video':
        setLabel('Video');
        setAcceptType('video/mp4');
        break;

      case 'file':
        setLabel('Document');
        setAcceptType('application/pdf');
        break;

      default:
    }
  }, [type]);

  const handleFileInput = e => {
    // handle validations
    const file = e.target.files[0];
    console.log({ file });

    if (file === undefined) {
      // onFileSelectError({ error: "No file is selected" });
      console.log({ error: 'No file is selected' });
    }

    // Validate file type and file size
    switch (file.type) {
      // Document
      case 'application/pdf':
        if (file.size > MAX_FILE_SIZE.document.byte) {
          onFileSelectError({ error: 'File size cannot exceed more than 16MB' });
          return;
        }
        break;

      // Image
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
        if (file.size > MAX_FILE_SIZE.image.byte) {
          onFileSelectError({ error: 'File size cannot exceed more than 16MB' });
          return;
        }
        break;

      // Video
      case 'video/mp4':
        if (file.size > MAX_FILE_SIZE.video.byte) {
          onFileSelectError({ error: 'File size cannot exceed more than 16MB' });
          return;
        }
        break;

      default:
        onFileSelectError({ error: 'Unsupported file type' });
        return;
    }

    onFileSelectSuccess(file);
  };

  return (
    // <div className="file-uploader">
    //   <label for="formFile" class="form-label">
    //     Default file input example
    //   </label>
    //   <input ref={fileInput} type="file" onChange={handleFileInput} style={{ display: 'none' }} accept={acceptType} />
    // </div>
    <Form.Group controlId="formFile">
      <Form.Label>{label}</Form.Label>
      <Form.Control ref={fileInput} onChange={handleFileInput} type="file" accept={acceptType} disabled={disabled} />
    </Form.Group>
  );
};

// * Main component.
const MessageTemplateForm = props => {
  const { t } = props;

  const appSettings = useContext(AppSettings);
  const navigate = useNavigate();
  const location = useLocation();

  // * Refs
  // > Body.
  const bodyTextRef = useRef(null);
  const bodyTextEmojiPickerRef = useRef(null);

  // * Page states.
  const [roleList, setRoleList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [editMessageTemplate, setEditMessageTemplate] = useState(null);
  const [editMode, setEditMode] = useState(false);

  // * Form input states.
  // > Form submission state.
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  // > Preview.
  const [previewComponents, setPreviewComponents] = useState([]);
  // > Form data states.
  const [language, setLanguage] = useState(MESSAGE_TEMPLATE_SUPPORTED_LANGUAGES[0].code);
  const [category, setCategory] = useState(CATEGORIES[0].value);
  const [name, setName] = useState(
    process.env.REACT_APP_API_ENV === 'PROD' ? '' : `test_${new Date().getTime().toString()}`
  );
  const [nameError, setNameError] = useState(undefined);
  const [selectedMessageTemplateLanguages, setSelectedMessageTemplateLanguages] = useState({}); // Indexed by agent ID
  const [headerFormat, setHeaderFormat] = useState(COMPONENT_HEADER_FORMATS[0].value);
  const [headerText, setHeaderText] = useState(process.env.REACT_APP_API_ENV === 'PROD' ? '' : '');
  const [headerTextError, setHeaderTextError] = useState(undefined);
  // File hook
  const [headerSelectedFile, setHeaderSelectedFile] = useState(null);
  const [headerFileOriginalName, setHeaderFileOriginalName] = useState(null);
  const [bodyText, setBodyText] = useState(process.env.REACT_APP_API_ENV === 'PROD' ? '' : '');
  const [bodyTextVariables, setBodyTextVariables] = useState([]);
  const [bodyTextError, setBodyTextError] = useState(undefined);
  const [bodyTextHighlight, setBodyTextHighlight] = useState(undefined);
  // Emoji picker for body text.
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [footerText, setFooterText] = useState(process.env.REACT_APP_API_ENV === 'PROD' ? '' : '');
  const [footerTextError, setFooterTextError] = useState(undefined);
  const [status, setStatus] = useState(null);

  // * React effects
  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    // };
  }, [appSettings]);

  useEffect(() => {
    getPermissionList().then(res => {
      let resposnse = res.data;
      if (resposnse.status) {
        setPermissionList(resposnse.params);
      }
    });
  }, []);

  // > On enter page.
  useEffect(() => {
    if (location.state?.messageTemplate) {
      setEditMessageTemplate(location.state.messageTemplate);
      setLanguage(location.state.messageTemplate.language);
      setCategory(location.state.messageTemplate.category);
      setName(location.state.messageTemplate.name);
      setStatus(location.state.messageTemplate.status);

      for (const component of location.state.messageTemplate.components) {
        switch (component.type) {
          case 'HEADER':
            setHeaderFormat(component.format);
            setHeaderText(component.text);
            break;

          case 'BODY':
            setBodyText(component.text);

            // Body variables.
            if (Array.isArray(component?.example?.body_text?.[0])) {
              const newBodyTextVariables = [];

              for (const bodyTextVariableValue of component.example.body_text[0]) {
                newBodyTextVariables.push({
                  value: bodyTextVariableValue,
                });
              }

              console.log('test test test', { newBodyTextVariables });
              setBodyTextVariables(newBodyTextVariables);
            }
            break;

          case 'FOOTER':
            setFooterText(component.text);
            break;

          default:
            break;
        }
      }

      if (Array.isArray(location.state.messageTemplate?.mediaStorage)) {
        for (const mediaStorage of location.state.messageTemplate?.mediaStorage) {
          if (mediaStorage.component === 'header') {
            setHeaderFileOriginalName(mediaStorage.original_name);
          }
        }
      }

      setEditMode(true);
    }
  }, [location]);

  // > Update body text variable input and preview.
  useEffect(() => {
    if (bodyText !== '') {
      setBodyTextVariables(bodyTextVariables => {
        // Update body text variable inputs.
        let extractedVariableNumbers = extractVariableNumbers(bodyText);
        const newBodyTextVariables = [];

        for (let index = 0; index < extractedVariableNumbers.unique.length; index++) {
          if (typeof bodyTextVariables?.[index] !== 'undefined') {
            // * Has existing variable value.
            console.log('Has existing variable value.', bodyTextVariables[index]);
            newBodyTextVariables.push(bodyTextVariables[index]);
            continue;
          }

          // * No existing variable value.
          newBodyTextVariables.push({
            value: '',
          });
        }

        console.log('test test', { bodyText, bodyTextVariables, newBodyTextVariables });

        return newBodyTextVariables;
      });
    }
  }, [bodyText, JSON.stringify(bodyTextVariables)]);

  // > Update preview on component change.
  useEffect(() => {
    const newPreviewComponents = [];
    // * Header
    switch (headerFormat) {
      case 'TEXT':
        newPreviewComponents.push({
          type: 'HEADER',
          format: headerFormat,
          text: headerText,
        });
        break;

      default:
        break;
    }

    // * Body
    if (bodyText !== '') {
      // // Update body text variable inputs.
      // let extractedVariableNumbers = extractVariableNumbers(bodyText);
      // const newBodyTextVariables = [];

      // for (let index = 0; index < extractedVariableNumbers.unique.length; index++) {
      //   if (typeof bodyTextVariables?.[index] !== 'undefined') {
      //     // * Has existing variable value.
      //     newBodyTextVariables.push(bodyTextVariables[index]);
      //     continue;
      //   }

      //   // * No existing variable value.
      //   newBodyTextVariables.push({
      //     value: '',
      //   });
      // }

      // setBodyTextVariables(newBodyTextVariables);

      const newBodyComponent = {
        type: 'BODY',
        text: bodyText,
      };

      if (bodyTextVariables.length > 0) {
        newBodyComponent.example = {
          body_text: [bodyTextVariables.map(bodyTextVariable => bodyTextVariable.value)],
        };
      }

      newPreviewComponents.push(newBodyComponent);
    }

    // * Footer
    if (footerText !== '') {
      newPreviewComponents.push({
        type: 'FOOTER',
        text: footerText,
      });
    }

    setPreviewComponents(newPreviewComponents);
  }, [headerFormat, headerText, bodyText, JSON.stringify(bodyTextVariables), footerText]);

  useEffect(() => {
    if (bodyTextHighlight !== undefined) {
      bodyTextRef.current.focus();
      bodyTextRef.current.setSelectionRange(bodyTextHighlight.start, bodyTextHighlight.end);
      setBodyTextHighlight(undefined);
    }
  }, [bodyTextHighlight]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (bodyTextEmojiPickerRef.current && !bodyTextEmojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [bodyTextEmojiPickerRef]);

  // * Helper functions.
  // ^ The following toggle functions handle validation + UI toggling.
  // ^ This separate the validation and UI toggling function. Allow validation functions being isolated.
  // ^ Those validation functions can now be duplicated to server for validation without changing the decoupling the UI code.
  // > Toggle name error.
  const toggleNameError = (name, { required = false } = {}) => {
    console.log('Invoke toggleNameError', { name, options: { required } });

    const error = validateName(name, { required });

    console.log({ error });

    if (error === 'REQUIRED') {
      setNameError('This field is required.');
      return error;
    }

    if (error === 'INVALID_CHARACTER') {
      setNameError('This can only have lowercase letters and underscores.');
      return error;
    }

    if (error === 'EXCEEDED_LENGTH') {
      setNameError(`This is limited to ${MAX_NAME_LENGTH} characters.`);
      return error;
    }

    setNameError(undefined);
  };

  // > Toggle header text error.
  const toggleHeaderTextError = (headerText, { required = false } = {}) => {
    console.log('Invoke toggleHeaderTextError', { headerText, options: { required } });

    if (headerFormat !== 'TEXT') {
      // Skip.
      console.log('Skip. (Header Format)');
      return;
    }

    const error = validateHeaderText(headerText, { required });

    if (error === 'REQUIRED') {
      setHeaderTextError('This field is required.');
      return error;
    }

    if (error === 'EXCEEDED_LENGTH') {
      setHeaderTextError(`This is limited to ${MAX_HEADER_TEXT_LENGTH} characters.`);
      return error;
    }

    setHeaderTextError(undefined);

    return error;
  };

  // > Toggle body text error.
  const toggleBodyTextError = (bodyText, { required = false, variables } = {}) => {
    // console.log('Invoke toggleBodyTextError', { bodyText, options: { required } });

    const error = validateBodyText(bodyText, { required, variables });

    if (error === 'REQUIRED') {
      setBodyTextError('This field is required.');
      return error;
    }

    if (error === 'EXCEEDED_LENGTH') {
      setBodyTextError(`This is limited to ${MAX_BODY_TEXT_LENGTH} characters.`);
      return error;
    }

    if (error === 'VARIABLE_SAMPLE_TEXT_REQUIRED') {
      setBodyTextError(`Please add sample text for all variables.`);
      return error;
    }

    setBodyTextError(undefined);

    return error;
  };

  // > Toggle footer text error.
  const toggleFooterTextError = (footerText, { required = false } = {}) => {
    console.log('Invoke toggleFooterTextError', { footerText, options: { required } });

    const error = validateFooterText(footerText, { required });

    if (error === 'REQUIRED') {
      setFooterTextError('This field is required.');
      return error;
    }

    if (error === 'EXCEEDED_LENGTH') {
      setFooterTextError(`This is limited to ${MAX_FOOTER_TEXT_LENGTH} characters.`);
      return error;
    }

    setFooterTextError(undefined);

    return error;
  };

  // * Event functions.
  // > On change name.
  const handleChangeName = e => {
    const newName = e.target.value;
    setName(newName);

    // * Validation
    toggleNameError(newName);
  };

  // > On change header text.
  const handleChangeHeaderText = e => {
    const newHeaderText = e.target.value;
    setHeaderText(newHeaderText);

    toggleHeaderTextError(newHeaderText);
  };

  // > On change body text.
  const handleChangeBodyText = e => {
    const newBodyText = e.target.value;
    setBodyText(newBodyText);

    toggleBodyTextError(newBodyText);
  };

  // > On change body text variable.
  const handleChangeBodyTextVariable = (e, index) => {
    setBodyTextVariables(prev => {
      prev[index] = { value: e.target.value };
      return [...prev];
    });
  };

  // > Format body text.
  const formatSelectedBodyText = formatEncloser => {
    console.log('formatSelectedBodyText()', { formatEncloser });

    const start = bodyTextRef.current.selectionStart;
    const end = bodyTextRef.current.selectionEnd;

    // Get the selected text
    const selectedText = bodyText.substring(start, end);

    console.log({ selectedText });

    // Apply formatting to the selected text
    let formattedText = bodyText.slice(0, start);
    formattedText += `${formatEncloser}${selectedText}${formatEncloser}`;
    formattedText += bodyText.slice(end);

    // Update the state with the formatted text
    setBodyText(formattedText);

    // Set the focus and selection back to the textarea
    // bodyTextRef.current.focus();
    // bodyTextRef.current.setSelectionRange(start, end);
    setBodyTextHighlight({ start: start + formatEncloser.length, end: end + formatEncloser.length });
  };

  // > Add emoji to body text.
  const addEmoji = ({ emoji }) => {
    const start = bodyTextRef.current.selectionStart;
    const end = bodyTextRef.current.selectionEnd;

    let newBodyText = bodyText.slice(0, start);
    newBodyText += emoji;
    newBodyText += bodyText.slice(end);

    setBodyText(newBodyText);

    setBodyTextHighlight({ start: start + emoji.length, end: end + emoji.length });
  };

  // > Add variable to body text.
  const addVariable = () => {
    console.log('addVariable()');

    let newBodyText = bodyText;

    let extractedVariableNumbers = extractVariableNumbers(newBodyText);
    const hasMissingVariableIndex = extractedVariableNumbers.unique.some((value, index) => {
      console.log('test joseph', { value, index });
      return value !== index + 1;
    });

    console.log({ extractedVariableNumbers, hasMissingVariableIndex });

    // * Has duplicate, clean up duplicates and reset variable numbers.
    if (hasMissingVariableIndex) {
      for (const key in extractedVariableNumbers.unique) {
        // * Replace and correct varaible number.
        const oldVariableNumber = extractedVariableNumbers.unique[key];
        const newVariableNumber = Number(key) + 1;
        console.log({ oldVariableNumber, newVariableNumber });

        // > Replace.
        newBodyText = newBodyText.replace(`{{${oldVariableNumber}}}`, `{{${newVariableNumber}}}`);
      }

      // * Re-extract after correcting the variable numbers
      extractedVariableNumbers = extractVariableNumbers(newBodyText);
    }

    console.log({ newBodyText, extractedVariableNumbers });

    // * Assign new varaible.
    const nextVariableNumber = extractedVariableNumbers.unique.length + 1;
    newBodyText = `${newBodyText} {{${nextVariableNumber}}}`;

    setBodyText(newBodyText);
  };

  // > On change footer text.
  const handleChangeFooterText = e => {
    const newFooterText = e.target.value;
    setFooterText(newFooterText);

    toggleFooterTextError(newFooterText);
  };

  const handleSubmit = event => {
    try {
      event.preventDefault();

      setIsFormSubmitting(true);

      console.log({ event });

      const isNameValid = typeof toggleNameError(name, { required: true }) === 'undefined';
      const isHeaderTextValid = typeof toggleHeaderTextError(headerText, { required: true }) === 'undefined';
      const isBodyTextValid =
        typeof toggleBodyTextError(bodyText, { required: true, variables: bodyTextVariables }) === 'undefined';
      const isFooterTextValid = typeof toggleFooterTextError(footerText, { required: true }) === 'undefined';

      console.log('Form validation', {
        isNameValid,
        isHeaderTextValid,
        isBodyTextValid,
        isFooterTextValid,
      });

      if (!isNameValid || !isHeaderTextValid || !isBodyTextValid || !isFooterTextValid) {
        Store.addNotification({
          message: `Please check your inputs for error.`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: { duration: 5000 },
        });

        setIsFormSubmitting(false);
      } else {
        // let requestBody = {
        //   language,
        //   category,
        //   name,
        //   headerFormat,
        //   headerText,
        //   bodyText,
        //   footerText,
        // };
        const requestBody = new FormData();
        requestBody.append('language', language);
        requestBody.append('category', category);
        requestBody.append('name', name);
        requestBody.append('headerFormat', headerFormat);
        requestBody.append('headerText', headerText);
        if (headerSelectedFile !== null) requestBody.append('file', headerSelectedFile);
        requestBody.append('bodyText', bodyText);
        requestBody.append('bodyTextVariables', JSON.stringify(bodyTextVariables));
        requestBody.append('footerText', footerText);

        console.log({ requestBody });
        for (var pair of requestBody.entries()) {
          console.log(pair[0] + ', ' + pair[1].length);
        }

        if (editMode) {
          // TODO
          apiGenerator({
            method: 'PATCH',
            path: `message_templates/${editMessageTemplate._id}`,
            body: requestBody,
          })
            .then(res => {
              // alert(res.data.message);
              Store.addNotification({
                message: res.data.message,
                type: 'success',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: { duration: 5000 },
              });

              // TODO: uncomment.
              if (res.data.status) {
                navigate('/message_templates');
              }
            })
            .catch(error => {
              // handle error
              console.log(error);

              Store.addNotification({
                message: `Failed to edit message template.`,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: { duration: 5000 },
              });

              setIsFormSubmitting(false);

              switch (error?.response?.data?.message?.error_subcode) {
                case 2388003:
                  Store.addNotification({
                    message: `Message templates can only be edited if they have been rejected.`,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: { duration: 5000 },
                  });
                  break;

                default:
                  if (typeof error?.response?.data?.message?.error_user_msg !== 'undefined') {
                    Store.addNotification({
                      message: error.response.data.message.error_user_msg,
                      type: 'danger',
                      insert: 'top',
                      container: 'top-right',
                      animationIn: ['animated', 'fadeIn'],
                      animationOut: ['animated', 'fadeOut'],
                      dismiss: { duration: 5000 },
                    });
                  }
              }
            });
        } else {
          apiGenerator({
            method: 'POST',
            path: 'message_templates',
            body: requestBody,
          })
            .then(res => {
              Store.addNotification({
                message: res.data.message,
                type: res.data.status ? 'success' : 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: { duration: 5000 },
              });

              // TODO: uncomment.
              if (res.data.status) {
                navigate('/message_templates');
              }
            })
            .catch(error => {
              // handle error
              console.log('Create template error', error);

              Store.addNotification({
                message: `Failed to create message template.`,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: { duration: 5000 },
              });

              setIsFormSubmitting(false);

              switch (error?.response?.data?.message?.error_subcode) {
                case 2388023:
                  setNameError(
                    'A different name should be considered as the original template with the same name is being deleted. (The name cannot be used again for 30 days since the deletion.)'
                  );
                  break;

                case 2388024:
                  setNameError(
                    'The name has been used for another template under the current template language. Please use a different name.'
                  );
                  break;

                default:
                  if (typeof error?.response?.data?.message?.error_user_msg !== 'undefined') {
                    Store.addNotification({
                      message: error.response.data.message.error_user_msg,
                      type: 'danger',
                      insert: 'top',
                      container: 'top-right',
                      animationIn: ['animated', 'fadeIn'],
                      animationOut: ['animated', 'fadeOut'],
                      dismiss: { duration: 5000 },
                    });
                  }
              }
            });
        }
      }
    } catch (error) {
      console.log('Failed to create/edit message template.');
      console.log(error);

      Store.addNotification({
        message: `API call failed.`,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: { duration: 5000 },
      });

      setIsFormSubmitting(false);
    }
  };

  return (
    <Container>
      <div className="d-flex align-items-center">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={'/message_templates'}>Message Template Management</Link>
            </li>
            <li className="breadcrumb-item active">{editMode ? 'Edit Message Template' : 'Add Message Template'}</li>
          </ol>
          <h1 className="page-header">{editMode ? 'Edit Message Template' : 'Add Message Template'}</h1>
        </div>
      </div>

      <Form onSubmit={handleSubmit} id="messageTemplateForm">
        <div className="row gx-4">
          {/* Basic message template settings. */}
          <div className="col-lg-4">
            <div className="card border-0 mb-4">
              <div className="card-body p-3 text-dark">
                <div className="row">
                  {/* Left side */}
                  <div className="col-sm-12">
                    {/* Message preview */}
                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <label className="form-label">Message Preview</label>
                        <div
                          style={{ backgroundColor: '#efeae2', padding: '15px 15px 15px 15px', borderRadius: '10px' }}
                        >
                          <MsgRow
                            id={''}
                            message_id={''}
                            key={'preview'}
                            type={'send'}
                            msg={''}
                            template={{
                              message_template: {
                                components: previewComponents,
                              },
                            }}
                            marked={false}
                            time={new Date().getTime()}
                            messageType={'template'}
                            isMedia={false}
                            media_info={{
                              media_id: '',
                              media_type: '',
                              mime_type: '',
                              extension: '',
                            }}
                            previewMode={true}
                            conversationMessageBubbleCss={{ maxWidth: '100%' }}
                          />
                        </div>
                      </div>
                    </div>
                    <fieldset>
                      {/* Language */}
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <label className="form-label">Language</label>
                          <select
                            className="form-select"
                            name="category"
                            value={language}
                            onChange={e => {
                              setLanguage(e.target.value);
                            }}
                            disabled={editMode === true || isFormSubmitting || status === 'PENDING'}
                          >
                            {MESSAGE_TEMPLATE_SUPPORTED_LANGUAGES.map(language => {
                              return (
                                <option value={`${language.code}`} key={language.code}>
                                  {language.language}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      {/* Category */}
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <label className="form-label">Category</label>
                          <select
                            className="form-select"
                            name="category"
                            value={category}
                            onChange={e => {
                              setCategory(e.target.value);
                            }}
                            disabled={isFormSubmitting || status === 'PENDING'}
                          >
                            {CATEGORIES.map(category => {
                              return (
                                <option value={`${category.value}`} key={category.value}>
                                  {category.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      {/* Name */}
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <label className="form-label" htmlFor="name">
                            Name
                          </label>
                          <input
                            type="text"
                            className={`form-control ${nameError !== undefined && 'is-invalid'}`}
                            name="name"
                            value={name}
                            onChange={handleChangeName}
                            disabled={editMode === true || isFormSubmitting || status === 'PENDING'}
                          />
                          {nameError !== undefined && <div className="invalid-feedback">{nameError}</div>}
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Advanced message template settings. */}
          <div className="col-lg-8">
            <div className="card border-0 mb-4">
              <div className="card-body p-3 text-dark fw-bold">
                <div className="row">
                  {/* Left side */}
                  <div className="col-sm-12">
                    <fieldset>
                      {/* Header */}
                      <div className="row mb-3">
                        {/* Header format */}
                        <div className="col-sm-6">
                          <label className="form-label">Header</label>
                          <select
                            className="form-select"
                            name="header"
                            value={headerFormat}
                            onChange={e => {
                              setHeaderFormat(e.target.value);
                            }}
                            disabled={isFormSubmitting || status === 'PENDING'}
                          >
                            {COMPONENT_HEADER_FORMATS.map(componentHeaderFormat => {
                              return (
                                <option value={`${componentHeaderFormat.value}`} key={componentHeaderFormat.value}>
                                  {componentHeaderFormat.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {/* Header text */}
                        {headerFormat === 'TEXT' && (
                          <div className="col-sm-6">
                            <label className="form-label" htmlFor="name">
                              Header Text
                            </label>
                            <input
                              type="text"
                              className={`form-control ${headerTextError !== undefined && 'is-invalid'}`}
                              name="headerText"
                              value={headerText}
                              onChange={handleChangeHeaderText}
                              disabled={isFormSubmitting || status === 'PENDING'}
                            />
                            {headerTextError !== undefined && <div className="invalid-feedback">{headerTextError}</div>}
                          </div>
                        )}
                        {(headerFormat === 'IMAGE' || headerFormat === 'VIDEO' || headerFormat === 'DOCUMENT') && (
                          <div className="col-sm-6">
                            <FileUploader
                              onFileSelectSuccess={file => setHeaderSelectedFile(file)}
                              onFileSelectError={({ error }) => {
                                // alert(error)
                                Store.addNotification({
                                  message: error,
                                  type: 'danger',
                                  insert: 'top',
                                  container: 'top-right',
                                  animationIn: ['animated', 'fadeIn'],
                                  animationOut: ['animated', 'fadeOut'],
                                  dismiss: { duration: 5000 },
                                });
                              }}
                              selectedFile={headerSelectedFile}
                              headerFormat={headerFormat}
                              disabled={isFormSubmitting || status === 'PENDING'}
                            />
                            {headerFileOriginalName !== null && (
                              <small className="fs-12px text-gray-500-darker">
                                Uploaded file: <b>{headerFileOriginalName}</b>
                              </small>
                            )}
                          </div>
                        )}
                      </div>
                      {/* Body */}
                      <div className="row mb-3">
                        {/* Body text */}
                        <div className="col-sm-12">
                          <label className="form-label" htmlFor="name">
                            Body Text
                          </label>
                          <TextareaAutosize
                            ref={bodyTextRef}
                            as="textarea"
                            minRows={6}
                            className={`form-control ${bodyTextError !== undefined && 'is-invalid'}`}
                            name="bodyText"
                            onChange={handleChangeBodyText}
                            value={bodyText}
                            disabled={isFormSubmitting || status === 'PENDING'}
                          />
                          {/* Body text formatting function bar. */}
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {/* Emoji */}
                            <button
                              type="button"
                              className="btn btn-textarea-function"
                              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                height="1rem"
                                width="1rem"
                                style={{ width: '100%', height: '100%' }}
                              >
                                <path
                                  fill="currentColor"
                                  d="M9.153,11.603c0.795,0,1.439-0.879,1.439-1.962S9.948,7.679,9.153,7.679 S7.714,8.558,7.714,9.641S8.358,11.603,9.153,11.603z M5.949,12.965c-0.026-0.307-0.131,5.218,6.063,5.551 c6.066-0.25,6.066-5.551,6.066-5.551C12,14.381,5.949,12.965,5.949,12.965z M17.312,14.073c0,0-0.669,1.959-5.051,1.959 c-3.505,0-5.388-1.164-5.607-1.959C6.654,14.073,12.566,15.128,17.312,14.073z M11.804,1.011c-6.195,0-10.826,5.022-10.826,11.217 s4.826,10.761,11.021,10.761S23.02,18.423,23.02,12.228C23.021,6.033,17.999,1.011,11.804,1.011z M12,21.354 c-5.273,0-9.381-3.886-9.381-9.159s3.942-9.548,9.215-9.548s9.548,4.275,9.548,9.548C21.381,17.467,17.273,21.354,12,21.354z  M15.108,11.603c0.795,0,1.439-0.879,1.439-1.962s-0.644-1.962-1.439-1.962s-1.439,0.879-1.439,1.962S14.313,11.603,15.108,11.603z"
                                ></path>
                              </svg>
                            </button>
                            <div
                              className={showEmojiPicker ? 'd-block' : 'd-none'}
                              style={{ position: 'relative', width: '0px' }}
                            >
                              <div ref={bodyTextEmojiPickerRef} style={{ position: 'absolute', top: '100%', right: 0 }}>
                                <EmojiPicker
                                  width={'100%'}
                                  searchPlaceholder="Search Emoji Here..."
                                  lazyLoadEmojis={true}
                                  emojiStyle="apple"
                                  autoFocusSearch={false}
                                  previewConfig={{ showPreview: false }}
                                  onEmojiClick={addEmoji}
                                />
                              </div>
                            </div>

                            {/* Bold. */}
                            <button
                              type="button"
                              className="btn btn-textarea-function"
                              onClick={() => formatSelectedBodyText('*')}
                            >
                              <i className="fa fa-bold"></i>
                            </button>

                            {/* Italic. */}
                            <button
                              type="button"
                              className="btn btn-textarea-function"
                              onClick={() => formatSelectedBodyText('_')}
                            >
                              <i className="fa fa-italic"></i>
                            </button>

                            {/* Strikethrough. */}
                            <button
                              type="button"
                              className="btn btn-textarea-function"
                              onClick={() => formatSelectedBodyText('~')}
                            >
                              <i className="fa fa-strikethrough"></i>
                            </button>

                            {/* Monospace. */}
                            <button
                              type="button"
                              className="btn btn-textarea-function"
                              onClick={() => formatSelectedBodyText('```')}
                            >
                              <i className="fa fa-code"></i>
                            </button>

                            {/* Variable. */}
                            <button type="button" className="btn btn-textarea-function" onClick={() => addVariable()}>
                              <i className="fa fa-plus fa-lg me-1"></i> {t('Add variable')}
                            </button>
                          </div>

                          {/* Variable inputs. */}
                          {bodyTextVariables.length > 0 && (
                            <>
                              {/* <h6>Body Text Variables</h6> */}
                              <label className="form-label" htmlFor="name">
                                Variables (Body Text)
                              </label>
                              <div>
                                {bodyTextVariables.map((bodyTextVariable, index) => (
                                  <div
                                    key={index}
                                    style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '5px' }}
                                  >
                                    <div>{`{{${index + 1}}}`}</div>
                                    <input
                                      type="text"
                                      className={`form-control ${bodyTextError !== undefined && 'is-invalid'}`}
                                      placeholder={`Enter content for {{${index + 1}}}`}
                                      name={`bodyTextVariable[${index}]`}
                                      value={bodyTextVariable.value}
                                      onChange={e => {
                                        handleChangeBodyTextVariable(e, index);
                                      }}
                                      disabled={isFormSubmitting || status === 'PENDING'}
                                    />
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                          {bodyTextError !== undefined && <div className="invalid-feedback">{bodyTextError}</div>}
                        </div>
                      </div>
                      {/* Footer */}
                      <div className="row mb-3">
                        {/* Footer text */}
                        <div className="col-sm-12">
                          <label className="form-label" htmlFor="name">
                            Footer Text
                          </label>
                          <input
                            type="text"
                            className={`form-control ${footerTextError !== undefined && 'is-invalid'}`}
                            name="footerText"
                            value={footerText}
                            onChange={handleChangeFooterText}
                            disabled={isFormSubmitting || status === 'PENDING'}
                          />
                          {footerTextError !== undefined && <div className="invalid-feedback">{footerTextError}</div>}
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  {/* Right side */}
                  {false && (
                    <div className="col-sm-6">
                      <fieldset>
                        <div className="row mb-3">
                          <div className="col-sm-12">
                            <label className="form-label">Languages</label>
                            <SelectionList
                              style={{ overflowY: 'auto', maxHeight: '400px' }}
                              optionList={MESSAGE_TEMPLATE_SUPPORTED_LANGUAGES.map((value, index) => ({
                                id: value.code,
                                title: value.language,
                              }))}
                              handleClickOption={(e, option, index) => {
                                const newSelectedMessageTemplateLanguages = { ...selectedMessageTemplateLanguages };

                                if (
                                  newSelectedMessageTemplateLanguages?.[
                                    MESSAGE_TEMPLATE_SUPPORTED_LANGUAGES[index].code
                                  ] === undefined
                                ) {
                                  // Add language
                                  newSelectedMessageTemplateLanguages[
                                    MESSAGE_TEMPLATE_SUPPORTED_LANGUAGES[index].code
                                  ] = MESSAGE_TEMPLATE_SUPPORTED_LANGUAGES[index];
                                } else {
                                  // Remove language
                                  delete newSelectedMessageTemplateLanguages[
                                    MESSAGE_TEMPLATE_SUPPORTED_LANGUAGES[index].code
                                  ];
                                }

                                setSelectedMessageTemplateLanguages(newSelectedMessageTemplateLanguages);
                              }}
                              selectedOptions={selectedMessageTemplateLanguages}
                              href={(e, option, index) => `#message_template_language_${index}`}
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-footer bg-none d-flex p-3">
                <button
                  type="submit"
                  className="btn btn-primary w-fit ms-auto"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}
                  disabled={isFormSubmitting || status === 'PENDING'}
                >
                  {isFormSubmitting && (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  )}
                  <span className="ml-2">{status === 'PENDING' ? 'In Review' : t('submit')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default withTranslation()(MessageTemplateForm);
