import React, { useEffect, useState, useContext } from 'react';
import { Button, Col, Row, Table, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getUserList, getRoleList, apiGenerator } from '../api/api-manager';
import { Link, useNavigate } from 'react-router-dom';
import PageTitle from '../_component/PageTitle';
import CustomPagination from '../_component/Pagination';
import CustomPagination1 from '../_component/Pagination1';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';

import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import CustomModal1 from '../_component/CustomModal1';
import { usePermissions } from '../_component/context/AuthContext';
import { useDebounce } from '../_component/hooks/useDebounce';

const getAgentCountLimit = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'GET',
      path: `client/agent-count-limit`,
      body: {},
    }).then(res => {
      return resolve(res?.data ? res.data : undefined);
    });
  });
};

const Users1 = props => {
  const { t } = props;
  // const dispatch = useDispatch();
  const appSettings = useContext(AppSettings);
  const { permissions } = usePermissions();

  // Disable add agent button
  const [disableAddUser, setDisableAddUser] = useState(true);

  // Search fields
  const [searchKeyword, setSearchKeyword] = useState('');
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 350);
  const [searchRole, setSearchRole] = useState('');
  const { debouncedValue: debouncedSearchRole } = useDebounce(searchRole, 350);

  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteUser, setSelectedDeleteUser] = useState(undefined);

  const [page, setPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState([]);
  const limit = 2;

  const navigate = useNavigate();

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    // };
  }, [appSettings]);

  useEffect(() => {
    getRoleList().then(res => {
      let response = res.data;
      if (response.status) {
        setRoleList(response.params);
      }
    });

    toggleAgentLimit();
  }, []);

  useEffect(() => {
    updateUserList();
  }, [roleList, debouncedSearchKeyword, debouncedSearchRole, page]);

  useEffect(() => {
    console.log(`userList`, userList);
  }, [userList]);

  const toggleAgentLimit = () => {
    getAgentCountLimit().then(res => {
      if (isNaN(res?.data?.countLimit)) return setDisableAddUser(false);

      if (isNaN(res?.data?.count)) return setDisableAddUser(true);

      setDisableAddUser(res.data.count >= res.data.countLimit);
    });
  };

  const onDeleteModalSubmit = () => {
    apiGenerator({
      method: 'DELETE',
      path: `users/${selectedDeleteUser._id}`,
      body: {},
    }).then(res => {
      // alert(res.data.message);
      Store.addNotification({
        message: res.data.message,
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
      if (res.data.status) {
        updateUserList();
        toggleAgentLimit();
      }
    });
  }

  const handleDeleteUser = user => {
    setSelectedDeleteUser(user);
    setShowDeleteModal(true);
  };

  const editUser = user => {
    console.log(`editing user...`, user);
    navigate('/users/add', { state: { user: user } });
  };

  const updateUserList = () => {
    if (roleList.length > 0) {
      getUserList({
        keyword: debouncedSearchKeyword,
        role: debouncedSearchRole,
        page: page,
        limit: limit
      })
        .then(res => {
        let response = res.data;
        console.log(response);
        if (response.status) {
          let temp_user_list = [];

          for (const user of response.params.docs) {
            for (const role of roleList) {
              if (role._id == user.role_id) {
                temp_user_list.push({
                  ...user,
                  role_name: role.name,
                });
                break;
              }
            }
          }

          setUserList(temp_user_list);
          setPaginationParams({
            totalDocs: response.params.totalDocs,
            limit: response.params.limit,
            page: response.params.page,
            totalPages: response.params.totalPages,
            hasPrevPage: response.params.hasPrevPage,
            hasNextPage: response.params.hasNextPage,
            prevPage: response.params.prevPage,
            nextPage: response.params.nextPage,
          });
        }
      });
    }
  };
  return (
    <>
      <Container>
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={'/'}>Home</Link>
              </li>
              <li className="breadcrumb-item active">{t('agent_management')}</li>
            </ul>
            <h1 className="page-header mb-0">{t('agent_management')}</h1>
          </div>
          <div className="ms-auto">
            {permissions?.add_agent && (
              <OverlayTrigger
                placement="left"
                overlay={
                    <Tooltip>
                        {t('max_agent_reached')}
                    </Tooltip>
                }
                show={disableAddUser ? undefined : false}
              >
                <Link to={'/users/add'} onClick={disableAddUser ? (e => e.preventDefault()) : undefined} style={disableAddUser ? { cursor: 'not-allowed' } : undefined}>
                  <Button variant="primary" className="px-4 btn-rounded rounded-pill" disabled={disableAddUser}>
                    <i className="fa fa-plus fa-lg me-2 ms-n2 text-white"></i> {t('add_agent')}
                  </Button>
                </Link>
              </OverlayTrigger>
            )}
          </div>
        </div>

        <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              {/* Search bar */}
              <div className="row mb-3">
                <div className="col-sm-6">
                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                        style={{ zIndex: 10 }}
                      >
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control px-35px bg-light"
                        style={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
                        placeholder={t('search_username_name')}
                        value={searchKeyword}
                        onChange={e => {
                          setSearchKeyword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <select
                    className="form-select"
                    value={searchRole}
                    onChange={e => {
                      setSearchRole(e.target.value);
                    }}
                  >
                    <option value="" key="">
                      {t('all_roles')}
                    </option>
                    {roleList.map(role => {
                      return (
                        <option value={`${role._id}`} key={role._id}>
                          {role.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              {/* Table */}
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: '1%', whiteSpace: 'nowrap' }}>#</th>
                      {(permissions?.edit_agent || permissions?.delete_agent) && (
                        <th style={{ width: '1%', whiteSpace: 'nowrap' }}>{t('action')}</th>
                      )}
                      <th>{t('username')}</th>
                      <th>{t('name')}</th>
                      <th>{t('role')}</th>
                      {/* <th>{t('permission')}</th> */}
                      <th>{t('updated_at')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList &&
                      userList.length > 0 &&
                      userList.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ verticalAlign: 'super' }}>{index + 1}</td>
                            {(permissions?.edit_agent || permissions?.delete_agent) && (
                              <td nowrap="true" className="with-btn" style={{ verticalAlign: 'super' }}>
                                {permissions?.edit_agent && (
                                  <button className="btn btn-sm btn-primary w-60px me-1" onClick={() => editUser(user)}>
                                    {t('edit')}
                                  </button>
                                )}
                                {permissions?.delete_agent && (
                                  <button className="btn btn-sm btn-danger w-60px" onClick={() => handleDeleteUser(user)}>
                                    {t('delete')}
                                  </button>
                                )}
                              </td>
                            )}
                            <td style={{ verticalAlign: 'super' }}>{user.username}</td>
                            <td style={{ verticalAlign: 'super' }}>{user.name}</td>
                            <td style={{ verticalAlign: 'super' }}>{user.role_name}</td>
                            {/* <td style={{ verticalAlign: 'super' }}>
                              {user.permission_info &&
                                user.permission_info.length > 0 &&
                                user.permission_info.map((per, index) => {
                                  return (
                                    <div key={index}>
                                      {per.name}
                                      <br></br>
                                    </div>
                                  );
                                })}
                            </td> */}
                            <td style={{ verticalAlign: 'super' }}>
                              {dayjs(user.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="d-md-flex align-items-center">
                <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                  {t('pagination_info_1')} {(paginationParams.page - 1) * paginationParams.limit + 1} {t('pagination_info_2')}{' '}
                  {paginationParams.page * paginationParams.limit < paginationParams.totalDocs
                    ? paginationParams.page * paginationParams.limit
                    : paginationParams.totalDocs}{' '}
                  {t('pagination_info_3')} {paginationParams.totalDocs} {t('pagination_info_4')}
                </div>

                <CustomPagination1 paginationParams={paginationParams} setPage={setPage} />
              </div>
            </div>
          </div>
        </div>

        <CustomModal1
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          modal_title={t('delete_agent')}
          onSubmit={onDeleteModalSubmit}
          submitButtonText={t('confirm')}
        >
          <div className="alert alert-danger">
            <h5><i className="fa fa-info-circle"></i> {t('alert')}</h5>
            <p>{t('delete_confirmation_text')}</p>
          </div>
        </CustomModal1>
      </Container>
    </>
  );
};

export default withTranslation()(Users1);
