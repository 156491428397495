import React from 'react';
import { changeLanguage } from '../../../store/reducers/auth';
import { PATCH } from '../../../api/api-manager';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import dayjs from "dayjs";

const CurrentLanguage = ({ language }) => {
  switch (language) {
    case 'en_US':
      return (
        <>
          {/* <span className="flag-icon flag-icon-us" title="us"></span> */}
          <span className="d-none d-sm-inline ms-1">EN</span>
        </>
      );

    case 'zh_HK':
      return (
        <>
          {/* <span className="flag-icon flag-icon-tw" title="us"></span> */}
          <span className="d-none d-sm-inline ms-1">繁中</span>
        </>
      );

    case 'zh_CN':
      return (
        <>
          {/* <span className="flag-icon flag-icon-cn" title="us"></span> */}
          <span className="d-none d-sm-inline ms-1">简中</span>
        </>
      );

    default:
      break;
  }
};

const DropdownLanguage = ({ t }) => {
  const dispatch = useDispatch();

  return (
    <div className="navbar-item dropdown">
      <a href="#/" className="navbar-link dropdown-toggle" data-bs-toggle="dropdown">
        <CurrentLanguage language={i18n.language} /> <b className="caret"></b>
      </a>
      <div className="dropdown-menu dropdown-menu-end">
        <a
          href="#/"
          className="dropdown-item"
          onClick={() => {
            dispatch(PATCH(changeLanguage, 'users/set_default_language', { language_key: 'en_US' }));
            i18n.changeLanguage('en_US');
            dayjs.locale('en');
          }}
        >
          {/* <span className="flag-icon flag-icon-us me-2" title="us"></span> English */}
          English
        </a>
        <a
          href="#/"
          className="dropdown-item"
          onClick={() => {
            dispatch(PATCH(changeLanguage, 'users/set_default_language', { language_key: 'zh_HK' }));
            i18n.changeLanguage('zh_HK');
            dayjs.locale('zh-hk');
          }}
        >
          {/* <span className="flag-icon flag-icon-tw me-2" title="zh"></span> 繁體中文 */}
          繁體中文
        </a>
        <a
          href="#/"
          className="dropdown-item"
          onClick={() => {
            dispatch(PATCH(changeLanguage, 'users/set_default_language', { language_key: 'zh_CN' }));
            i18n.changeLanguage('zh_CN');
            dayjs.locale('zh-cn');
          }}
        >
          {/* <span className="flag-icon flag-icon-cn me-2" title="cn"></span> 简体中文 */}
          简体中文
        </a>
        {/* <a href="#/" className="dropdown-item"><span className="flag-icon flag-icon-jp me-2" title="jp"></span> Japanese</a>
					<a href="#/" className="dropdown-item"><span className="flag-icon flag-icon-be me-2" title="be"></span> Belgium</a> */}
        {/* <div className="dropdown-divider"></div> */}
        {/* <a href="#/" className="dropdown-item text-center">
          more options
        </a> */}
      </div>
    </div>
  );
};

export default withTranslation()(DropdownLanguage);
