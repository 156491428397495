import { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
/*
paginationParams
    {
        totalDocs: Number,
        limit: Number,
        page: Number,
        totalPages: Number,
        hasPrevPage: Number,
        hasNextPage: Number,
        prevPage: Number,
        nextPage: Number
    }
*/
function CustomPagination({paginationParams, setPage}) {

    const [items, setItems] = useState([]);

    useEffect(() => {
        console.log(paginationParams)
        let temp_item = [];
        for (let number = 1; number <= paginationParams.totalPages; number++) {
            temp_item.push(
                <Pagination.Item key={number} active={number === paginationParams.page} onClick= {()=> setPage(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        setItems(temp_item)
    },[paginationParams])

    
    return (
        <Pagination className='justify-content-center'>
            <Pagination.First onClick={() => setPage(1)} disabled={paginationParams.page == 1}/>
            <Pagination.Prev onClick={() => setPage(paginationParams.prevPage)} disabled={!paginationParams.hasPrevPage}/>
            
            <Pagination>{items}</Pagination>
            {/* <Pagination.Ellipsis /> */}
            {/* <Pagination.Item active>{12}</Pagination.Item> */}
            <Pagination.Next onClick={() => setPage(paginationParams.nextPage)} disabled={!paginationParams.hasNextPage}/>
            <Pagination.Last onClick={() => setPage(paginationParams.totalPages)} disabled={paginationParams.page == paginationParams.totalPages}/>
        </Pagination>
    );
}

export default CustomPagination;