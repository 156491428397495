import { createSlice } from "@reduxjs/toolkit";
import i18n from 'i18next';

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isLogined: false,
        permissions: [],
        userDisplayName: "",
        userID: "",
        userName: "",
        userRole: "",
        responseData: null,
        loading: false,
        loginErrorMsg: '',
        database: '',
        language_key: 'en_US'
    },
    reducers: {
        check(state){

        },
        checkSuccess(state){
            state.isLogined = false;
        },
        checkFail(state){

        },
        login(state) {
            state.loading = true;
        },
        loginSuccess(state, payload) {
            let responseData = payload.resData.data;
            state.userName = responseData.data.user_name;
            state.userDisplayName = responseData.data.display_name;
            state.userRole = responseData.data.role;
            state.permissions = responseData.data.permission
            state.isLogined = true;
            state.loading = false;
            state.userID = responseData.data.user_id;
            state.loginErrorMsg = '';
            state.database = responseData.data.database;
            state.language_key = responseData.data.language_key;
            i18n.changeLanguage(responseData.data.language_key);
        },
        loginFail(state, payload) {
            state.error = payload.error;
            state.loading = false;
            state.loginErrorMsg = 'Login Failed'
        },
        logout(state) {
            state.loading = true;
            state.loginErrorMsg = '';
        },
        logoutSuccess(state, payload) {
            state.isLogined = false;
            state.userName = "";
            state.userDisplayName = "";
            state.userRole = "";
            state.userID = "";
            state.loading = false;
            state.responseData = null
            state.permissions = []
        },
        logoutFail(state, payload) {
            state.error = payload.error;
            state.loading = false;
        },
        checkSession(state){

        },
        checkSessionSuccess(state, payload){
            if(!payload.resData.data.user_id){
                state.isLogined = false;
            }
        },
        checkSessionFail(state){

        },
        sessionExpired(state){
            state.isLogined = false;
        },
        sessionExpiredSuccess(state, payload){
            state.isLogined = false;
            state.loginErrorMsg = 'Session has expired. Login again.';
        },
        sessionExpiredFail(state){

        },
        changeLanguage(state){

        },
        changeLanguageSuccess(state, payload){
            let responseData = payload.resData.data;
            console.log(responseData.data.language_key);
            // i18n.changeLanguage(responseData.data.language_key);
        },
        changeLanguageFail(state){

        },
    },
});

export const selectAuth = (state) => state.auth;
export const {
    check,
    checkSuccess,
    checkFail,
    login,
    loginSuccess,
    loginFail,
    logout,
    logoutSuccess,
    logoutFail,
    checkSession,
    checkSessionSuccess,
    checkSessionFail,
    sessionExpired,
    sessionExpiredSuccess,
    sessionExpiredFail,
    changeLanguage,
    changeLanguageSuccess,
    changeLanguageFail,
} = authSlice.actions;

export default authSlice.reducer;