import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slider-modal';
import 'animate.css/animate.min.css';
import { ThreeDots } from 'react-loader-spinner';
import { useConversation } from './context/ConversationContext';
import { Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { BsSearch } from 'react-icons/bs';

const SearchMessageSlide1 = ({ showSearchMessage, setShowSearchMessage, t, onClickSearchMessage }) => {
  const [loading, setLoading] = useState(true);
  const [searchStr, setSearchStr] = useState('');
  const [searchMessage, setSearchMessage] = useState([]);
  const [filteredMessage, setFilteredMessage] = useState([]);
  const { getSearchMessageList } = useConversation();

  useEffect(() => {
    console.log(`showSearchMessage : ${showSearchMessage}`);
    // update markedList
    if (showSearchMessage) {
      setLoading(false);
      setSearchStr('');
      setFilteredMessage([]);
    }
  }, [showSearchMessage]);

  useEffect(() => {
    console.log(`searching ... ${searchStr}`);
    setLoading(true);
    // delay call list
    const timeOutId = setTimeout(async () => {
      try {
        const res = await getSearchMessageList(searchStr, '1970-01-01');

        console.log(`update Search message...`);
        console.log(res);
        if (res?.data?.data) setSearchMessage(res.data.data);
        setLoading(false);
      } catch (error) {
        setSearchMessage([]);
        setLoading(false);
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [searchStr]);

  useEffect(() => {
    var filter = searchMessage.filter(value => value.text?.body.includes(searchStr));
    if (searchStr == '') {
      filter = [];
    }
    setFilteredMessage(filter);
  }, [searchMessage]);

  const renderMessage = message => {
    var msg = '';
    if (message.type == 'text') {
      msg = message.text?.body;
    } else {
      msg = message.text?.body.replace('<br>', ' ');
    }
    return msg;
  };

  return (
    <div
      className="conversation-utility-sidebar"
      style={{
        display: showSearchMessage ? 'flex' : 'none',
        flexDirection: 'column',
        flex: '0 0 480px',
        overflow: 'hidden',
        background: '#ffffff',
      }}
    >
      {/* Topbar */}
      <div style={{ backgroundColor: '#f0f2f5', display: 'flex', alignItems: 'center', padding: '16px' }}>
        <svg
          stroke="#54656f"
          fill="#54656f"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1.4em"
          width="1.4em"
          xmlns="http://www.w3.org/2000/svg"
          onClick={e => setShowSearchMessage(false)}
        >
          <path fill="none" stroke="#54656f" strokeWidth="2" d="M3,3 L21,21 M3,21 L21,3"></path>
        </svg>
        <div style={{ paddingLeft: '12px', fontSize: '16px', color: '#54656f' }}>{t('search_message')}</div>
      </div>
      {/* Search bar */}
      {/* <div style={{ position: 'relative', padding: '0px 12px 12px 12px', backgroundColor: '#f0f2f5' }}>
        <BsSearch style={{ position: 'absolute', left: '20px', zIndex: 100 }} />
        <input
          type="search"
          className="searchMessageInput form-control"
          onChange={e => setSearchStr(e.target.value)}
          value={searchStr}
        />
      </div> */}
      <div
        className="input-group position-relative d-inline-flex align-items-center"
        style={{ padding: '0px 12px 12px 12px', backgroundColor: '#f0f2f5' }}
      >
        <BsSearch style={{ position: 'absolute', left: '22px', zIndex: 100 }} />
        <input
          className="form-control"
          type="text"
          style={{ borderRadius: '9px', paddingLeft: '30px', paddingRight: '30px' }}
          placeholder={`${t('search')}...`}
          name="search-conversation"
          value={searchStr}
          onChange={e => setSearchStr(e.target.value)}
        />
        {searchStr && (
          <svg
            style={{ position: 'absolute', right: '22px', cursor: 'pointer', zIndex: 100 }}
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            onClick={e => setSearchStr('')}
          >
            <path fill="none" stroke="#000" strokeWidth="2" d="M3,3 L21,21 M3,21 L21,3"></path>
          </svg>
        )}
      </div>
      <div className="search-slide-container" style={{ overflowY: 'auto' }}>
        {/* Main List of Marked Message */}
        {
          // Loading Spinner
          loading && (
            <div className="loading-marked-message">
              <ThreeDots
                height="50"
                width="50"
                radius="4"
                color="#54656f"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          )
        }

        {
          // have at least one record
          !loading &&
            filteredMessage.length > 0 &&
            filteredMessage.map((filteredMessage, index) => {
              return (
                <div key={`msg-search-${filteredMessage._id}`}>
                  <div className="search-slide-row" onClick={() => onClickSearchMessage(filteredMessage._id)}>
                    <div style={{ padding: '0 10px' }}>
                      <div>{dayjs(filteredMessage.timestamp).format(`YYYY-MM-DD hh:mm:ss`)}</div>
                      <div className="search-slide-text" key={`msg-search-${filteredMessage._id}`}>
                        {renderMessage(filteredMessage)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
        }

        {
          // no record
          !loading && filteredMessage.length == 0 && (
            <div style={{ padding: '16px', textAlign: 'center' }}>{t('no_result')}</div>
          )
        }
      </div>
    </div>
  );
};

export default withTranslation()(SearchMessageSlide1);
