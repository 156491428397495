import React,{useEffect,useState} from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector, useDispatch } from 'react-redux'
import { POST } from "../api/api-manager"
import { selectAuth, login, checkSession } from '../store/reducers/auth';
import { withTranslation } from 'react-i18next'

const Login = (props) => {
    const { t } = props;
    const dispatch = useDispatch();
    const authState = useSelector(selectAuth);

    const [organization, setOrganization] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = () => {
        let body={ organization, username, password };
        dispatch( POST(login,"users/login",body) );
    }

    // useEffect(()=>{
    //     console.log("authState",authState)
    // },[authState]);

  return (
    <>
        <Container fluid>
            <Row className="vh-100 align-items-center justify-content-center">
                <Col xs={3}>
                    <Form onSubmit={(e) => e.preventDefault()}>

                        <Container className="mb-3 text-center" fluid >
                            <Image src="logo.png" fluid />
                        </Container>

                        <div style={{textAlign:'center', color:'red'}}>{authState.loginErrorMsg}</div>
                        
                        <Form.Group className="mb-3" controlId="organization">
                            <Form.Label>{t('organization')}</Form.Label>
                            <Form.Control 
                                type="text"
                                // placeholder="Enter organization" 
                                value={organization}
                                onChange={(e) => setOrganization(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="username">
                            <Form.Label>{t('username')}</Form.Label>
                            <Form.Control 
                                type="text"
                                // placeholder="Enter username" 
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>{t('password')}</Form.Label>
                            <Form.Control 
                                type="password" 
                                // placeholder="Password" 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Button variant="primary" type="button" onClick={() => handleSubmit()}>
                            {t('sign_in')}
                        </Button>
                        {/* <Button variant="primary" type="button" onClick={() => dispatch( POST(checkSession,"users/check_session",{}))}>
                            Check Session
                        </Button> */}
                    </Form>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default withTranslation()(Login)