import React, { useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import { POST, GET, PATCH, DELETE } from "../api/api-manager";
import { changePassword } from "../store/reducers/user";
import PageTitle from "../_component/PageTitle";
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next'
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';

const ChangePassword1 = (props) => {
  const { t } = props;
  const dispatch = useDispatch();

  const oldPwRef = useRef();
  const newPwRef = useRef();
  const confirmPwRef = useRef();

  const appSettings = useContext(AppSettings);

  const onFormSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    var error = false;

    for (let [key, value] of formData.entries()) {
      // console.log(key, value);
      // check empty
      if (key == "oldPw" || key == "newPw" || key == "confirmPw") {
        if (value == "" || value == null || value == undefined) {
          // toast("Please fill in all fields");
          Store.addNotification({
            message: "Please fill in all fields",
            type: 'danger',
            insert: "top",
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 5000 },
          });
          return;
        }
      }
    }

    if (formData.get("newPw") != formData.get("confirmPw")) {
      // toast("Please confirm password");
      Store.addNotification({
        message: "Please confirm password",
        type: 'danger',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
      return;
    }

    let request_user = {
      oldPw: formData.get("oldPw"),
      newPw: formData.get("newPw"),
      confirmPw: formData.get("confirmPw"),
    };

    dispatch(PATCH(changePassword, "users/change_password", request_user));
  };

  // return (
  //   <Container>
  //     <PageTitle title={t("update_password")} lastPage={false} />

  //     <Form style={{ padding: 10 }} onSubmit={onFormSubmit} id="userForm">
  //       <Row className="mb-3">
  //         <Form.Group as={Col} controlId="formGridOldPw">
  //           <Form.Label>{t("old_password")}</Form.Label>
  //           <Form.Control
  //             type="password"
  //             placeholder={t("old_password")}
  //             ref={oldPwRef}
  //             name="oldPw"
  //           />
  //         </Form.Group>
  //       </Row>

  //       <Row className="mb-3">
  //         <Form.Group as={Col} controlId="formGridNewPw">
  //           <Form.Label>{t("new_password")}</Form.Label>
  //           <Form.Control
  //             type="password"
  //             placeholder={t("new_password")}
  //             ref={newPwRef}
  //             name="newPw"
  //           />
  //         </Form.Group>
  //       </Row>

  //       <Row className="mb-3">
  //         <Form.Group as={Col} controlId="formGridConfirmPw">
  //           <Form.Label>{t("confirm_password")}</Form.Label>
  //           <Form.Control
  //             type="password"
  //             placeholder={t("confirm_password")}
  //             ref={confirmPwRef}
  //             name="confirmPw"
  //           />
  //         </Form.Group>
  //       </Row>

  //       <Row className="mb-3">
  //         <Form.Group as={Col}>
  //           <Button variant="primary" type="submit">
  //             {t("submit")}
  //           </Button>
  //         </Form.Group>
  //       </Row>
  //     </Form>
  //   </Container>
  // );

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //     appSettings.handleSetAppSidebarNone(false);
    //     appSettings.handleSetAppHeaderNone(false);
    //     appSettings.handleSetAppContentClass('');
    // }
}, [appSettings])

  return (
    <div className="login login-v1">
      <div className="login-container">
        <div className="login-header">
          <div className="brand">
            <div className="d-flex align-items-center">
              {t("update_password")}
            </div>
          </div>
          <div className="icon">
            <i className="fa fa-lock"></i>
          </div>
        </div>
        <div className="login-body">
          <div className="login-content fs-13px">
            <form onSubmit={onFormSubmit}>
              <div className="form-floating mb-20px">
                <input
                  type="password"
                  className="form-control fs-13px h-45px"
                  name="oldPw"
                  ref={oldPwRef}
                />
                <label htmlFor="organization" className="d-flex align-items-center py-0">
                  {t('old_password')}
                </label>
              </div>
              <div className="form-floating mb-20px">
                <input
                  type="password"
                  className="form-control fs-13px h-45px"
                  name="newPw"
                  ref={newPwRef}
                />
                <label htmlFor="username" className="d-flex align-items-center py-0">
                  {t('new_password')}
                </label>
              </div>
              <div className="form-floating mb-20px">
                <input
                  type="password"
                  className="form-control fs-13px h-45px"
                  name="confirmPw"
                  ref={confirmPwRef}
                />
                <label htmlFor="password" className="d-flex align-items-center py-0">
                  {t('confirm_password')}
                </label>
              </div>
              <div className="login-buttons">
                <button type="submit" className="btn h-45px btn-primary d-block w-100 btn-lg">
                  {t("submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ChangePassword1);
