import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import { POST, GET, PATCH, DELETE } from "../api/api-manager";
import { changePassword, updateProfilePic, getProfilePic, removeProfilePic } from "../store/reducers/user";
import PageTitle from "../_component/PageTitle";
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next'
import Avatar from 'react-avatar-edit'
import {
  selectUser
} from "../store/reducers/user";

const UpdateProfile = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const {
    profile_pic,
    update_profile_pic
  } = useSelector(selectUser);

  const oldPwRef = useRef();
  const newPwRef = useRef();
  const confirmPwRef = useRef();

  const [profileSrc, setProfileSrc] = useState("");
  const [preview, setPreview] = useState(null);
  const [backUpCurrentPic, setBackUpCurrentPic] = useState(null);
  const [editingProfilePic, setEditingProfilePic] = useState(false);

  useEffect(() => {
    setProfileSrc("");
    setEditingProfilePic(false);
    dispatch(GET(getProfilePic, "users/profile_pic", {}));
  }, [update_profile_pic]);
  
  useEffect(() => {
    setPreview(profile_pic);
    setBackUpCurrentPic(profile_pic);
    if(profile_pic == null){
      setEditingProfilePic(true);
    }
  }, [profile_pic]);

  const onCrop = function(onCropPreview){
    setPreview(onCropPreview)
    console.log('onCrop')
  };

  const onClose = function(){
    if(backUpCurrentPic != null){
      setPreview(backUpCurrentPic)
    }else{
      setPreview(null)
    }
    console.log('onCrop')
  };

  const onBeforeFileLoad = function(){
    console.log('onBeforeFileLoad')
  };

  const updateIcon = function(){
    dispatch(PATCH(updateProfilePic, "users/update_profile_pic", {profile_pic: preview}));
    console.log(preview)
    console.log('updateIcon')
  };

  const removeProfilePicFunction = () => {
    dispatch(DELETE(removeProfilePic, "users/remove_profile_pic", {}));
  };

  const onFormSubmit = (event) => {
    // console.log(usernameRef.current.value)
    // console.log(roleRef.current.value)
    const formData = new FormData(event.currentTarget);
    // console.log(event.target.elements.username.value)

    event.preventDefault();

    var error = false;

    for (let [key, value] of formData.entries()) {
      // console.log(key, value);
      // check empty
      if (key == "oldPw" || key == "newPw" || key == "confirmPw") {
        if (value == "" || value == null || value == undefined) {
          toast("Please fill in all fields");
          return;
        }
      }
    }

    if (formData.get("newPw") != formData.get("confirmPw")) {
      toast("Please confirm password");
      return;
    }

    let request_user = {
      oldPw: formData.get("oldPw"),
      newPw: formData.get("newPw"),
      confirmPw: formData.get("confirmPw"),
    };

    dispatch(PATCH(changePassword, "users/change_password", request_user));
  };

  return (
    <Container>
      <PageTitle title={t("update_profile")} lastPage={false} />
      <Row className="mb-3">
        <Col>
            {
              (backUpCurrentPic && !editingProfilePic) ? (
                <img width={300} height={300} src={backUpCurrentPic} alt="Preview" />
              ) : (
                <Avatar
                  exportAsSquare
                  imageWidth={300}
                  imageHeight={300}
                  onCrop={onCrop}
                  onClose={onClose}
                  onBeforeFileLoad={onBeforeFileLoad}
                  src={null}
                  label={t('select_a_file')}
                />      
              )
            }
          
        </Col>
        {/* <Col className="align-self-center text-center">
          {backUpCurrentPic != null && (<img src={backUpCurrentPic} alt="Preview" />)}
        </Col> */}
      </Row>
      <Row className="mb-3">
          <Form.Group as={Col}>
            { !editingProfilePic && (<Button variant="primary" className="m-1" onClick={() => setEditingProfilePic(true)}>
              {t("update_profile_pic")}
            </Button>
            )}
            { (editingProfilePic && backUpCurrentPic != null) && (<Button variant="secondary" className="m-1" onClick={() => setEditingProfilePic(false)}>
              {t("cancel")}
            </Button>
            )}
            { (!editingProfilePic && backUpCurrentPic) && (<Button variant="danger" className="m-1" onClick={removeProfilePicFunction}>
              {t("remove_profile_pic")}
              </Button>
            )}
          </Form.Group>
          {/* {backUpCurrentPic && (
            <Form.Group as={Col}>
              <Button variant="danger" onClick={removeProfilePicFunction}>
                {t("remove_profile_pic")}
              </Button>
            </Form.Group>
          )} */}
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col}>
          { editingProfilePic && (
            <Button variant="primary" className="m-1" onClick={updateIcon}>
              {t("upload")}
            </Button>
          )}
        </Form.Group>
      </Row>
      <PageTitle title={t("update_password")} lastPage={false} />
      <Form style={{ padding: 10 }} onSubmit={onFormSubmit} id="userForm">
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridOldPw">
            <Form.Label>{t("old_password")}</Form.Label>
            <Form.Control
              type="password"
              placeholder={t("old_password")}
              ref={oldPwRef}
              name="oldPw"
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridNewPw">
            <Form.Label>{t("new_password")}</Form.Label>
            <Form.Control
              type="password"
              placeholder={t("new_password")}
              ref={newPwRef}
              name="newPw"
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridConfirmPw">
            <Form.Label>{t("confirm_password")}</Form.Label>
            <Form.Control
              type="password"
              placeholder={t("confirm_password")}
              ref={confirmPwRef}
              name="confirmPw"
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Button variant="primary" type="submit">
              {t("submit")}
            </Button>
          </Form.Group>
        </Row>
      </Form>
    </Container>
  );
};

export default withTranslation()(UpdateProfile);
