import React, { useEffect, useState, useMemo, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import { POST, GET, PATCH, DELETE } from "../api/api-manager";
import {
  getDefaultMessageList,
  setDefaultMessage,
  addAutoReplyMessage,
  updateAutoReplyMessage,
  deleteAutoReplyMessage,
} from "../store/reducers/messages";
import { selectMessage } from "../store/reducers/messages";
import Table from "react-bootstrap/Table";
import CustomModal from "../_component/CustomModal";
import CustomModal1 from "../_component/CustomModal1";
import MsgRow from "../_component/MsgRow";
import EmojiPicker from "emoji-picker-react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { BsEmojiSmile } from "react-icons/bs";
import PageTitle from "../_component/PageTitle";
import CustomPagination from "../_component/Pagination";
import { withTranslation } from 'react-i18next'

const AutoReplyMessages = (props) => {
  const { t } = props;
  const dispatch = useDispatch();
  const {
    autoReplyMessageList,
    autoReplyPaginationParams,
    loading,
  } = useSelector(selectMessage);

  // Default messages list
  const [autoReplyMessageListState, setAutoReplyMessageListState] = useState(
    []
  );

  // Add/Edit modal
  const [cursorPosition, setCursorPosition] = useState();
  const [showEmojiBox, setShowEmojiBox] = useState(false);
  const [addEditModalState, setAddEditModalState] = useState(false);
  const [addEditModalTypeState, setAddEditModalTypeState] = useState(null);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentEditMessage, setCurrentEditMessage] = useState({});

  const [page, setPage] = useState(1);
  const [displayPage, setDisplayPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState({
    totalDocs: 0,
    limit: 2,
    page: 1,
    totalPages: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  });

  const msgRef = useRef();

  useEffect(() => {
    let reqPaginationParams = Object.assign({}, paginationParams);
    reqPaginationParams.page = page;
    dispatch(
      POST(getDefaultMessageList, "users/get_all_auto_reply_message", {
        paginationParams: reqPaginationParams,
      })
    );
  }, [page]);

  useEffect(() => {
    if (!loading) setDisplayPage(page);
  }, [loading]);

  useEffect(() => {
    setAutoReplyMessageListState(autoReplyMessageList);
    setPaginationParams(autoReplyPaginationParams);
    setPage(autoReplyPaginationParams.page);
  }, [autoReplyMessageList]);

  const onToggleDefaultMessage = (message_id) => {
    let reqPaginationParams = Object.assign({}, paginationParams);
    reqPaginationParams.page = page;
    dispatch(
      PATCH(setDefaultMessage, "users/set_default_auto_reply_message", {
        message_id,
        paginationParams: reqPaginationParams,
      })
    );
  };

  const openEditModal = (type, message = {}) => {
    if (type != "Add") {
      setCurrentEditMessage(message);
      setCurrentMessage(message.message);
    }
    setAddEditModalState(true);
    setAddEditModalTypeState(type);
  };

  const deleteMessage = (message) => {
    let reqPaginationParams = Object.assign({}, paginationParams);
    reqPaginationParams.page = page;
    dispatch(
      POST(deleteAutoReplyMessage, `users/delete_auto_reply_message`, {
        message_id: message._id,
        paginationParams: reqPaginationParams,
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ref = msgRef.current;
    let reqPaginationParams = Object.assign({}, paginationParams);
    reqPaginationParams.page = page;
    if (addEditModalTypeState == "Add") {
      dispatch(
        POST(addAutoReplyMessage, "users/add_auto_reply_message", {
          message: ref.value,
          paginationParams: reqPaginationParams,
        })
      );
    } else {
      dispatch(
        PATCH(updateAutoReplyMessage, "users/update_auto_reply_message", {
          message: ref.value,
          message_id: currentEditMessage._id,
          paginationParams: reqPaginationParams,
        })
      );
    }
    setAddEditModalState(false);
    ref.value = "";
    setCurrentMessage("");
  };

  const addEmoji = ({ emoji }) => {
    const ref = msgRef.current;
    ref.focus();
    const start = ref.value.substring(0, ref.selectionStart);
    const end = ref.value.substring(ref.selectionStart);
    const text = start + emoji + end;

    ref.value = text;
    setCurrentMessage(text);
    setCursorPosition(start.length + emoji.length);
  };

  const editModal = useMemo(() => {
    return (
      <CustomModal1
        showModal={addEditModalState}
        setShowModal={setAddEditModalState}
        modal_title={ t("auto_reply_message")}
        onSubmit={() => {
          //updateAgent();
        }}
        centered={true}
        onHideFunction={() => setCurrentMessage("")}
        showSubmitButton={false}
      >
        <div className="my-2">
          <MsgRow
            key={1}
            rowKey={1}
            type={"send"}
            msg={currentMessage}
            time={"2020-01-01T09:00:00.000Z"}
          />
        </div>
        <div className="d-flex flex-column">
          <div className={showEmojiBox ? "d-block" : "d-none"}>
            <EmojiPicker
              width={"100%"}
              searchPlaceholder={ t("search_emoji_here")}
              lazyLoadEmojis={true}
              emojiStyle="apple"
              autoFocusSearch={false}
              previewConfig={{ showPreview: false }}
              onEmojiClick={addEmoji}
            />
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <InputGroup className="mb-3">
                <div className="px-1 d-flex flex-column justify-content-center align-items-center">
                  <button
                    className="btn btn-success"
                    onClick={(e) => {
                      e.preventDefault();
                      msgRef.current.focus();
                      setShowEmojiBox(!showEmojiBox);
                    }}
                  >
                    <BsEmojiSmile />
                  </button>
                </div>

                <Form.Control
                  as="textarea"
                  required
                  ref={msgRef}
                  id="messageTextArea"
                  placeholder={ t("type_message")}
                  style={{ height: "75px", resize: "none" }}
                  onChange={(event) => {
                    setCurrentMessage(event.target.value);
                  }}
                  value={currentMessage}
                />

                <Button type={"submit"}>{addEditModalTypeState}</Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
      </CustomModal1>
    );
  });

  return (
    <>
      <Container>
        <PageTitle title="Auto Reply Message Management" lastPage={false} />

        <Row className="my-2">
          <Col>
            <Button variant="success" onClick={() => openEditModal("Add")}>
            { t("add_message")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{ t("default")}</th>
                  <th>{ t("message")}</th>
                  <th>{ t("action")}</th>
                </tr>
              </thead>
              <tbody>
                {autoReplyMessageListState.map((message, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {displayPage && ((displayPage - 1) * paginationParams.limit + 1 + index)}
                      </td>
                      <td>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label=""
                          checked={message.default}
                          onChange={() => onToggleDefaultMessage(message._id)}
                        />
                      </td>
                      <td>{message.message}</td>
                      <td>
                        <Button
                          variant="info"
                          className="m-1"
                          onClick={() => openEditModal("Edit", message)}
                        >
                          { t("edit")}
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => deleteMessage(message)}
                        >
                          { t("delete")}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <CustomPagination
            paginationParams={paginationParams}
            setPage={setPage}
          />
        </Row>
        {/* Modal for edit message */}
        {editModal}
      </Container>
    </>
  );
};

export default withTranslation()(AutoReplyMessages);
