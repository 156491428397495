import { Row } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

const PageTitle = (props) => {
  return (
    <Row className="my-2">
      <div style={{ display: "flex", textAlign: "center" }}>
        {props.lastPage && (
          <Link to={props.lastPage}>
            <BsArrowLeft
              color="royalblue"
              size={20}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                cursor: "pointer",
              }}
            />
          </Link>
        )}

        <h3
          style={{
            paddingLeft: props.lastPage && 30,
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {props.title}
        </h3>
      </div>
    </Row>
  );
};

export default PageTitle;
