import { takeEvery } from "redux-saga/effects";
import { sagaBuild } from "./common";
import * as BusinessReducerrs from "../reducers/business";

function* getBusinessListCaller({ payload }) {
    yield sagaBuild({ payload, successType: BusinessReducerrs.getBusinessPhoneListSuccess.type, failType: BusinessReducerrs.getBusinessPhoneListFail.type });
}

export default function* conversation() {
    yield takeEvery(BusinessReducerrs.getBusinessPhoneList.type, getBusinessListCaller);
}