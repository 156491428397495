// roomList => List of Room for customer in ConversationBar.js
// messageList => List of message for Customer Room  in ConversationWindow.js

import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getPagingObject } from "../../utils";
import { Store } from 'react-notifications-component';

export const customersSlice = createSlice({
  name: "customers",
  initialState: {
    customerList: [],
    customerPaginationParams: {},
    loading: false,
  },
  reducers: {
    getCustomerList(state) {
      state.loading = true;
    },
    getCustomerListSuccess(state, payload) {
      state.loading = false;
      let responseData = payload.resData.data.data;
      state.customerList = responseData.docs;
      state.customerPaginationParams = getPagingObject(responseData);
    },
    getCustomerListFail(state) {
      state.loading = false;
    },
    addCustomer(state) {
      state.loading = true;
    },
    addCustomerSuccess(state, payload) {
      state.loading = false;
      // toast(payload.resData.data.message)
      Store.addNotification({
        message: payload.resData.data.message,
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
      let responseData = payload.resData.data.data;
      state.customerList = responseData.docs;
      state.customerPaginationParams = getPagingObject(responseData);
    },
    addCustomerFail(state, error) {
      state.loading = false;
      toast(error.error.response.data.message)
      Store.addNotification({
        message: error.error.response.data.message,
        type: 'danger',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
    },
    updateCustomer(state) {
      state.loading = true;
    },
    updateCustomerSuccess(state, payload) {
      state.loading = false;
      // toast(payload.resData.data.message)
      Store.addNotification({
        message: payload.resData.data.message,
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
    });
      let responseData = payload.resData.data.data;
      state.customerList = responseData.docs;
      state.customerPaginationParams = getPagingObject(responseData);
    },
    updateCustomerFail(state, error) {
      state.loading = false;
      toast(error.error.response.data.message)
      Store.addNotification({
        message: error.error.response.data.message,
        type: 'danger',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
    },
    deleteCustomer(state) {
      state.loading = true;
    },
    deleteCustomerSuccess(state, payload) {
      state.loading = false;
      // toast(payload.resData.data.message)
      Store.addNotification({
        message: payload.resData.data.message,
        type: 'success',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
    });
      let responseData = payload.resData.data.data;
      state.customerList = responseData.docs;
      state.customerPaginationParams = getPagingObject(responseData);
    },
    deleteCustomerFail(state, error) {
      state.loading = false;
      toast(error.error.response.data.message)
    },
  },
});

export const selectCustomers = (state) => state.customers;
export const {
  getCustomerList,
  getCustomerListSuccess,
  getCustomerListFail,
  addCustomer,
  addCustomerSuccess,
  addCustomerFail,
  updateCustomer,
  updateCustomerSuccess,
  updateCustomerFail,
  deleteCustomer,
  deleteCustomerSuccess,
  deleteCustomerFail,
} = customersSlice.actions;

export default customersSlice.reducer;
