import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { apiGenerator } from '../api/api-manager';
import { withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';

const AddCustomer1 = props => {
  const { t } = props;

  const appSettings = useContext(AppSettings);
  const [editCustomer, setEditCustomer] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const nameRef = useRef();
  const phoneNumberRef = useRef();

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    // };
  }, [appSettings]);

  useEffect(() => {
    if (location.state?.customer) {
      setEditCustomer(location.state.customer);
      setEditMode(true);
    }
  }, [location]);

  const onFormSubmit = event => {
    const formData = new FormData(event.currentTarget);

    const submitted_checkedbox = [];
    document.querySelectorAll(':checked').forEach(ele => {
      if (ele.value == 'on') {
        submitted_checkedbox.push(ele.id);
      }
    });
    event.preventDefault();

    var error = false;

    for (let [key, value] of formData.entries()) {
      // console.log(key, value);
      // check empty
      if (key == 'name' || key == 'phoneNumber') {
        if (value == '' || value == null || value == undefined) {
          error = true;
        }
      }
    }

    if (error) {
      // alert(`Please filled in required information`);
      Store.addNotification({
        message: `Please filled in required information`,
        type: 'danger',
        insert: "top",
        container: 'top-right',
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
    } else {
      let requestBody = {
        profile_name: formData.get('name'),
        phone_number: formData.get('phoneNumber'),
      };
      console.log(requestBody);

      if (editMode) {
        apiGenerator({
          method: 'PATCH',
          path: `customers/${editCustomer._id}`,
          body: requestBody,
        }).then(res => {
          // alert(res.data.message);
          Store.addNotification({
            message: res.data.message,
            type: 'success',
            insert: "top",
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 5000 },
          });
          if (res.status === 200) {
            navigate('/customers');
          }
        }).catch(function (error) {
          if (error.response.data.message) {
            // alert(error.response.data.message);
            Store.addNotification({
              message: error.response.data.message,
              type: 'danger',
              insert: "top",
              container: 'top-right',
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: { duration: 5000 },
            });
          }
        });
      } else {
        apiGenerator({
          method: 'POST',
          path: 'customers/create',
          body: requestBody,
        }).then(res => {
          // alert(res.data.message);
          Store.addNotification({
            message: res.data.message,
            type: 'success',
            insert: "top",
            container: 'top-right',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 5000 },
          });
          if (res.status === 200) {
            navigate('/customers');
          }
        }).catch(function (error) {
          if (error.response.data.message) {
            // alert(error.response.data.message);
            Store.addNotification({
              message: error.response.data.message,
              type: 'danger',
              insert: "top",
              container: 'top-right',
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: { duration: 5000 },
            });
          }
        });;
      }
    }
  };

  return (
    <Container>
      <div className="d-flex align-items-center">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={'/customers'}>{t('customer_management')}</Link>
            </li>
            <li className="breadcrumb-item active">{editMode ? t('edit_customer') : t('add_customer')}</li>
          </ol>
          <h1 className="page-header">{editMode ? t('edit_customer') : t('add_customer')}</h1>
        </div>
      </div>

      <div className="row gx-4">
        <div className="col-lg-12">
          <Form onSubmit={onFormSubmit} id="customerForm">
            <div className="card border-0 mb-4">
              <div className="card-body p-3 text-dark fw-bold">
                <div className="row">
                  <div className="col-sm-12">
                    <fieldset>
                      <div className="row mb-3">
                        <div className="col-sm-6">
                          <label className="form-label" htmlFor="name">
                            {t('name')}
                          </label>
                          <input
                            ref={nameRef}
                            type="text"
                            className="form-control"
                            name="name"
                            defaultValue={editMode ? editCustomer.profile_name : ''}
                          />
                        </div>
                        <div className="col-sm-6">
                          <label className="form-label" htmlFor="phoneNumber">
                            {t('phone_number')}
                          </label>
                          <input
                            ref={phoneNumberRef}
                            type="text"
                            className="form-control"
                            name="phoneNumber"
                            defaultValue={editMode ? editCustomer.phone_number : ''}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="card-footer bg-none d-flex p-3">
                <button type="submit" className="btn btn-primary w-100px ms-auto">
                  {t('submit')}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default withTranslation()(AddCustomer1);
