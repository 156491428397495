import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { memo } from "react";

//
// modal_detail
// {
//     title: '',
//     body: <Component>
// }

function CustomModal({
  modal_title = "Title",
  size = undefined,
  children,
  onSubmit,
  showModal,
  setShowModal,
  centered = false,
  onHideFunction = () => {},
  showSubmitButton = true,
}) {
  // const [show, setShow] = useState(false);
  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    onHideFunction();
    setShowModal(false);
  };
  const handleSubmit = () => {
    try{
      onSubmit();
      handleClose();
    }catch(e){
      console.log(e)
    }
    
  };

  return (
    <Modal
      size={size}
      show={showModal}
      onHide={handleClose}
      dialogClassName="message-template-modal"
      centered={centered}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modal_title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {showSubmitButton && (
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default memo(CustomModal);
