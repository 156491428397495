import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { Store } from 'react-notifications-component';

export const userSlice = createSlice({
    name: "user",
    initialState: {
        autoReply: false,
        profile_pic: null,
        update_profile_pic: false
    },
    reducers: {
        setAutoReply(state){

        },
        setAutoReplySuccess(state, payload){
            let responseData = payload.resData.data;
            state.autoReply = responseData.data
        },
        setAutoReplyFail(state){

        },
        getAutoReply(state){
            // state.autoReply = false
        },
        getAutoReplySuccess(state, payload){
            let responseData = payload.resData.data;
            state.autoReply = responseData.data
        },
        getAutoReplyFail(state){

        },
        changePassword(state){
            // state.autoReply = false
        },
        changePasswordSuccess(state, payload){
            // toast(payload.resData.data.message)
            Store.addNotification({
                message: payload.resData.data.message,
                type: 'success',
                insert: "top",
                container: 'top-right',
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 5000 },
            });
        },
        changePasswordFail(state, error){
            // toast(error.error.response.data.message)
            Store.addNotification({
                message: error.error.response.data.message,
                type: 'danger',
                insert: "top",
                container: 'top-right',
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: { duration: 5000 },
            });
        },
        updateProfilePic(state){
            // state.autoReply = false
        },
        updateProfilePicSuccess(state, payload){
            toast(payload.resData.data.message)
            state.update_profile_pic = !state.update_profile_pic;
        },
        updateProfilePicFail(state, error){
            toast(error.error.response.data.message)
        },
        getProfilePic(state){
            // state.autoReply = false
        },
        getProfilePicSuccess(state, payload){
            let responseData = payload.resData.data;
            state.profile_pic = responseData.data
        },
        getProfilePicFail(state, error){

        },
        removeProfilePic(state){
            // state.autoReply = false
        },
        removeProfilePicSuccess(state, payload){
            toast(payload.resData.data.message);
            state.update_profile_pic = !state.update_profile_pic;
        },
        removeProfilePicFail(state, error){

        },
    },
});

export const selectUser = (state) => state.user;
export const {
    setAutoReply,
    setAutoReplySuccess,
    setAutoReplyFail,
    getAutoReply,
    getAutoReplySuccess,
    getAutoReplyFail,
    changePassword,
    changePasswordSuccess,
    changePasswordFail,
    updateProfilePic,
    updateProfilePicSuccess,
    updateProfilePicFail,
    getProfilePic,
    getProfilePicSuccess,
    getProfilePicFail,
    removeProfilePic,
    removeProfilePicSuccess,
    removeProfilePicFail,
} = userSlice.actions;

export default userSlice.reducer;