import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { POST, GET, PATCH, apiGenerator } from '../api/api-manager';
import { getAgentGroupList, getParentAgentGroupList } from '../store/reducers/agentGroups';
import { selectAgentGroups, addAgentGroup, updateAgentGroup, deleteAgentGroup } from '../store/reducers/agentGroups';
import Table from 'react-bootstrap/Table';
import CustomModal from '../_component/CustomModal';
import CustomModal1 from '../_component/CustomModal1';
import { Button, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PageTitle from '../_component/PageTitle';
import CustomPagination from '../_component/Pagination';
import CustomPagination1 from '../_component/Pagination1';
import { withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { usePermissions } from '../_component/context/AuthContext';
import { useDebounce } from '../_component/hooks/useDebounce';

const getAgentGroupCountLimit = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'GET',
      path: `client/agent-group-count-limit`,
      body: {},
    }).then(res => {
      return resolve(res?.data ? res.data : undefined);
    });
  });
};

const renderAssociatedGroup = (t, agentGroup) => {
  if (agentGroup.parent_group_id === null && agentGroup.sub_groups.length > 0) {
    // Parent group
    let html = [];

    html.push(<b>{`${t('second_level_agent_groups')}${t('symbol_colon')}`}</b>);

    html.push(<ul>
      {agentGroup.sub_groups.map((group, index) => {
        return <li key={'sub_group_name_' + index}>{group.name}</li>;
      })}
    </ul>);

    return html;
  }

  if (agentGroup.parent_group_id !== null && agentGroup.parent_group.length > 0) {
    // Sub group
    let html = [];

    html.push(<b>{`${t('main_agent_group')}${t('symbol_colon')}`}</b>);

    html.push(<ul>
      {agentGroup.parent_group.map((group, index) => {
        return <li key={'sub_group_name_' + index}>{group.name}</li>;
      })}
    </ul>);

    return html;
  }

  return '(None)';
};

const AgentGroups1 = props => {
  const { t } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { permissions } = usePermissions();

  const appSettings = useContext(AppSettings);

  // Disable add agent group button
  const [disableAddAgentGroup, setDisableAddAgentGroup] = useState(true);

  // Search fields
  const [searchKeyword, setSearchKeyword] = useState('');
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 350);

  const { agentGroupList, agentGroupPaginationParams, loading, parentAgentGroupList } = useSelector(selectAgentGroups);

  // Default Group list
  const [agentGroupListState, setAgentGroupListState] = useState([]);

  // Add/Edit modal
  const [addEditModalState, setAddEditModalState] = useState(false);
  const [addEditModalTypeState, setAddEditModalTypeState] = useState(null);

  // Delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteAgentGroup, setSelectedDeleteAgentGroup] = useState(undefined);

  const [currentGroupName, setCurrentGroupName] = useState('');
  const [currentParentGroupId, setCurrentParentGroupId] = useState('');
  const [currentGroupType, setCurrentGroupType] = useState('parent');

  const [searchGroupType, setSearchGroupType] = useState('all');

  const [editGroupId, setEditGroupId] = useState(null);

  const [parentAgentGroupListState, setParentAgentGroupListState] = useState([]);

  const [page, setPage] = useState(1);
  const [displayPage, setDisplayPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState({
    totalDocs: 0,
    limit: 2,
    page: 1,
    totalPages: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  });

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    // };
  }, [appSettings]);

  useEffect(() => {
    toggleAgentGroupLimit();
  }, [])

  useEffect(() => {
    dispatch(GET(getParentAgentGroupList, 'user_groups/parent', {}));
  }, [agentGroupList]);

  useEffect(() => {
    let reqPaginationParams = Object.assign({}, paginationParams);
    if (page) {
      reqPaginationParams.page = page;
      dispatch(
        POST(getAgentGroupList, 'user_groups', {
          keyword: debouncedSearchKeyword,
          page: reqPaginationParams.page,
          limit: reqPaginationParams.limit,
          type: searchGroupType,
        })
      );
    }
  }, [debouncedSearchKeyword, page]);

  useEffect(() => {
    let reqPaginationParams = Object.assign({}, paginationParams);
    if (page) {
      reqPaginationParams.page = page;
      dispatch(
        POST(getAgentGroupList, 'user_groups', {
          page: 1,
          limit: reqPaginationParams.limit,
          type: searchGroupType,
        })
      );
    }
  }, [searchGroupType]);

  useEffect(() => {
    if (!loading) setDisplayPage(page);
  }, [loading]);

  useEffect(() => {
    setAgentGroupListState(agentGroupList);
    setPaginationParams(agentGroupPaginationParams);
    // setPage(agentGroupPaginationParams.page);
  }, [agentGroupList]);

  useEffect(() => {
    setParentAgentGroupListState(parentAgentGroupList);
    if (parentAgentGroupList[0]) {
      setCurrentParentGroupId(parentAgentGroupList[0]._id);
    }
  }, [parentAgentGroupList]);

  const toggleAgentGroupLimit = () => {
    getAgentGroupCountLimit().then(res => {
      if (isNaN(res?.data?.countLimit)) return setDisableAddAgentGroup(false);

      if (isNaN(res?.data?.count)) return setDisableAddAgentGroup(true);

      setDisableAddAgentGroup(res.data.count >= res.data.countLimit);
    });
  };

  const editGroup = (agentGroup = {}) => {
    navigate('/agent_groups/add', { state: { agentGroup: agentGroup } });
  };

  const onDeleteModalSubmit = () => {
    let reqPaginationParams = Object.assign({}, paginationParams);

    // Reset to page 1
    setPage(1);
    reqPaginationParams.page = 1;

    dispatch(
      POST(
        deleteAgentGroup,
        `user_groups/delete`,
        {
          id: selectedDeleteAgentGroup._id,
          page: reqPaginationParams.page,
          limit: reqPaginationParams.limit,
          type: searchGroupType,
        },
        {
          callback: (response) => {
            toggleAgentGroupLimit();
          }
        }
      )
    );
  }

  const handleDeleteAgentGroup = agentGroup => {
    setSelectedDeleteAgentGroup(agentGroup);
    setShowDeleteModal(true);
  };

  const handleSubmit = e => {
    e.preventDefault();
    let reqPaginationParams = Object.assign({}, paginationParams);
    reqPaginationParams.page = page;
    if (addEditModalTypeState == 'Add') {
      dispatch(
        POST(addAgentGroup, 'user_groups/create', {
          page: 1,
          limit: reqPaginationParams.limit,
          name: currentGroupName,
          parent_group_id: currentGroupType === 'subGroup' ? currentParentGroupId : null,
          type: searchGroupType,
        })
      );
    } else {
      dispatch(
        PATCH(updateAgentGroup, `user_groups/${editGroupId}`, {
          page: 1,
          limit: reqPaginationParams.limit,
          name: currentGroupName,
          parent_group_id: currentGroupType === 'subGroup' ? currentParentGroupId : null,
          type: searchGroupType,
        })
      );
    }

    setAddEditModalState(false);
    setCurrentGroupName('');
    setCurrentGroupType('parent');
    setEditGroupId(null);

    if (parentAgentGroupList[0]) {
      setCurrentParentGroupId(parentAgentGroupList[0]._id);
    }
  };

  const clearModal = () => {
    setAddEditModalState(false);
    setCurrentGroupName('');
    setCurrentGroupType('parent');
    setEditGroupId(null);
  };

  const editModal = useMemo(() => {
    return (
      <CustomModal1
        showModal={addEditModalState}
        setShowModal={setAddEditModalState}
        modal_title={t('agent_group')}
        onSubmit={() => {
          //updateAgent();
        }}
        centered={true}
        onHideFunction={() => clearModal()}
        showSubmitButton={false}
      >
        <div className="d-flex flex-column">
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridUsername">
                  <Form.Label>{t('group_name')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t('group_name')}
                    name="groupName"
                    value={currentGroupName}
                    onChange={e => {
                      setCurrentGroupName(e.target.value);
                    }}
                    // defaultValue={editMode ? editUser.username : ""}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridRole">
                  <Form.Label>{t('group_type')}</Form.Label>
                  <div>
                    <Form.Select
                      name="currentGroupType"
                      value={currentGroupType}
                      onChange={e => {
                        setCurrentGroupType(e.target.value);
                      }}
                      // value={editMode ? editUser.role_id : undefined}
                    >
                      <option value="parent">{t('main_agent_group')}</option>
                      <option value="subGroup">{t('second_level_agent_group')}</option>
                    </Form.Select>
                  </div>
                </Form.Group>
              </Row>
              {currentGroupType === 'subGroup' && (
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridRole">
                    <Form.Label>{t('main_agent_group')}</Form.Label>
                    <div>
                      <Form.Select
                        name="parentGroupId"
                        value={currentParentGroupId}
                        onChange={e => {
                          setCurrentParentGroupId(e.target.value);
                        }}
                      >
                        {parentAgentGroupListState.map(parentGroup => {
                          return (
                            <option value={`${parentGroup._id}`} key={parentGroup._id}>
                              {parentGroup.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </div>
                  </Form.Group>
                </Row>
              )}
              <InputGroup className="mb-3">
                <Button type={'submit'}>{addEditModalTypeState}</Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
      </CustomModal1>
    );
  });

  return (
    <>
      <Container>
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={'/'}>Home</Link>
              </li>
              <li className="breadcrumb-item active">{t('agent_group_management')}</li>
            </ul>
            <h1 className="page-header mb-0">{t('agent_group_management')}</h1>
          </div>
          <div className="ms-auto">
            {permissions?.add_agent_group && (
              <OverlayTrigger
                placement="left"
                overlay={
                    <Tooltip>
                        {t('max_agent_group_reached')}
                    </Tooltip>
                }
                show={disableAddAgentGroup ? undefined : false}
              >
                <Link to={'/agent_groups/add'} onClick={disableAddAgentGroup ? (e => e.preventDefault()) : undefined} style={disableAddAgentGroup ? { cursor: 'not-allowed' } : undefined}>
                  <Button variant="primary" className="px-4 btn-rounded rounded-pill" disabled={disableAddAgentGroup}>
                  <i className="fa fa-plus fa-lg me-2 ms-n2 text-white"></i> {t('add_agent_group')}
                  </Button>
                </Link>
              </OverlayTrigger>
            )}
          </div>
        </div>

        <div className="card border-0">
          <ul className="nav nav-tabs nav-tabs-v2 px-3">
            <li className="nav-item me-2">
            
              <a href="#" className={`nav-link px-2 ${searchGroupType === 'all' && 'active'}`} onClick={() => setSearchGroupType('all')}>
                {t('all')}
              </a>
            </li>
            <li className="nav-item me-2">
              <a href="#" className={`nav-link px-2 ${searchGroupType === 'groups' && 'active'}`} onClick={() => setSearchGroupType('groups')}>
                {t('main_agent_group')}
              </a>
            </li>
            <li className="nav-item me-2">
              <a href="#" className={`nav-link px-2 ${searchGroupType === 'sub_groups' && 'active'}`} onClick={() => setSearchGroupType('sub_groups')}>
                {t('second_level_agent_group')}
              </a>
            </li>
          </ul>
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              {/* Search bar */}
              <div div className="input-group mb-3">
                <div
                  className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                  style={{ zIndex: 10 }}
                >
                  <i className="fa fa-search opacity-5"></i>
                </div>
                <input
                  type="text"
                  className="form-control px-35px bg-light"
                  style={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
                  placeholder={t('search_name')}
                  value={searchKeyword}
                  onChange={e => {
                    setSearchKeyword(e.target.value);
                  }}
                />
              </div>
              
              {/* Table */}
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: '1%', whiteSpace: 'nowrap' }}>#</th>
                      {(permissions?.edit_agent_group || permissions?.delete_agent_group) && (
                        <th style={{ width: '1%', whiteSpace: 'nowrap' }}>{t('action')}</th>
                      )}
                      <th>{t('name')}</th>
                      <th>{t('group_type')}</th>
                      <th>{t('associated_groups')}</th>
                      <th>{t('agents')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentGroupListState.map((agentgroup, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ verticalAlign: 'super' }}>
                            {displayPage && (displayPage - 1) * paginationParams.limit + 1 + index}
                          </td>
                          {(permissions?.edit_agent_group || permissions?.delete_agent_group) && (
                            <td nowrap="true" className="with-btn" style={{ verticalAlign: 'super' }}>
                              {permissions?.edit_agent_group && (
                                <button
                                  className="btn btn-sm btn-primary w-60px me-1"
                                  onClick={() => editGroup(agentgroup)}
                                >
                                  {t('edit')}
                                </button>
                              )}
                              {permissions?.delete_agent_group && (
                                <button className="btn btn-sm btn-danger w-60px" onClick={() => handleDeleteAgentGroup(agentgroup)}>
                                  {t('delete')}
                                </button>
                              )}
                            </td>
                          )}
                          <td style={{ verticalAlign: 'super' }}>{agentgroup.name}</td>
                          <td style={{ verticalAlign: 'super' }}>
                            {agentgroup.parent_group_id === null ? t('main') : t('second_level')}
                          </td>
                          <td style={{ verticalAlign: 'super' }}>{renderAssociatedGroup(t, agentgroup)}</td>
                          <td style={{ verticalAlign: 'super' }}>
                            <ul>
                              {agentgroup.group_users.map((group_user, index) => {
                                return <li key={'group_user_name_' + index}>{group_user.name}</li>;
                              })}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="d-md-flex align-items-center">
                <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                  {t('pagination_info_1')} {(paginationParams.page - 1) * paginationParams.limit + 1} {t('pagination_info_2')}{' '}
                  {paginationParams.page * paginationParams.limit < paginationParams.totalDocs
                    ? paginationParams.page * paginationParams.limit
                    : paginationParams.totalDocs}{' '}
                  {t('pagination_info_3')} {paginationParams.totalDocs} {t('pagination_info_4')}
                </div>

                <CustomPagination1 paginationParams={paginationParams} setPage={setPage} />
              </div>
            </div>
          </div>
        </div>

        <CustomModal1
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          modal_title={t('delete_agent_group')}
          onSubmit={onDeleteModalSubmit}
          submitButtonText={t('confirm')}
        >
          <div className="alert alert-danger">
            <h5><i className="fa fa-info-circle"></i> {t('alert')}</h5>
            <p>{t('delete_confirmation_text')}</p>
          </div>
        </CustomModal1>
      </Container>
    </>
  );
};

export default withTranslation()(AgentGroups1);
