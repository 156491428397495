import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { POST, GET, PATCH } from '../api/api-manager';
import { getAgentGroupList, getParentAgentGroupList } from '../store/reducers/agentGroups';
import { selectAgentGroups, addAgentGroup, updateAgentGroup, deleteAgentGroup } from '../store/reducers/agentGroups';
import Table from 'react-bootstrap/Table';
import CustomModal from '../_component/CustomModal';
import CustomModal1 from '../_component/CustomModal1';
import { Button, Form, InputGroup } from 'react-bootstrap';
import PageTitle from '../_component/PageTitle';
import CustomPagination from '../_component/Pagination';
import CustomPagination1 from '../_component/Pagination1';
import { withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { apiGenerator } from '../api/api-manager';

function ceilTo1DecimalPlace(number) {
  return Math.ceil(number * 10) / 10;
}

function addThousandSeparator(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getSecondsToMinutes(seconds) {
  const minutes = Math.round(seconds / 60);
  return `${addThousandSeparator(minutes)}`;
}

const fetchReport = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'GET',
      path: `reports/conversation-report`,
      body: {},
    }).then(res => {
      console.log(res);
      return resolve(res?.data?.data ? res.data.data : undefined);
    });
  });
};

const ConversationReport = props => {
  const { t } = props;

  const appSettings = useContext(AppSettings);
  const [report, setReport] = useState([]);
  const [totalAverage, setTotalAverage] = useState({
    min: 0,
    max: 0,
    avg: 0,
  });

  // General UI settings (Template)
  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');
  }, [appSettings]);

  // Test effect
  useEffect(() => {
    (async () => {
      const newReport = await fetchReport();

      if (Array.isArray(newReport) && newReport.length > 0) {
        setReport(newReport);
        
        const newAverage = {
          min: 0,
          max: 0,
          avg: 0,
        };

        for (const value of newReport) {
          const first_message_time = value.first_message_time;

          newAverage.min += first_message_time.min;
          newAverage.max += first_message_time.max;
          newAverage.avg += first_message_time.avg;
        }

        newAverage.min /= newReport.length;
        newAverage.max /= newReport.length;
        newAverage.avg /= newReport.length;

        setTotalAverage(newAverage);
      }
    })();
  }, []);

  return (
    <>
      <Container>
        {/* Breadcrumb */}
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={'/'}>Home</Link>
              </li>
              <li className="breadcrumb-item active">{t('conversation_report')}</li>
            </ul>
            <h1 className="page-header mb-0">{t('conversation_report')}</h1>
          </div>
        </div>

        {/* Main content */}
        <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: '1%', whiteSpace: 'nowrap' }}>{t('customer')}</th>
                      <th style={{ textAlign: 'right' }}>{t('avg_first_response_time')}</th>
                      <th style={{ textAlign: 'right' }}>{t('min_first_response_time')}</th>
                      <th style={{ textAlign: 'right' }}>{t('max_first_response_time')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {report &&
                      report.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ verticalAlign: 'super' }}>
                              {value.customer.name} ({value.customer.phone_number})
                            </td>
                            <td style={{ textAlign: 'right', verticalAlign: 'super' }}>
                              <b>{getSecondsToMinutes(value.first_message_time.avg)}</b>{` ${t('minutes_short')}`}
                            </td>
                            <td style={{ textAlign: 'right', verticalAlign: 'super' }}>
                              <b>{getSecondsToMinutes(value.first_message_time.min)}</b>{` ${t('minutes_short')}`}
                            </td>
                            <td style={{ textAlign: 'right', verticalAlign: 'super' }}>
                              <b>{getSecondsToMinutes(value.first_message_time.max)}</b>{` ${t('minutes_short')}`}
                            </td>
                          </tr>
                        );
                      })}
                    <tr style={{ backgroundColor: 'rgb(0, 0, 0, 0.075)' }}>
                      <td style={{ verticalAlign: 'super' }}>
                        <b>{t('average')}</b>
                      </td>
                      <td style={{ textAlign: 'right', verticalAlign: 'super' }}><b>{getSecondsToMinutes(totalAverage.avg)}</b>{` ${t('minutes_short')}`}</td>
                      <td style={{ textAlign: 'right', verticalAlign: 'super' }}><b>{getSecondsToMinutes(totalAverage.min)}</b>{` ${t('minutes_short')}`}</td>
                      <td style={{ textAlign: 'right', verticalAlign: 'super' }}><b>{getSecondsToMinutes(totalAverage.max)}</b>{` ${t('minutes_short')}`}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default withTranslation()(ConversationReport);
