import { createContext, useContext, useEffect, useState } from 'react';

export const SystemInfoContext = createContext();

export function SystemInfoProvider({ children }) {
  const [isAllowUseOfChatGpt, setIsAllowUseOfChatGpt] = useState(false);

  useEffect(() => {
    console.log('useEffect on isAllowUseOfChatGpt', isAllowUseOfChatGpt);
  }, [isAllowUseOfChatGpt]);

  return (
    <SystemInfoContext.Provider
      value={{
        isAllowUseOfChatGpt,
        setIsAllowUseOfChatGpt,
      }}
    >
      {children}
    </SystemInfoContext.Provider>
  );
}

export function useSystemInfo() {
  return useContext(SystemInfoContext);
}
