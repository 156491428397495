import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { useConversation } from '../_component/context/ConversationContext';
import SelectionList from '../_component/SelectionList';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { getRoleList, getPermissionList, apiGenerator } from '../api/api-manager';
import { withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';

const AddUsers1 = props => {
  const { t } = props;

  const appSettings = useContext(AppSettings);
  const [roleList, setRoleList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedAgentGroups, setSelectedAgentGroups] = useState({}); // Indexed by agent ID

  const navigate = useNavigate();
  const location = useLocation();

  const nameRef = useRef();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const roleRef = useRef();

  const { agentGroupList, updateAgentGroupList } = useConversation();

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');

    // return () => {
    //   appSettings.handleSetAppSidebarNone(false);
    //   appSettings.handleSetAppHeaderNone(false);
    // };
  }, [appSettings]);

  useEffect(() => {
    getRoleList().then(res => {
      let resposnse = res.data;
      if (resposnse.status) {
        setRoleList(resposnse.params);
      }
    });

    getPermissionList().then(res => {
      let resposnse = res.data;
      if (resposnse.status) {
        setPermissionList(resposnse.params);
      }
    });

    // eslint-disable-next-line
    updateAgentGroupList();
  }, []);

  useEffect(() => {
    // Pre-selected the assigned agent group for the user.
    if (editUser === null || !Array.isArray(editUser.user_groups)) {
      // Not edit user or no selected user group.
      return;
    }

    const newSelectedAgentGroups = {};

    for (const agentGroup of agentGroupList) {
      if (!editUser.user_groups.includes(agentGroup._id)) {
        // Database agent groups does not include this agent group
        continue;
      }

      newSelectedAgentGroups[agentGroup._id] = agentGroup;
    }

    setSelectedAgentGroups(newSelectedAgentGroups);
  }, [agentGroupList, editUser]);

  useEffect(() => {
    if (location.state?.user) {
      setEditUser(location.state.user);
      setEditMode(true);
    }
  }, [location]);

  const onFormSubmit = event => {
    // console.log(usernameRef.current.value)
    // console.log(roleRef.current.value)
    const formData = new FormData(event.currentTarget);
    // console.log(event.target.elements.username.value)

    console.log({ event, formData });

    const submitted_checkedbox = [];
    document.querySelectorAll('input[type="checkbox"]:checked').forEach(ele => {
      if (ele.value == 'on') {
        submitted_checkedbox.push(ele.id);
      }
    });
    event.preventDefault();

    var error = false;

    for (let [key, value] of formData.entries()) {
      // console.log(key, value);
      // check empty
      if (key == 'username' || key == 'password' || key == 'name') {
        if (value == '' || value == null || value == undefined) {
          error = true;
        }
      }
    }

    if (error) {
      // alert(`Please filled in required information`);
      Store.addNotification({
        message: `Please filled in required information`,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: { duration: 5000 },
      });
    } else {
      let request_user = {
        name: formData.get('name'),
        username: formData.get('username'),
        password: formData.get('password'),
        role_id: formData.get('role_id'),
        permission: submitted_checkedbox,
        user_groups: Object.keys(selectedAgentGroups),
        auto_reply_messages: [],
        enable_auto_reply: false,
      };
      console.log(request_user);

      if (editMode) {
        apiGenerator({
          method: 'PATCH',
          path: `users/${editUser._id}`,
          body: request_user,
        }).then(res => {
          // alert(res.data.message);
          Store.addNotification({
            message: res.data.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 5000 },
          });
          if (res.data.status) {
            navigate('/users');
          }
        });
      } else {
        apiGenerator({
          method: 'POST',
          path: 'users/create',
          body: request_user,
        }).then(res => {
          // alert(res.data.message);
          Store.addNotification({
            message: res.data.message,
            type: res.data.status ? 'success' : 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: { duration: 5000 },
          });
          if (res.data.status) {
            navigate('/users');
          }
        });
      }
    }
  };

  return (
    <Container>
      <div className="d-flex align-items-center">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={'/users'}>{t('agent_management')}</Link>
            </li>
            <li className="breadcrumb-item active">{editMode ? t('edit_agent') : t('add_agent')}</li>
          </ol>
          <h1 className="page-header">{editMode ? t('edit_agent') : t('add_agent')}</h1>
        </div>
      </div>

      <Form onSubmit={onFormSubmit} id="agentForm">
        <div className="row gx-4">
          <div className="col-lg-12">
            <div className="card border-0 mb-4">
              <div className="card-body p-3 text-dark fw-bold">
                <div className="row">
                  <div className="col-sm-6">
                    <fieldset>
                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <label className="form-label" htmlFor="username">
                            {t('username')}
                          </label>
                          <input
                            ref={usernameRef}
                            type="text"
                            className="form-control"
                            name="username"
                            defaultValue={editMode ? editUser.username : ''}
                          />
                        </div>
                        <div className="col-sm-12">
                          <label className="form-label" htmlFor="password">
                            {t('password')}
                          </label>
                          <input
                            ref={passwordRef}
                            type="password"
                            className="form-control"
                            name="password"
                            defaultValue={editMode ? editUser.password : ''}
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-sm-12">
                          <label className="form-label" htmlFor="name">
                            {t('name')}
                          </label>
                          <input
                            ref={nameRef}
                            type="text"
                            className="form-control"
                            name="name"
                            defaultValue={editMode ? editUser.name : ''}
                          />
                        </div>
                        <div className="col-sm-12">
                          <label className="form-label">{t('role')}</label>
                          <select
                            ref={roleRef}
                            className="form-select"
                            name="role_id"
                            value={editMode ? editUser.role_id : undefined}
                          >
                            {roleList.map(role => {
                              return (
                                <option value={`${role._id}`} key={role._id}>
                                  {role.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      {/* <div className="row mb-3">
                        <div className="col-sm-12">
                          <label className="form-label">{t('permission')}</label>
                          <div>
                            {permissionList &&
                              permissionList.map((permission, index) => {
                                if (editMode && editUser.permission.includes(permission.key)) {
                                  return (
                                    <div key={index} className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={permission.key}
                                        defaultChecked={true}
                                      />
                                      <label className="form-check-label" htmlFor={permission.key}>
                                        {permission.name}
                                      </label>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={index} className="form-check form-check-inline">
                                      <input className="form-check-input" type="checkbox" id={permission.key} />
                                      <label className="form-check-label" htmlFor={permission.key}>
                                        {permission.name}
                                      </label>
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        </div>
                      </div> */}
                    </fieldset>
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">{t('agent_groups')}</label>
                    <SelectionList
                      style={{ overflowY: 'auto', maxHeight: '400px' }}
                      optionList={agentGroupList.map((value, index) => ({ id: value._id, title: value.name }))}
                      handleClickOption={(e, option, index) => {
                        const newSelectedAgentGroups = { ...selectedAgentGroups };

                        if (newSelectedAgentGroups?.[agentGroupList[index]._id] === undefined) {
                          // Add agent
                          newSelectedAgentGroups[agentGroupList[index]._id] = agentGroupList[index];
                        } else {
                          // Remove agent
                          delete newSelectedAgentGroups[agentGroupList[index]._id];
                        }

                        setSelectedAgentGroups(newSelectedAgentGroups);
                      }}
                      selectedOptions={selectedAgentGroups}
                      href={(e, option, index) => `#agent${index}`}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer bg-none d-flex p-3">
                <button type="submit" className="btn btn-primary w-100px ms-auto">
                  {t('submit')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>

    </Container>
  );
};

export default withTranslation()(AddUsers1);
