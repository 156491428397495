import { put, takeEvery } from "redux-saga/effects";
import { sagaBuild } from "./common";
import * as ConversationReducers from "../reducers/conversations";

function* getRoomListCaller({ payload }) {
    yield sagaBuild({ payload, successType: ConversationReducers.getRoomListSuccess.type, failType: ConversationReducers.getRoomListFail.type });
}

function* setRoomListCaller({ payload }) {
    yield put({ type: ConversationReducers.setRoomListSuccess.type, newRoomList: payload.newRoomList });

}

function* getMessageListCaller({ payload }) {
    yield sagaBuild({ payload, successType: ConversationReducers.getMessageListSuccess.type, failType: ConversationReducers.getMessageListFail.type });
}

function* appendMessageListCaller({ payload }) {
    yield sagaBuild({ payload, successType: ConversationReducers.appendMessageListSuccess.type, failType: ConversationReducers.appendMessageListFail.type });
}

function* forwardMessageCaller({ payload }) {
    yield sagaBuild({ payload, successType: ConversationReducers.forwardMessageSuccess.type, failType: ConversationReducers.forwardMessageFail.type });
}

export default function* conversation() {
    yield takeEvery(ConversationReducers.getRoomList.type, getRoomListCaller);
    yield takeEvery(ConversationReducers.setRoomList.type, setRoomListCaller);
    yield takeEvery(ConversationReducers.getMessageList.type, getMessageListCaller);
    yield takeEvery(ConversationReducers.appendMessageList.type, appendMessageListCaller);
    yield takeEvery(ConversationReducers.forwardMessage.type, forwardMessageCaller);
}