import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { useConversation } from '../_component/context/ConversationContext';
import SelectionList from '../_component/SelectionList';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { apiGenerator } from '../api/api-manager';
import { withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';

const AlertSetting = props => {
  const { t } = props;

  const appSettings = useContext(AppSettings);
  const [customerOnHoldTime, setCustomerOnHoldTime] = useState('');
  const [alertEmailRecipients, setAlertEmailRecipients] = useState([
    {
      email: '',
    },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');
  }, [appSettings]);

  useEffect(() => {
    getAlertSetting();
  }, []);

  const getAlertSetting = () => {
    apiGenerator({
      method: 'GET',
      path: 'system_settings/alert_setting',
      body: {},
    }).then(res => {
      console.log(res.data.params);

      const alertSetting = res.data.params;

      // Set customer on hold time
      setCustomerOnHoldTime(alertSetting.alert_customer_on_hold_time);

      // Set alert emails
      const newAlertEmailRecipients = alertSetting.alert_email_recipients.map(value => {
        return { email: value.email };
      });

      setAlertEmailRecipients(newAlertEmailRecipients);
    });
  };

  const handleChangeCustomerOnHoldTime = e => {
    setCustomerOnHoldTime(e.target.value);
  };

  const handleChangeAlertEmailRecipients = (e, index) => {
    const newAlertEmailRecipients = [...alertEmailRecipients];

    newAlertEmailRecipients[index][e.target.name] = e.target.value;
    setAlertEmailRecipients(newAlertEmailRecipients);
  };

  const handleClickAddAlertEmail = e => {
    const newAlertEmailRecipients = [...alertEmailRecipients];
    newAlertEmailRecipients.push({ email: '' });
    setAlertEmailRecipients(newAlertEmailRecipients);
  };

  const handleClickDeleteAlertEmail = (e, index) => {
    const newAlertEmailRecipients = [...alertEmailRecipients];
    newAlertEmailRecipients.splice(index, 1);
    setAlertEmailRecipients(newAlertEmailRecipients);
  };

  const onFormSubmit = e => {
    e.preventDefault();

    apiGenerator({
      method: 'PATCH',
      path: `system_settings/alert_setting`,
      body: {
        alert_customer_on_hold_time: customerOnHoldTime,
        alert_email_recipients: alertEmailRecipients,
      },
    }).then(res => {
      Store.addNotification({
        message: res.data.message,
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: { duration: 5000 },
      });
    });
  };

  return (
    <Container>
      <div className="d-flex align-items-center">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={'/'}>Home</Link>
            </li>
            <li className="breadcrumb-item active">{t('alert_setting')}</li>
          </ol>
          <h1 className="page-header">{t('alert_setting')}</h1>
        </div>
      </div>

      <Form onSubmit={onFormSubmit} id="alertSetting">
        <div className="row gx-4">
          <div className="col-lg-12">
            <div className="card border-0 mb-4">
              <div className="card-body p-3 text-dark fw-bold">
                <div className="row">
                  <div className="col-sm-12">
                    <fieldset>
                      <div className="row mb-3">
                        <div className="col-sm-6">
                          <label className="form-label" htmlFor="name">
                            {t('customer_on_hold_time')}
                          </label>
                          <div className="input-group mb-1">
                            <input
                              type="number"
                              name="alert_send_time"
                              className="form-control"
                              min="0"
                              value={customerOnHoldTime}
                              onChange={handleChangeCustomerOnHoldTime}
                              style={{ textAlign: 'right' }}
                            />
                            <div className="input-group-text">Min(s)</div>
                          </div>

                          <small className="fs-12px text-gray-500-darker">
                            An alert email is sent to recipients if a customer is on hold longer than the specified
                            time. Email is delivered periodically (e.g. every hour) based on the number of on-hold
                            customers. Alert is canceled if an agent responds to the customer before the next check.
                          </small>
                        </div>
                        {/* </div>

                      <div className="row mb-3"> */}
                        <div className="col-sm-6" style={{ borderLeft: '1px solid rgb(231 231 231)' }}>
                          <label className="form-label" htmlFor="name">
                            {t('alert_email_recipients')}
                          </label>

                          <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                            <thead>
                              <tr>
                                <th>{t('email')}</th>
                                <th style={{ width: '1%', whiteSpace: 'nowrap' }}>{t('action')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(alertEmailRecipients) &&
                                alertEmailRecipients.map((value, index) => {
                                  return (
                                    <tr key={index}>
                                      <td style={{ verticalAlign: 'super' }}>
                                        <input
                                          type="email"
                                          name="email"
                                          className="form-control"
                                          value={value.email}
                                          onChange={e => handleChangeAlertEmailRecipients(e, index)}
                                        />
                                      </td>
                                      <td nowrap="true" className="with-btn" style={{ verticalAlign: 'super' }}>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger w-60px"
                                          onClick={e => handleClickDeleteAlertEmail(e, index)}
                                        >
                                          {t('delete')}
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              <tr>
                                <td></td>
                                <td nowrap="true" className="with-btn" style={{ verticalAlign: 'super' }}>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary w-60px"
                                    onClick={handleClickAddAlertEmail}
                                  >
                                    {t('add')}
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="card-footer bg-none d-flex p-3">
                <button type="submit" className="btn btn-primary w-100px ms-auto">
                  {t('submit')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default withTranslation()(AlertSetting);
