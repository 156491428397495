import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { POST } from '../../../api/api-manager';
import { logout, changeLanguage, selectAuth } from '../../../store/reducers/auth';
import { selectUser } from '../../../store/reducers/user';
import { Link } from 'react-router-dom';

const DropdownProfile = props => {
  const { t } = props;
  const dispatch = useDispatch();
  const { userName } = useSelector(selectAuth);
  const {
    profile_pic
  } = useSelector(selectUser);

  const [agentProfilePic, setAgentProfilePic] = useState(null);

  useEffect(() => {
    setAgentProfilePic(profile_pic);
  }, [profile_pic]);

  return (
    <div className="navbar-item navbar-user dropdown">
      <a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
        {/* <img src="/assets/img/user/user-13.jpg" alt="" />  */}
        <img
          className="profile-pic"
          src={
            agentProfilePic
              ? agentProfilePic
              : 'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000'
          }
          alt="profile_pic"
        />
        <span>
          <span className="d-none d-md-inline">{userName}</span>
          <b className="caret"></b>
        </span>
      </a>
      <div className="dropdown-menu dropdown-menu-end me-1">
        {/* <a href="#/" className="dropdown-item">
          Edit Profile
        </a>
        <a href="#/" className="dropdown-item d-flex align-items-center">
          Inbox
          <span className="badge bg-danger rounded-pill ms-auto pb-4px">2</span>
        </a>
        <a href="#/" className="dropdown-item">
          Calendar
        </a>
        <a href="#/" className="dropdown-item">
          Settings
        </a> */}
        {/* <div className="dropdown-divider"></div> */}
        <Link to="/update_profile" className="dropdown-item">
          <i className="fa fa-circle-user fa-fw me-1 text-dark"></i> {t('update_profile')}
        </Link>
        <Link to="/subscription" className="dropdown-item">
          <i className="fa fa-credit-card fa-fw me-1 text-dark"></i> {t('subscription')}
        </Link>
        <a href="#" className="dropdown-item" onClick={() => dispatch(POST(logout, 'users/logout', {}))}>
          <i className="fa fa-door-open fa-fw me-1 text-dark"></i> {t('logout')}
        </a>
      </div>
    </div>
  );
};

export default withTranslation()(DropdownProfile);
