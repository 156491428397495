import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { POST, GET, PATCH } from '../api/api-manager';
import { getAgentGroupList, getParentAgentGroupList } from '../store/reducers/agentGroups';
import { selectAgentGroups, addAgentGroup, updateAgentGroup, deleteAgentGroup } from '../store/reducers/agentGroups';
import Table from 'react-bootstrap/Table';
import CustomModal from '../_component/CustomModal';
import CustomModal1 from '../_component/CustomModal1';
import { Button, Form, InputGroup } from 'react-bootstrap';
import PageTitle from '../_component/PageTitle';
import CustomPagination from '../_component/Pagination';
import CustomPagination1 from '../_component/Pagination1';
import { withTranslation } from 'react-i18next';
import { AppSettings } from '../config/app-settings';
import { apiGenerator } from '../api/api-manager';

function bytesToGB(bytes) {
  if (bytes < 1024 * 1024 * 1024) {
    // show bytes in MB with 2 decimal places
    const mb = bytes / (1024 * 1024);
    return mb.toFixed(2) + ' MB';
  } else {
    // show bytes in GB with 2 decimal places
    const gb = bytes / (1024 * 1024 * 1024);
    return gb.toFixed(2) + ' GB';
  }
}

function addThousandSeparator(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function getSecondsToMinutes(seconds) {
  const minutes = Math.round(seconds / 60);
  return `${addThousandSeparator(minutes)}`;
}

const fetchConversationReport = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'GET',
      path: `reports/conversation-report`,
      body: {},
    }).then(res => {
      console.log(res);
      return resolve(res?.data?.data ? res.data.data : undefined);
    });
  });
};

const fetchDashboardReport = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'GET',
      path: `reports/dashboard-report`,
      body: {},
    }).then(res => {
      console.log(res);
      return resolve(res?.data?.data ? res.data.data : undefined);
    });
  });
};

const fetchStatistic = async () => {
  return new Promise(resolve => {
    apiGenerator({
      method: 'GET',
      path: `reports/statistic`,
      body: {},
    }).then(res => {
      console.log(res);
      return resolve(res?.data?.data ? res.data.data : undefined);
    });
  });
};

const Dashboard = props => {
  const { t } = props;

  const appSettings = useContext(AppSettings);
  const [conversationReport, setConversationReport] = useState([]);
  const [dashboardReport, setDashboardReport] = useState([]);
  const [statistic, setStatistic] = useState({
    total_file_size: 0,
    chat_gpt: {
      improve_total_chatgpt_api_call: 0,
      improve_total_completion_tokens: 0,
      improve_total_prompt_tokens: 0,
      improve_total_tokens: 0,
      total_chatgpt_api_call: 0,
      total_completion_tokens: 0,
      total_prompt_tokens: 0,
      total_tokens: 0,
      translate_total_chatgpt_api_call: 0,
      translate_total_completion_tokens: 0,
      translate_total_prompt_tokens: 0,
      translate_total_tokens: 0,
    },
  });
  const [avgResponseTime, setAvgResponseTime] = useState(0);
  const [totalAverage, setTotalAverage] = useState({
    min: 0,
    max: 0,
    avg: 0,
  });

  // General UI settings (Template)
  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');
  }, [appSettings]);

  useEffect(() => {
    (async () => {
      const conversationReportRes = await fetchConversationReport();
      const dashboardReportRes = await fetchDashboardReport();
      const statisticRes = await fetchStatistic();

      // Process conversation report data.
      if (Array.isArray(conversationReportRes) && conversationReportRes.length > 0) {
        setConversationReport(conversationReportRes);

        const newAverage = {
          min: 0,
          max: 0,
          avg: 0,
        };

        for (const value of conversationReportRes) {
          const first_message_time = value.first_message_time;

          newAverage.min += first_message_time.min;
          newAverage.max += first_message_time.max;
          newAverage.avg += first_message_time.avg;
        }

        newAverage.min /= conversationReportRes.length;
        newAverage.max /= conversationReportRes.length;
        newAverage.avg /= conversationReportRes.length;

        setTotalAverage(newAverage);
      }

      // Process dashboard report data.
      if (Array.isArray(dashboardReportRes?.chart1) && dashboardReportRes.chart1.length > 0) {
        setDashboardReport(dashboardReportRes);

        let count = 0;
        let totalTime = 0;

        for (const message of dashboardReportRes.chart1) {
          if (message.response_time === null) {
            continue;
          }

          totalTime += message.response_time;
          count++;
        }

        if (count > 0) setAvgResponseTime(totalTime / count);
      }

      // Process statistic data.
      if (Array.isArray(statisticRes) && statisticRes.length > 0) {
        const newStatistic = statistic;

        for (const data of statisticRes) {
          switch (data.name) {
            case 'total_file_size':
              if (data?.total_file_size !== undefined) {
                newStatistic.total_file_size = data.total_file_size;
              }
              break;

            case 'total_chatgpt_api_usage':
              if (typeof data?.chat_gpt === 'object' && data?.chat_gpt) {
                for (const chat_gpt_key of Object.keys(data.chat_gpt)) {
                  const chat_gpt_value = data.chat_gpt[chat_gpt_key];

                  newStatistic.chat_gpt[chat_gpt_key] = chat_gpt_value;
                }
              }
              break;

            default:
          }
        }

        console.log('test joseph', newStatistic)
        setStatistic(newStatistic);
      }
    })();
  }, [statistic]);

  return (
    <>
      <Container>
        {/* Breadcrumb */}
        <div className="d-flex align-items-center mb-3">
          <div>
            <h1 className="page-header mb-0">{t('dashboard')}</h1>
          </div>
        </div>

        {/* Main content */}
        {/* <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: '1%', whiteSpace: 'nowrap' }}>{t('customer')}</th>
                      <th style={{ textAlign: 'right' }}>{t('avg_first_response_time')}</th>
                      <th style={{ textAlign: 'right' }}>{t('min_first_response_time')}</th>
                      <th style={{ textAlign: 'right' }}>{t('max_first_response_time')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {report &&
                      report.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ verticalAlign: 'super' }}>
                              {value.customer.name} ({value.customer.phone_number})
                            </td>
                            <td style={{ textAlign: 'right', verticalAlign: 'super' }}>
                              <b>{getSecondsToMinutes(value.first_message_time.avg)}</b>{` ${t('minutes_short')}`}
                            </td>
                            <td style={{ textAlign: 'right', verticalAlign: 'super' }}>
                              <b>{getSecondsToMinutes(value.first_message_time.min)}</b>{` ${t('minutes_short')}`}
                            </td>
                            <td style={{ textAlign: 'right', verticalAlign: 'super' }}>
                              <b>{getSecondsToMinutes(value.first_message_time.max)}</b>{` ${t('minutes_short')}`}
                            </td>
                          </tr>
                        );
                      })}
                    <tr style={{ backgroundColor: 'rgb(0, 0, 0, 0.075)' }}>
                      <td style={{ verticalAlign: 'super' }}>
                        <b>{t('average')}</b>
                      </td>
                      <td style={{ textAlign: 'right', verticalAlign: 'super' }}><b>{getSecondsToMinutes(totalAverage.avg)}</b>{` ${t('minutes_short')}`}</td>
                      <td style={{ textAlign: 'right', verticalAlign: 'super' }}><b>{getSecondsToMinutes(totalAverage.min)}</b>{` ${t('minutes_short')}`}</td>
                      <td style={{ textAlign: 'right', verticalAlign: 'super' }}><b>{getSecondsToMinutes(totalAverage.max)}</b>{` ${t('minutes_short')}`}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div className="widget widget-stats bg-blue">
              <div className="stats-icon">
                <i className="fa fa-users"></i>
              </div>
              <div className="stats-info">
                <h4>TOTAL CUSTOMERS</h4>
                <p>{dashboardReport.customerCount ? dashboardReport.customerCount : 0}</p>
              </div>
              <div className="stats-link">
                <Link to="#" style={{ visibility: 'hidden' }}>
                  None
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="widget widget-stats bg-orange">
              <div className="stats-icon">
                <i className="fa fa-message"></i>
              </div>
              <div className="stats-info">
                <h4>ACTIVE CONVERSATION</h4>
                <p>{dashboardReport.activeConversationCount ? dashboardReport.activeConversationCount : 0}</p>
              </div>
              <div className="stats-link">
                <Link to="#" style={{ visibility: 'hidden' }}>
                  None
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="widget widget-stats bg-silver">
              <div className="stats-icon">
                <i className="fa fa-stopwatch"></i>
              </div>
              <div className="stats-info">
                <h4>AVG. FIRST RESPONSE TIME</h4>
                <p>
                  <b>{getSecondsToMinutes(totalAverage.avg)}</b>
                  {` ${t('minutes_short')}`}
                </p>
              </div>
              <div className="stats-link">
                <Link to="/conversation_report">
                  View Detail <i className="fa fa-arrow-alt-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div className="widget widget-stats bg-purple">
              <div className="stats-icon">
                <i className="fa fa-clock"></i>
              </div>
              <div className="stats-info">
                <h4>AVG. RESPONSE TIME</h4>
                <p>
                  {getSecondsToMinutes(avgResponseTime / 1000)}
                  {` ${t('minutes_short')}`}
                </p>
              </div>
              <div className="stats-link">
                <Link to="#" style={{ visibility: 'hidden' }}>
                  None
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="widget widget-stats bg-red">
              <div className="stats-icon">
                <i className="fa fa-chart-bar"></i>
              </div>
              <div className="stats-info">
                <h4>AVG. CONVERSATION PER DAY</h4>
                <p>{dashboardReport?.chart2?.[0]?.avgCount ? Math.round(dashboardReport.chart2[0].avgCount) : '0'}</p>
              </div>
              <div className="stats-link">
                <Link to="#" style={{ visibility: 'hidden' }}>
                  None
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="widget widget-stats bg-green">
              <div className="stats-icon">
                <i className="fa fa-rectangle-list"></i>
              </div>
              <div className="stats-info">
                <h4>AVG. MESSAGE COUNT PER CONVERSATION</h4>
                <p>
                  {dashboardReport?.chart3?.[0]?.avgMessageCount
                    ? Math.round(dashboardReport.chart3[0].avgMessageCount)
                    : '0'}
                </p>
              </div>
              <div className="stats-link">
                <Link to="#" style={{ visibility: 'hidden' }}>
                  None
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="widget widget-stats bg-silver">
              <div className="stats-icon">
                <i className="fa fa-chart-pie"></i>
              </div>
              <div className="stats-info">
                <h4>TOTAL UPLOADED MEDIA FILE SIZE</h4>
                <p>{bytesToGB(statistic.total_file_size)}</p>
              </div>
              <div className="stats-link">
                <Link to="#" style={{ visibility: 'hidden' }}>
                  None
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="widget widget-stats bg-blue">
              <div className="stats-icon">
                <i className="fa fa-chart-line"></i>
              </div>
              <div className="stats-info">
                <h4>ChatGPT API USAGE</h4>
                <p>{statistic.chat_gpt.total_chatgpt_api_call}</p>
              </div>
              <div className="stats-link">
                <Link to="#" style={{ visibility: 'hidden' }}>
                  None
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default withTranslation()(Dashboard);
