import { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { withTranslation } from 'react-i18next'

/*
paginationParams
    {
        totalDocs: Number,
        limit: Number,
        page: Number,
        totalPages: Number,
        hasPrevPage: Number,
        hasNextPage: Number,
        prevPage: Number,
        nextPage: Number
    }
*/

const PAGE_BUFFER = 2; // E.g. 1, 2, 3, 4, 5. If the current page is 3, 1&2 and 4&5 are buffers.

function CustomPagination1({paginationParams, setPage, t}) {

    const [items, setItems] = useState([]);

    useEffect(() => {
        console.log(paginationParams)
        let temp_item = [];
        let from_page_range = paginationParams.page - PAGE_BUFFER
        let to_page_range = paginationParams.page + PAGE_BUFFER;

        // * Ensure index will not exceed the index range.
        if (from_page_range < 1) from_page_range = 1;
        if (to_page_range > paginationParams.totalPages) to_page_range = paginationParams.totalPages;

        // * Using key because the it reserves the index for React to properly render pagination without a "UI Flashing" effect.
        // ! If the page "number" is used in "key={number}", React will cause flashing as the key is changing everytime it is clicked.
        // ! To observe the issue, you can replace key={key} with key={number}.
        let key = 1;

        for (let number = from_page_range; number <= to_page_range; number++) {
            let active = number === paginationParams.page && 'active';
            
            temp_item.push(
                <div key={key} className={`page-item ${active}`}>
                    <button className="page-link" onClick= {()=> setPage(number)}>{number}</button>
                </div>
            );

            key++;
        }
        setItems(temp_item)
    },[paginationParams])

    
    return (
        <div>
            <ul className="pagination justify-content-center">
                <div className={`page-item ${paginationParams.page == 1 && 'disabled'}`}><button className="page-link" onClick={() => setPage(1)}>«</button></div>
                {/* <div className={`page-item ${!paginationParams.hasPrevPage && 'disabled'}`}><button className="page-link" onClick={() => setPage(paginationParams.prevPage)}>‹</button></div> */}
                <div className={`page-item ${!paginationParams.hasPrevPage && 'disabled'}`}><button className="page-link" onClick={() => setPage(paginationParams.prevPage)}>{t('previous_page')}</button></div>

                {/* ! Deprecated */}
                {/* {
                    paginationParams.page - PAGE_BUFFER > 1 && <Pagination.Ellipsis />
                } */}

                {items}

                {/* Deprecated */}
                {/* {
                    paginationParams.page + PAGE_BUFFER < paginationParams.totalPages && <Pagination.Ellipsis />
                } */}
                
                {/* <div className={`page-item ${!paginationParams.hasNextPage && 'disabled'}`}><button className="page-link" onClick={() => setPage(paginationParams.nextPage)}>›</button></div> */}
                <div className={`page-item ${!paginationParams.hasNextPage && 'disabled'}`}><button className="page-link" onClick={() => setPage(paginationParams.nextPage)}>{t('next_page')}</button></div>
                <div className={`page-item ${paginationParams.page == paginationParams.totalPages && 'disabled'}`}><button className="page-link" onClick={() => setPage(paginationParams.totalPages)}>»</button></div>
            </ul>
        </div>
    );
}

export default withTranslation()(CustomPagination1);