import { put, call } from "redux-saga/effects";
import { apiGenerator } from "../../api/api-manager";



export function* sagaBuild({ payload, successType, failType }) {
    try {
        const resData = yield call(apiGenerator, payload);
        yield put({ type: successType, resData });

    // add if resData-> response is something.... u can put to failtype too
    
    } catch (error) {
        yield put({ type: failType, error });
    }
}