import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom/client";
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from "./store/reducers/auth";
// import "./index.css";
// import "./App.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import axios from "axios";

import { PersistGate } from "redux-persist/integration/react";
import { sessionExpired } from "./store/reducers/auth";
import { PermissionsProvider } from './_component/context/AuthContext';
import { SearchProvider } from './_component/context/SearchContext';
import { usePermissionsDispatch } from './_component/context/AuthContext';
import { usePermissions } from './_component/context/AuthContext';
import { SystemInfoProvider } from './_component/context/SystemInfoContext';
import { LOCAL } from "./api/api-manager"
import './i18n';
import i18n from 'i18next';

import { BrowserRouter, useRoutes } from 'react-router-dom';
import AppRoute from './config/app-route.jsx';

// bootstrap
import 'bootstrap';

// css
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';

// Locale for dayjs
import dayjs from "dayjs";
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/zh-hk';
import 'dayjs/locale/zh-cn';

dayjs.extend(updateLocale);

// Customized locale for zh-hk
// a minute will be shown as "1 分鐘" instead of "一分鐘"
dayjs.updateLocale('zh-hk', {
  relativeTime: {
    future: '%s內',
    past: '%s前',
    s: '幾秒',
    m: '1 分鐘',
    mm: '%d 分鐘',
    h: '1 小時',
    hh: '%d 小時',
    d: '1 天',
    dd: '%d 天',
    M: '1 個月',
    MM: '%d 個月',
    y: '1 年',
    yy: '%d 年'
  }
});

switch (i18n.language) {
  case 'en_US':
    dayjs.locale('en');
    break;

  case 'zh_HK':
    dayjs.locale('zh-hk');
    break;

  case 'zh_CN':
    dayjs.locale('zh-cn');
    break;

  default:
    break;
}

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL_DEV}/api/`;
axios.defaults.withCredentials = true;
// axios.defaults.proxy.host = "https://localhost";
// axios.defaults.proxy.port = "3000";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Helper function
// Clear the local storage data set by the app.
// * Should be invoked when settting `isLogined` to false.
const clearLocalStorage = () => {
  console.log('Invoke clearLocalStorage().');
  localStorage.removeItem('permissions');
}

// Component
function App() {
  const { isLogined } = useSelector( selectAuth );
  const { permissions } = usePermissions();

  const [error, setError] = useState();

  const dispatch = useDispatch();

  const permissionsDispatch = usePermissionsDispatch();
  
  useMemo(() => {
    axios.interceptors.response.use((response) => {
      // Update local permission
      console.log(response.headers.permissions);
      permissionsDispatch({ permissions: response.headers.permissions });

      return response;
    }, (error) => { // Anything except 2XX goes to here
      // Update local permission
      if (error?.response?.headers?.permissions) {
        permissionsDispatch({ permissions: error.response.headers.permissions });
      }
      
      const status = error.response?.status || 500;
      if (status === 440) {
          dispatch(LOCAL(sessionExpired, {}));
          setError(error)
      } else {
          return Promise.reject(error); // Delegate error to calling side
      }
    });
  }, [setError])


  let element = useRoutes(AppRoute(isLogined, permissions));

  useEffect(() => {
    console.log('on change isLogined', isLogined)
    if (!isLogined) {
      // Not logged in
      console.log('isLogined is set to false');
      permissionsDispatch({ permissions: '[]' })
    }
  }, [isLogined]);

  useEffect(() => {
    if (Object.keys(permissions).length === 0) {
      clearLocalStorage();
    }
  }, [permissions])
  

  return element;
}

root.render(
  // <React.StrictMode>
    <Provider store={store().store}>
      <PersistGate loading={null} persistor={store().persistor}>
        {/* Include `"homepage": "/app",` in package.json if basename needs to be set */}
        {/* <BrowserRouter basename="/app"> */}
        <BrowserRouter>
          <SystemInfoProvider>
            <PermissionsProvider>
              <SearchProvider>
                <App />
              </SearchProvider>
            </PermissionsProvider>
          </SystemInfoProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
