import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { memo } from 'react';
import { withTranslation } from 'react-i18next'

//
// modal_detail
// {
//     title: '',
//     body: <Component>
// }

function CustomModal1({
  modal_title = 'Title',
  children,
  onSubmit,
  showModal,
  setShowModal,
  centered = false,
  onHideFunction = () => {},
  showSubmitButton = true,
  size,
  submitButtonText = 'submit',
  t
}) {
  // const [show, setShow] = useState(false);
  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    onHideFunction();
    setShowModal(false);
  };
  const handleSubmit = () => {
    try {
      onSubmit();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} dialogClassName="message-template-modal" centered={centered} size={size}>
        <Modal.Header closeButton>
          <Modal.Title>{modal_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <button type={'button'} className="btn btn-white" onClick={handleClose}>
            {t('close')}
          </button>
          {showSubmitButton && (
            <button type={'button'} className="btn btn-primary" onClick={handleSubmit}>
              {t(submitButtonText)}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default memo(withTranslation()(CustomModal1));