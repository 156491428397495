import React, { useEffect, useState, useContext } from 'react';
import { Button, Col, Row, Table, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getLogList, getUserList, apiGenerator, getLog } from '../api/api-manager';
import { Link, useNavigate } from 'react-router-dom';
import PageTitle from '../_component/PageTitle';
import CustomPagination from '../_component/Pagination';
import CustomPagination1 from '../_component/Pagination1';
import { AppSettings } from '../config/app-settings';
import { Store } from 'react-notifications-component';

import dayjs from 'dayjs';
import { useTranslation, withTranslation } from 'react-i18next';
import { usePermissions } from '../_component/context/AuthContext';
import { useDebounce } from '../_component/hooks/useDebounce';

const ITEMS = {
  business_profile: 'Business Profile',
  chat_gpt: 'ChatGPT',
  message: 'Message',
  customer: 'Customer',
  role: 'Role',
  system_setting: 'System Setting',
  template: 'Template',
  user_group: 'User Group',
  user: 'User',
};

const ACTIONS = {
  create: 'Create',
  update: 'Update',
  delete: 'Delete',
  process_message: 'Process message',
  forward: 'Forward',
  update_is_blocked: 'Update block status',
  update_is_archived: 'Update archive status',
  delete_profile_picture: 'Delete profile picture',
  add_push_notification_token: 'Add push notification token',
  update_enable_auto_reply: 'Enable/Disable auto reply',
  set_default_auto_reply_message: 'Set default auto reply message',
  update_auto_reply_message: 'Update auto reply message',
  update_default_language: 'Update default language',
  set_auto_reply_message: 'Set auto reply message',
  delete_auto_reply_message: 'Delete auto reply message',
  update_password: 'Update password',
  update_profile_pic: 'Update profile picture',
  login: 'Login',
  logout: 'Logout',
};

const ColItem = ({ log }) => {
  const { t } = useTranslation();

  switch (log.item) {
    case 'user_group':
      return t('agent_group');

    case 'user':
      return t('agent');

    default:
      return t(log.item);
  }
};

const ColAction = ({ log }) => {
  const { t } = useTranslation();

  return t(log.action);
};

const ColRef = ({ log }) => {
  if (typeof log?.item_object === 'undefined') return null;

  switch (`${log.item}__${log.action}`) {
    case 'user__create':
    case 'user__update':
    case 'user__delete':
      return (
        <>
          {log?.item_object?.name} <b>({log?.item_object?.username})</b>
        </>
      );

    case 'role__create':
    case 'role__update':
    case 'role__delete':
    case 'user_group__create':
    case 'user_group__update':
    case 'user_group__delete':
      return log?.item_object?.name;

    case 'customer__create':
    case 'customer__update':
    case 'customer__delete':
      return (
        <>
          {log?.item_object?.profile_name} <b>({log?.item_object?.phone_number})</b>
        </>
      );

    default:
      return null;
  }
};

const SystemLogs = props => {
  const { t } = props;
  // const dispatch = useDispatch();
  const appSettings = useContext(AppSettings);
  const { permissions } = usePermissions();

  // Search fields
  const [searchIp, setSearchIp] = useState('');
  const { debouncedValue: debouncedSearchIp } = useDebounce(searchIp, 350);
  const [searchUser, setSearchUser] = useState('');
  const { debouncedValue: debouncedSearchUser } = useDebounce(searchUser, 350);

  const [logList, setLogList] = useState([]);
  const [userList, setUserList] = useState([]);

  const [page, setPage] = useState(1);
  const [paginationParams, setPaginationParams] = useState([]);
  const limit = 2;

  const navigate = useNavigate();

  const updateLogList = () => {
    getLog({
      ip: debouncedSearchIp,
      user: debouncedSearchUser,
      page: page,
      limit: limit,
    }).then(res => {
      let response = res.data;
      console.log(response);
      if (response.status) {
        let temp_log_list = [];

        for (const log of response.params.docs) {
          temp_log_list.push({
            ...log,
          });
        }

        setLogList(temp_log_list);
        setPaginationParams({
          totalDocs: response.params.totalDocs,
          limit: response.params.limit,
          page: response.params.page,
          totalPages: response.params.totalPages,
          hasPrevPage: response.params.hasPrevPage,
          hasNextPage: response.params.hasNextPage,
          prevPage: response.params.prevPage,
          nextPage: response.params.nextPage,
        });
      }
    });
  };

  useEffect(() => {
    console.log('app settings context', appSettings);

    appSettings.handleSetAppSidebarNone(true);
    appSettings.handleSetAppHeaderNone(false);
    appSettings.handleSetAppContentClass('p-2');
  }, [appSettings]);

  useEffect(() => {
    getUserList().then(res => {
      let response = res.data;
      if (response.status) {
        setUserList(response.params.docs);
      }
    });
  }, []);

  useEffect(() => {
    updateLogList();
  }, [debouncedSearchIp, debouncedSearchUser, page]);

  return (
    <>
      <Container>
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={'/'}>Home</Link>
              </li>
              <li className="breadcrumb-item active">{t('system_log')}</li>
            </ul>
            <h1 className="page-header mb-0">{t('system_log')}</h1>
          </div>
        </div>

        <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              {/* Search bar */}
              <div className="row mb-3">
                <div className="col-sm-6">
                  <div className="flex-fill position-relative">
                    <div className="input-group">
                      <div
                        className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                        style={{ zIndex: 10 }}
                      >
                        <i className="fa fa-search opacity-5"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control px-35px bg-light"
                        style={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
                        placeholder={t('search_ip_dots')}
                        value={searchIp}
                        onChange={e => {
                          setSearchIp(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <select
                    className="form-select"
                    value={searchUser}
                    onChange={e => {
                      setSearchUser(e.target.value);
                    }}
                  >
                    <option value="" key="">
                      {t('all_users')}
                    </option>
                    {userList.map(user => {
                      return (
                        <option value={`${user._id}`} key={user._id}>
                          {user.name} <b>({user.username})</b>
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              {/* Table */}
              <div className="table-responsive mb-3">
                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: '1%', whiteSpace: 'nowrap' }}>#</th>
                      <th>{t('datetime')}</th>
                      <th>{t('ip')}</th>
                      <th>{t('user')}</th>
                      <th>{t('item')}</th>
                      <th>{t('action')}</th>
                      <th>
                        {t('ref')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {logList &&
                      logList.length > 0 &&
                      logList.map((log, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ verticalAlign: 'super' }}>{index + 1}</td>
                            <td style={{ verticalAlign: 'super' }}>
                              {dayjs(log.action_time).format('YYYY-MM-DD HH:mm:ss')}
                            </td>
                            <td style={{ verticalAlign: 'super' }}>{log.ip}</td>
                            <td style={{ verticalAlign: 'super' }}>
                              {log.user?.name} <b>({log.user?.username})</b>
                            </td>
                            <td style={{ verticalAlign: 'super' }}>
                              <ColItem log={log} />
                            </td>
                            <td style={{ verticalAlign: 'super' }}>
                              <ColAction log={log} />
                            </td>
                            <td style={{ verticalAlign: 'super' }}>
                              <ColRef log={log} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="d-md-flex align-items-center">
                <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                  {t('pagination_info_1')} {(paginationParams.page - 1) * paginationParams.limit + 1}{' '}
                  {t('pagination_info_2')}{' '}
                  {paginationParams.page * paginationParams.limit < paginationParams.totalDocs
                    ? paginationParams.page * paginationParams.limit
                    : paginationParams.totalDocs}{' '}
                  {t('pagination_info_3')} {paginationParams.totalDocs} {t('pagination_info_4')}
                </div>

                <CustomPagination1 paginationParams={paginationParams} setPage={setPage} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default withTranslation()(SystemLogs);
