// This context stores all the search criterias of all pages globally.
// ! It is particular important for "Chat" page, the chat list can be refreshed by socket event or page effect(Hooks, Click conversation).
// ! Without this context, socket and page will filter the conversation list differently

import { createContext, useContext, useEffect, useState } from 'react';
import { useDebounce } from '../hooks/useDebounce';

export const SearchContext = createContext();

export function SearchProvider({ children }) {
  const [chatSearchOptions, setChatSearchOptions] = useState({
    keyword: '',
    agents: {},
    agentGroups: {},
    includeNoHistory: undefined,
    _sort: undefined,
  });
  const { debouncedValue: debouncedChatSearchOptions, setDebouncedValue: setDebouncedChatSearchOptions } = useDebounce(chatSearchOptions, 350);

  useEffect(() => {
    console.log('Chat search option are updated', chatSearchOptions);
  }, [chatSearchOptions]);

  useEffect(() => {
    console.log('[Debounce] Chat search option are updated', debouncedChatSearchOptions);
  }, [debouncedChatSearchOptions]);

  return (
    <SearchContext.Provider value={{
      chatSearchOptions,
      setChatSearchOptions,
      debouncedChatSearchOptions,
      setDebouncedChatSearchOptions,
    }}>
      {children}
    </SearchContext.Provider>
  );
}

export function useSearch() {
  return useContext(SearchContext);
}